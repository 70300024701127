import { ReactElement } from 'react';

export enum EnumAboutSectionKey {
  FAILED_SOLUTIONS = 'FAILED_SOLUTIONS',
  PROBLEM = 'PROBLEM',
  RESULT = 'RESULT',
  SOLUTION = 'SOLUTION',
}

export type TypeAboutSection = {
  key: EnumAboutSectionKey;
  darkImage: ReactElement<any>;
  description: string;
  lightImage: ReactElement<any>;
  titleText: string;
};
