import { ReactElement } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useGetTheme } from 'util/theme/hooks';

export type TypeThemeProviderProps = {
  children: ReactElement<any, any>;
};

export default function ThemeProvider(props: TypeThemeProviderProps) {
  const { children } = props;
  const theme = useGetTheme();

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
