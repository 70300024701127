import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

export default function SignupFooter() {
  const { formatMessage } = useIntl();

  return (
    <Stack direction='row' spacing={1}>
      <Typography>
        {formatMessage({ defaultMessage: 'Already have an account?' })}
      </Typography>
      <Link component={RouterLink} to='/login'>
        {formatMessage({ defaultMessage: 'Log in' })}
      </Link>
    </Stack>
  );
}
