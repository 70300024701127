import { Divider, Stack, Typography } from '@mui/material';
import { hasOauthFeatureFlag } from 'util/user/featureFlags/helpers';
import { useIntl } from 'react-intl';
import BrandButtonLink from 'components/common/brand/BrandButtonLink';
import ComingSoonLoginBanner from 'components/login/ComingSoonLoginBanner';
import LoginFooter from 'components/login/LoginFooter';
import LoginForm from 'components/login/LoginForm';
import LoginSideFeature from 'components/login/LoginSideFeature';
import MinimalPage from 'components/ui/layout/minimalPage/MinimalPage';
import OauthButtons from 'components/auth/OauthButtons';
import SideFeatureImage from 'assets/img/san_francisco-ca-usa.jpg';

export default function LoginPage() {
  const { formatMessage } = useIntl();

  return (
    <MinimalPage
      footer={<LoginFooter />}
      header={<BrandButtonLink />}
      htmlTitle={formatMessage({ defaultMessage: 'Log in' })}
      sideFeature={<LoginSideFeature />}
      sideFeatureProps={{
        alignItems: 'flex-end',
        style: {
          backgroundImage: `url(${SideFeatureImage})`,
        },
      }}
    >
      <Typography component='h1' mb={4} textAlign='center' variant='h4'>
        {formatMessage({ defaultMessage: 'Log in' })}
      </Typography>
      <ComingSoonLoginBanner mb={3} />
      <Stack spacing={3}>
        {hasOauthFeatureFlag() && (
          <>
            <OauthButtons />
            <Divider>{formatMessage({ defaultMessage: 'or' })}</Divider>
          </>
        )}
        <LoginForm />
      </Stack>
    </MinimalPage>
  );
}
