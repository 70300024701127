import { ReactElement } from 'react';
import { useSemanticBreakpoints } from 'util/theme/hooks';
import NavBar from 'components/nav/NavBar';
import TabBar from 'components/nav/TabBar';

export type TypeNavWrapperProps = {
  component: ReactElement;
};

export default function NavWrapper(props: TypeNavWrapperProps) {
  const { component } = props;
  const { mobile } = useSemanticBreakpoints();

  return (
    <>
      <NavBar />
      {component}
      {mobile && <TabBar />}
    </>
  );
}
