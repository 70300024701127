import {
  createPreference,
  fetchPreference,
  updatePreference,
} from 'util/models/preference/services';
import { defaultUseQueryOptions } from 'util/common/constants/queryConstants';
import {
  generateNewPreference,
  getPreferenceQueryKey,
} from 'util/models/preference/helpers';
import { queryClient } from 'util/common/constants/queryConstants';
import {
  TypeBasePreference,
  TypePartialPreference,
  TypePreference,
  TypeUpdatePreferenceMutationContext,
} from 'util/models/preference/types';
import {
  TypeUseMutationOptions,
  TypeUseQueryOptions,
} from 'util/common/types/queryTypes';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useUserDetailByUserIdQuery } from '../userDetail/hooks';
import useAuth from 'util/auth/hooks/useAuth';

export const useCreatePreferenceMutation = (
  mutationOptions: TypeUseMutationOptions = {}
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: (preference: TypeBasePreference) =>
      createPreference(generateNewPreference(preference)),
  });
};

export const usePreferenceQuery = (
  preferenceId: string,
  queryOptions: TypeUseQueryOptions = {}
) => {
  const { data, isError, isLoading, ...rest } = useQuery({
    ...defaultUseQueryOptions,
    ...queryOptions,
    queryKey: getPreferenceQueryKey(preferenceId),
    queryFn: () => fetchPreference(preferenceId),
    select: (data: TypePreference) => data,
  });

  return {
    ...rest,
    loadingPreference: isLoading,
    preference: data,
    preferenceErrored: isError,
  };
};

export const usePrimaryPreferenceQuery = () => {
  const { isAuthenticated, user } = useAuth();
  const { loadingUserDetail, userDetail } = useUserDetailByUserIdQuery(
    user?.uid ?? '',
    { enabled: isAuthenticated() }
  );
  const { loadingPreference, preference, preferenceErrored, ...rest } =
    usePreferenceQuery(userDetail?.primaryPreferenceId ?? '', {
      enabled: !loadingUserDetail && !!userDetail,
    });

  return {
    ...rest,
    loadingPreference,
    preference,
    preferenceErrored,
  };
};

export const useUpdatePreferenceMutation = () => {
  return useMutation({
    mutationFn: ({
      preferenceId,
      partialPreference,
    }: {
      preferenceId: string;
      partialPreference: TypePartialPreference;
    }) => updatePreference(preferenceId, partialPreference),
    onMutate: async ({
      preferenceId,
    }): Promise<TypeUpdatePreferenceMutationContext> => {
      const queryKey = getPreferenceQueryKey(preferenceId);
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey,
      });

      // Snapshot the previous value
      const previousPreference: TypePreference | undefined =
        queryClient.getQueryData(queryKey);

      // Return a context with the previous preference
      return { previousPreference };
    },
    // If the mutation fails, use the context we returned above
    onError: (_error, _varaibles, context) => {
      if (!context?.previousPreference) {
        return;
      }

      queryClient.setQueryData(
        getPreferenceQueryKey(context.previousPreference.id),
        context.previousPreference
      );
    },
    onSuccess: (_data, { partialPreference }, context) => {
      if (!context?.previousPreference) {
        return;
      }

      // Remove missing values so we don't overwrite the existing preference values
      const prunedPartialPreference = (
        Object.keys(partialPreference) as (keyof TypePartialPreference)[]
      )
        .filter((key) => partialPreference[key] != null)
        .reduce(
          (prunedPartialPreference, key) => ({
            ...prunedPartialPreference,
            [key]: partialPreference[key],
          }),
          {}
        );

      queryClient.setQueryData(
        getPreferenceQueryKey(context.previousPreference.id),
        {
          ...context.previousPreference,
          ...prunedPartialPreference,
        }
      );
    },
  });
};
