import { Box, Chip } from '@mui/material';
import { useIntl } from 'react-intl';

export default function LoginSideFeature() {
  const { formatMessage } = useIntl();

  return (
    <Box m={2}>
      <Chip
        label={formatMessage({ defaultMessage: '📍 San Francisco, CA' })}
        size='small'
        sx={{
          color: 'white',
        }}
      />
    </Box>
  );
}
