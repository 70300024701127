import { Menu, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import useAuth from 'util/auth/hooks/useAuth';

export type TypeUserMenuProps = {
  anchorElement?: HTMLElement;
  onClose: () => void;
  open: boolean;
};

export default function UserMenu(props: TypeUserMenuProps) {
  const { anchorElement, onClose, open } = props;

  const { logOut } = useAuth();
  const { formatMessage } = useIntl();

  return (
    <Menu anchorEl={anchorElement} onClose={onClose} open={open}>
      <MenuItem onClick={logOut}>
        {formatMessage({ defaultMessage: 'Log out' })}
      </MenuItem>
    </Menu>
  );
}
