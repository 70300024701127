import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';

export default function LoginFooter() {
  const { formatMessage } = useIntl();

  return (
    <Stack direction='row' spacing={1}>
      <Typography>
        {formatMessage({ defaultMessage: "Don't have an account yet?" })}
      </Typography>
      <Link component={RouterLink} to='/signup'>
        {formatMessage({ defaultMessage: 'Sign up' })}
      </Link>
    </Stack>
  );
}
