import { createContext, ReactNode } from 'react';
import { EnumThemeMode } from 'util/theme/types';
import { useThemeModeManager } from 'util/theme/hooks';

export type TypeThemeModeContextValue = {
  setThemeMode: (mode: EnumThemeMode) => void;
  themeMode: EnumThemeMode;
  toggleDarkThemeMode: () => void;
};

export const ThemeModeContext = createContext<TypeThemeModeContextValue>({
  setThemeMode: () => {},
  themeMode: EnumThemeMode.LIGHT,
  toggleDarkThemeMode: () => {},
});

export type TypeThemeModeProviderProps = {
  children: ReactNode;
};

export default function ThemeModeProvider(props: TypeThemeModeProviderProps) {
  const { children } = props;
  const value = useThemeModeManager();

  return (
    <ThemeModeContext.Provider value={value}>
      {children}
    </ThemeModeContext.Provider>
  );
}
