export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const isLocal = (): boolean => {
  return location.hostname === 'localhost';
};

export const isProduction = (): boolean => {
  return process.env.NODE_ENV === 'production';
};
