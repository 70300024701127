import { Box, BoxProps, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSemanticBreakpoints } from 'util/theme/hooks';
import betaPageLogo from 'assets/img/beta_page-logo.png';
import seniortopiaShowLogo from 'assets/img/seniortopia_show-logo.png';
import steemhuntLogo from 'assets/img/steemhunt-logo.png';

export type TypeFeaturedOnLogosProps = BoxProps;

export default function FeaturedOnLogos(props: TypeFeaturedOnLogosProps) {
  const { ...boxProps } = props;
  const { formatMessage } = useIntl();
  const { mobile } = useSemanticBreakpoints();

  return (
    <Box {...boxProps}>
      <Typography
        component='div'
        mb={3}
        sx={{ fontFamily: 'Caveat' }}
        textAlign='center'
        variant='h5'
      >
        {formatMessage({ defaultMessage: 'Featured on:' })}
      </Typography>
      <Stack
        alignItems='center'
        direction={mobile ? 'column' : 'row'}
        justifyContent='space-around'
        spacing={3}
      >
        <img
          alt={formatMessage({
            defaultMessage: 'BetaPage',
          })}
          height={35}
          src={betaPageLogo}
        />
        <img
          alt={formatMessage({
            defaultMessage: 'Steemhunt',
          })}
          height={25}
          src={steemhuntLogo}
        />
        <img
          alt={formatMessage({
            defaultMessage: 'Seniortopia Show',
          })}
          height={45}
          src={seniortopiaShowLogo}
        />
      </Stack>
    </Box>
  );
}
