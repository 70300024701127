import { isDevelopment } from 'util/browser/helpers';

export const hasLoginFeatureFlag = (): boolean => {
  return isDevelopment();
};

export const hasMatchScopeFeatureFlag = (): boolean => {
  return isDevelopment();
};

export const hasOauthFeatureFlag = (): boolean => {
  return isDevelopment();
};

export const hasPlacePageFeatureFlag = (): boolean => {
  return isDevelopment();
};

export const hasPreferenceChecklistFeatureFlag = (): boolean => {
  return isDevelopment() && hasPlacePageFeatureFlag();
};

export const hasSignupFeatureFlag = (): boolean => {
  return isDevelopment();
};
