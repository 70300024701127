import { Box, BoxProps, Button, Stack, Typography } from '@mui/material';
import { EnumMatchTopicKey } from 'util/match/types/topicTypes';
import {
  getAllQuestionKeysForTopic,
  getQuestionKeysForTopic,
  getTopic,
} from 'util/match/helpers/questionHelpers';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import MatchQuestion from 'components/match/MatchQuestion';

export type TypeMatchTopicProps = BoxProps & {
  topicKey: EnumMatchTopicKey;
};

export default function MatchTopic(props: TypeMatchTopicProps) {
  const { topicKey, ...boxProps } = props;
  const topic = getTopic(topicKey);

  const { resetField } = useFormContext();
  const { formatMessage } = useIntl();

  if (!topic) {
    return null;
  }

  const resetQuestions = () => {
    for (const questionKey of getAllQuestionKeysForTopic(topicKey)) {
      resetField(questionKey);
    }
  };

  return (
    <Box {...boxProps}>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        mb={1}
      >
        <Typography fontWeight='bold'>{topic.name}</Typography>
        <Button onClick={resetQuestions} size='small'>
          {formatMessage({ defaultMessage: 'Clear' })}
        </Button>
      </Stack>
      {getQuestionKeysForTopic(topic.key).map((questionKey) => {
        return <MatchQuestion key={questionKey} questionKey={questionKey} />;
      })}
    </Box>
  );
}
