import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
} from '@mui/material';
import { EnumRouteKey } from 'util/routing/types';
import { FiLogIn, FiUser } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import { RiEqualizerLine } from 'react-icons/ri';
import { useCurrentRouteKey } from 'util/routing/hooks';
import { useIntl } from 'react-intl';
import { useMenu } from 'util/common/hooks';
import useAuth from 'util/auth/hooks/useAuth';
import UserMenu from 'components/user/UserMenu';

export const TABBAR_HEIGHT = 55;

export default function TabBar() {
  const { isAuthenticated } = useAuth();
  const routeKey = useCurrentRouteKey();
  const { formatMessage } = useIntl();
  const { anchorElement, closeMenu, open, openMenu } = useMenu();

  return (
    <>
      {/* This box pushes the main content up because the BottomNavigation box has a fixed position */}
      <Box height={TABBAR_HEIGHT} />
      <Box bottom={0} height={TABBAR_HEIGHT} position='fixed' width='100%'>
        <Divider />
        <BottomNavigation value={routeKey} showLabels>
          <BottomNavigationAction
            component={RouterLink}
            icon={<RiEqualizerLine />}
            label={formatMessage({ defaultMessage: 'Match' })}
            to='/match'
            value={EnumRouteKey.MATCH}
          />
          {isAuthenticated() ? (
            <BottomNavigationAction
              icon={<FiUser />}
              label={formatMessage({ defaultMessage: 'You' })}
              onClick={openMenu}
            />
          ) : (
            <BottomNavigationAction
              component={RouterLink}
              icon={<FiLogIn />}
              label={formatMessage({ defaultMessage: 'Log in' })}
              to='/login'
              value={EnumRouteKey.LOGIN}
            />
          )}
        </BottomNavigation>
        <UserMenu
          anchorElement={anchorElement}
          onClose={closeMenu}
          open={open}
        />
      </Box>
    </>
  );
}
