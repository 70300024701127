import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { intl } from 'util/lang/helpers';
import { useIntl } from 'react-intl';
import nickKAvatar from 'assets/img/nick_k-avatar.jpg';
import sarahSAvatar from 'assets/img/sarah_s-avatar.jpg';
import sidVAvatar from 'assets/img/sid_v-avatar.jpg';

export default function TestimonialsGrid() {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Typography component='h2' variant='h6'>
        {formatMessage({
          defaultMessage: 'Thoughts from Titans worldwide',
        })}
      </Typography>
      <Grid py={3} spacing={3} container>
        {testimonials.map((testimonial) => {
          return (
            <Grid key={testimonial.name} xs={6} md={4} item>
              <Card sx={{ height: '100%' }} variant='outlined'>
                <CardContent sx={{ height: '100%' }}>
                  <Stack
                    alignItems='center'
                    height='100%'
                    justifyContent='center'
                    spacing={3}
                  >
                    <Typography
                      component='p'
                      textAlign='center'
                      variant='body1'
                    >
                      {testimonial.quote}
                    </Typography>
                    <Stack alignItems='center' direction='row' spacing={1}>
                      <Avatar
                        src={testimonial.image}
                        sx={{
                          height: 20,
                          width: 20,
                        }}
                      />
                      <Typography
                        component='h3'
                        sx={{ fontFamily: 'Caveat' }}
                        textAlign='center'
                        variant='h6'
                      >
                        {testimonial.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

const testimonials = [
  {
    quote: intl.formatMessage({
      defaultMessage:
        '"Such an easy and quick way to find your perfect place to live."',
    }),
    image: nickKAvatar,
    name: intl.formatMessage({
      defaultMessage: 'Nick K.',
    }),
  },
  {
    quote: intl.formatMessage({
      defaultMessage:
        '"MapTitan really helps. I\'ve never been happier. It helped me pinpoint where to live."',
    }),
    image: sarahSAvatar,
    name: intl.formatMessage({
      defaultMessage: 'Sarah S.',
    }),
  },
  {
    quote: intl.formatMessage({
      defaultMessage:
        '"It\'s very informative. The best website to find your best place to live."',
    }),
    image: sidVAvatar,
    name: intl.formatMessage({
      defaultMessage: 'Sim V.',
    }),
  },
];
