import { EnumPageContainerMaxSize } from 'util/layout/types';
import { ReactElement, ReactNode } from 'react';
import { useBreakpointValue, useSemanticBreakpoints } from 'util/theme/hooks';
import Grid, { Grid2Props } from '@mui/material/Unstable_Grid2';
import HtmlTitle from 'components/common/html/HtmlTitle';
import MinimalPageContainer from 'components/ui/layout/minimalPage/MinimalPageContainer';

const COLUMNS_COUNT = 12;

export type TypeMinimalPageProps = {
  children: ReactNode;
  footer?: ReactElement;
  header?: ReactElement;
  htmlTitle: string;
  sideFeature: ReactElement;
  sideFeatureProps?: Grid2Props;
};

export default function MinimalPage(props: TypeMinimalPageProps) {
  const { children, footer, header, htmlTitle, sideFeature, sideFeatureProps } =
    props;

  const { desktop } = useSemanticBreakpoints();
  const sideFeatureColumnsCount =
    useBreakpointValue({
      xs: 0,
      lg: 3,
      xl: 4,
    }) || 0;

  return (
    <>
      <HtmlTitle title={htmlTitle} />
      <Grid height='100vh' container>
        {desktop && (
          <Grid
            {...sideFeatureProps}
            alignSelf='stretch'
            bgcolor='gray'
            display='flex'
            height='100%'
            sx={{
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            xs={sideFeatureColumnsCount}
          >
            {sideFeature}
          </Grid>
        )}
        <Grid xs={COLUMNS_COUNT - sideFeatureColumnsCount}>
          <MinimalPageContainer
            header={header}
            footer={footer}
            maxSize={EnumPageContainerMaxSize.SMALL}
          >
            {children}
          </MinimalPageContainer>
        </Grid>
      </Grid>
    </>
  );
}
