import { EnumMatchCategoryKey } from '../types/categoryTypes';
import {
  EnumMatchQuestionControlType,
  EnumMatchQuestionKey,
  TypeMatchQuestionBoundControlConfig,
  TypeMatchQuestionBranchControlConfig,
} from 'util/match/types/questionTypes';
import {
  getAllQuestionKeys,
  getAllQuestionKeysForCategory,
  getQuestion,
  getQuestionControlConfig,
  isBoundQuestion,
  isBranchQuestion,
  isMetaQuestion,
} from 'util/match/helpers/questionHelpers';
import {
  TypeMatchFormValue,
  TypeMatchQuestionFormValue,
} from 'util/match/types/formTypes';
import { TypePreferenceFiltersByKey } from 'util/models/preference/types';

export const convertMatchFormValueToPreferenceFiltersByKey = (
  formValue: TypeMatchFormValue
): TypePreferenceFiltersByKey => {
  return (Object.keys(formValue) as EnumMatchQuestionKey[]).reduce(
    (filtersByKey, questionKey: EnumMatchQuestionKey) => {
      const { placeDataKey } = getQuestion(questionKey);
      // Meta questions (no 1:1 mapping to place data keys) and questions with default values are ignored
      if (
        !placeDataKey ||
        isDefaultQuestionFormValue(questionKey, formValue[questionKey])
      ) {
        return filtersByKey;
      }

      /**
       * Bound questions use config values for min and max because the user input is usually just opt-in (e.g. a
       * checkbox)
       */
      if (isBoundQuestion(questionKey)) {
        const { max, min } = getQuestionControlConfig(
          questionKey
        ) as TypeMatchQuestionBoundControlConfig;
        return {
          ...filtersByKey,
          [placeDataKey]: {
            min,
            max,
          },
        };
      }

      // Range questions have min and max values
      return {
        ...filtersByKey,
        [placeDataKey]: {
          min: (formValue[questionKey] as number[])[0],
          max: (formValue[questionKey] as number[])[1],
        },
      };
    },
    {} as TypePreferenceFiltersByKey
  );
};

export const convertPreferenceFiltersByKeyToMatchFormValue = (
  preferenceFiltersByKey: TypePreferenceFiltersByKey = {}
): TypeMatchFormValue => {
  const defaultFormValue = getDefaultMatchFormValue();
  if (!Object.keys(preferenceFiltersByKey).length) {
    return defaultFormValue;
  }

  return getAllQuestionKeys().reduce((formValue, questionKey) => {
    const { controlConfig, placeDataKey } = getQuestion(questionKey);
    if (!placeDataKey) {
      // For branch questions, set the form value to the option key that's present in the preference
      if (isBranchQuestion(questionKey)) {
        for (const option of (
          controlConfig as TypeMatchQuestionBranchControlConfig
        ).options) {
          const { placeDataKey: optionPlaceDataKey } = getQuestion(
            option.questionKey
          );
          if (
            !optionPlaceDataKey ||
            !preferenceFiltersByKey[optionPlaceDataKey]
          ) {
            continue;
          }

          return {
            ...formValue,
            [questionKey]: option.questionKey,
          };
        }
      }

      return formValue;
    }

    const filter = preferenceFiltersByKey[placeDataKey];

    // Bound questions are represented by the presence of the filter
    if (isBoundQuestion(questionKey)) {
      return {
        ...formValue,
        [questionKey]: !!filter,
      };
    }

    if (!filter) {
      return formValue;
    }

    const { max, min } = filter;
    return {
      ...formValue,
      [questionKey]: [min, max],
    };
  }, defaultFormValue);
};

export const getAnsweredQuestionCountForCategory = (
  categoryKey: EnumMatchCategoryKey,
  formValue: TypeMatchFormValue
): number => {
  const questionKeys = getAllQuestionKeysForCategory(categoryKey);
  return (Object.keys(formValue) as EnumMatchQuestionKey[])
    .filter((questionKey) => questionKeys.includes(questionKey))
    .reduce((sum, questionKey) => {
      if (
        isMetaQuestion(questionKey) ||
        isDefaultQuestionFormValue(questionKey, formValue[questionKey])
      ) {
        return sum;
      }

      return sum + 1;
    }, 0);
};

export const getDefaultMatchFormValue = (): TypeMatchFormValue => {
  return getAllQuestionKeys().reduce((defaultValue, questionKey) => {
    return {
      ...defaultValue,
      [questionKey]: getDefaultFormValueForQuestion(questionKey),
    };
  }, {}) as TypeMatchFormValue;
};

export const getDefaultFormValueForQuestion = (
  questionKey: EnumMatchQuestionKey
): EnumMatchQuestionKey | false | number[] | null | undefined => {
  const question = getQuestion(questionKey);
  if (!question) {
    return null;
  }

  const controlConfig = question.controlConfig;
  switch (controlConfig.controlType) {
    case EnumMatchQuestionControlType.BOUND:
      return false;
    case EnumMatchQuestionControlType.BRANCH:
      return controlConfig.defaultQuestionKey;
    case EnumMatchQuestionControlType.RANGE:
      return [controlConfig.min, controlConfig.max];
    default:
      return null;
  }
};

const isDefaultQuestionFormValue = (
  questionKey: EnumMatchQuestionKey,
  questionFormValue: TypeMatchQuestionFormValue
): boolean => {
  const question = getQuestion(questionKey);
  if (!question) {
    return true;
  }

  const controlConfig = question.controlConfig;
  switch (controlConfig.controlType) {
    case EnumMatchQuestionControlType.BOUND:
      return !questionFormValue;
    case EnumMatchQuestionControlType.BRANCH:
      return questionFormValue === controlConfig.defaultQuestionKey;
    case EnumMatchQuestionControlType.RANGE:
      return (
        (questionFormValue as number[])[0] === controlConfig.min &&
        (questionFormValue as number[])[1] === controlConfig.max
      );
    default:
      return true;
  }
};
