import { Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  EnumMatchQuestionKey,
  TypeMatchQuestionBoundControlConfig,
} from 'util/match/types/questionTypes';

export type TypeMatchQuestionBoundFormControlProps = {
  controlConfig: TypeMatchQuestionBoundControlConfig;
  questionKey: EnumMatchQuestionKey;
};

export default function MatchQuestionBoundFormControl(
  props: TypeMatchQuestionBoundFormControlProps
) {
  const { controlConfig, questionKey } = props;
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={questionKey}
        render={({ field }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox checked={!!field.value} onChange={field.onChange} />
              }
              label={controlConfig.labelText}
              sx={{ width: '100%' }}
            />
          );
        }}
      />
    </>
  );
}
