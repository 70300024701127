import { Box, Divider } from '@mui/material';
import {
  EnumPageContainerMaxSize,
  EnumPageSidebarRank,
} from 'util/layout/types';
import { PAGE_MAX_COLUMN_WIDTH } from 'util/layout/constants';
import { ReactElement, ReactNode } from 'react';
import { useSemanticBreakpoints } from 'util/theme/hooks';
import Grid from '@mui/material/Unstable_Grid2';
import HtmlTitle from 'components/common/html/HtmlTitle';
import Layer from 'components/ui/core/modal/Layer';
import PageContainer from 'components/ui/layout/page/PageContainer';
import PageHeader, {
  TypePageHeaderChildren,
  TypePageHeaderProps,
} from 'components/ui/layout/page/PageHeader';
import PageSidebar from 'components/ui/layout/page/PageSidebar';
import PageToolbar from 'components/ui/layout/page/PageToolbar';
import useSidebarColumnWidth from 'util/layout/hooks/useSidebarColumnWidth';

export type TypePageSidebarProps = {
  open: boolean;
};

export type TypePageProps = {
  children: ReactNode;
  footer?: ReactElement;
  header?: TypePageHeaderChildren;
  headerProps?: Pick<
    TypePageHeaderProps,
    'backgroundColor' | 'backgroundImageUrl'
  >;
  htmlTitle?: string;
  maxSize?: EnumPageContainerMaxSize;
  onCloseSidebars?: () => void;
  primarySidebar?: ReactElement;
  primarySidebarProps?: TypePageSidebarProps;
  secondarySidebar?: ReactElement;
  secondarySidebarProps?: TypePageSidebarProps;
  toolbar?: ReactElement;
};

export default function Page(props: TypePageProps) {
  const {
    children,
    footer,
    header,
    headerProps,
    htmlTitle,
    maxSize,
    onCloseSidebars,
    primarySidebar,
    primarySidebarProps,
    secondarySidebar,
    secondarySidebarProps,
    toolbar,
  } = props;

  const { mobile, tablet, desktop } = useSemanticBreakpoints();
  const { primarySidebarColumnWidth, secondarySidebarColumnWidth } =
    useSidebarColumnWidth();

  const primarySidebarIsOpen = !!primarySidebar && !!primarySidebarProps?.open;
  const secondarySidebarIsOpen =
    !!secondarySidebar && !!secondarySidebarProps?.open;

  const isPrimarySidebarVisible = (): boolean => {
    return !mobile && primarySidebarIsOpen;
  };

  const isSecondarySidebarVisible = (): boolean => {
    return desktop && secondarySidebarIsOpen;
  };

  const calculateSidebarsWidth = (): number => {
    return (
      (primarySidebarIsOpen ? primarySidebarColumnWidth : 0) +
      (secondarySidebarIsOpen ? secondarySidebarColumnWidth : 0)
    );
  };

  const calculateContentColumnWidth = (): number => {
    return PAGE_MAX_COLUMN_WIDTH - calculateSidebarsWidth();
  };

  return (
    <>
      <HtmlTitle title={htmlTitle} />
      <Grid container>
        {isPrimarySidebarVisible() && (
          <PageSidebar rank={EnumPageSidebarRank.PRIMARY}>
            {/* On medium screen sizes, the sidebars stack */}
            {tablet && secondarySidebarIsOpen
              ? secondarySidebar
              : primarySidebar}
          </PageSidebar>
        )}
        {isSecondarySidebarVisible() && (
          <PageSidebar rank={EnumPageSidebarRank.SECONDARY}>
            {secondarySidebar}
          </PageSidebar>
        )}
        <Grid
          sx={{
            overflowY: 'auto',
          }}
          xs={calculateContentColumnWidth()}
          xsOffset={calculateSidebarsWidth()}
        >
          {header && (
            <PageHeader {...headerProps} maxSize={maxSize}>
              {header}
            </PageHeader>
          )}
          {toolbar && <PageToolbar maxSize={maxSize}>{toolbar}</PageToolbar>}
          <PageContainer as='main' maxSize={maxSize}>
            {children}
          </PageContainer>
          {footer && (
            <Box mb={2}>
              <Divider />
              <PageContainer
                as='footer'
                maxSize={EnumPageContainerMaxSize.FULL}
              >
                {footer}
              </PageContainer>
            </Box>
          )}
        </Grid>
      </Grid>
      <Layer
        onClose={onCloseSidebars}
        open={mobile && (primarySidebarIsOpen || secondarySidebarIsOpen)}
      >
        {secondarySidebarIsOpen ? secondarySidebar : primarySidebar}
      </Layer>
    </>
  );
}

/**
 * TODO:
 * For mobile,
 *  - sidebar can be two variants: sheet and inline
 *    - if sheet, sidebar opens as modal
 *    - if inline, sidebar renders below the main content
 */
