import { DocumentData, getDocs, Query } from 'firebase/firestore';

export async function getFirstDoc<T>(query: Query<DocumentData>): Promise<T> {
  const docs = await getDocsForQuery(query);

  if (!docs.length) {
    throw new Error();
  }

  return docs[0] as T;
}

export async function getDocsForQuery<T>(
  query: Query<DocumentData>
): Promise<T[]> {
  const querySnapshot = await getDocs(query);
  return querySnapshot.docs.map((doc) => doc.data()) as T[];
}
