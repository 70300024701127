import { EnumPageContainerMaxSize } from 'util/layout/types';
import { privacyDocument } from 'util/legal/constants';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Footer from 'components/footer/Footer';
import LegalDocument from 'components/legal/LegalDocument';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Page from 'components/ui/layout/page/Page';
import SimpleHeader from 'components/ui/layout/header/SimpleHeader';

dayjs.extend(localizedFormat);

export default function PrivacyPage() {
  const { formatMessage } = useIntl();

  return (
    <Page
      footer={<Footer />}
      header={
        <SimpleHeader
          subtitleText={formatMessage(
            { defaultMessage: 'Updated on {dateString}' },
            {
              dateString: dayjs(privacyDocument.updatedAt).format('LL'),
            }
          )}
          titleText={formatMessage({ defaultMessage: 'Privacy Policy' })}
        />
      }
      htmlTitle={formatMessage({ defaultMessage: 'Privacy Policy' })}
      maxSize={EnumPageContainerMaxSize.NARROW}
    >
      <LegalDocument document={privacyDocument} mb={6} />
    </Page>
  );
}
