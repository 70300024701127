import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  Tooltip,
} from '@mui/material';

export type TypeIconButtonProps = MuiIconButtonProps & {
  tooltipText: string;
};

export default function IconButton(props: TypeIconButtonProps) {
  const { tooltipText, ...rest } = props;

  return (
    <Tooltip title={tooltipText}>
      <MuiIconButton {...rest} />
    </Tooltip>
  );
}
