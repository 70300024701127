import {
  collection,
  FieldPath,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { db } from 'util/common/constants/firebaseConstants';
import {
  getDocsForQuery,
  getFirstDoc,
} from 'util/common/services/queryServices';
import { TypePlace } from 'util/models/place/types';

const placesCollection = collection(db, 'places');

export const fetchMatchPlaces = async (): Promise<TypePlace[]> => {
  const populationFieldPath = new FieldPath('data', 'population');
  const slugQuery = query(
    placesCollection,
    where(populationFieldPath, '>=', 80000),
    limit(1000),
    orderBy(populationFieldPath, 'desc')
  );
  return getDocsForQuery<TypePlace>(slugQuery);
};

export const fetchPlaceByPlaceId = async (
  placeId: string
): Promise<TypePlace> => {
  const placeIdQuery = query(placesCollection, where('placeId', '==', placeId));
  return getFirstDoc<TypePlace>(placeIdQuery);
};

export const fetchPlaceBySlug = async (slug: string): Promise<TypePlace> => {
  const slugQuery = query(placesCollection, where('slug', '==', slug));
  return getFirstDoc<TypePlace>(slugQuery);
};
