import { Accordion as MuiAccordion, AccordionProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TypeAccordionProps = Omit<AccordionProps, 'elevation'>;

const Accordion = styled((props: TypeAccordionProps) => (
  <MuiAccordion elevation={0} disableGutters {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export default Accordion;
