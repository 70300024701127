import { customAlphabet } from 'nanoid';
import {
  defaultEmailSubscriptionStatusByKey,
  emptyUserPlaceIdsByKey,
  USER_CODE_ALPHABET,
  USER_CODE_LENGTH,
} from 'util/models/userDetail/constants';
import { rootQueryKeysByKey } from 'util/common/constants/queryConstants';
import {
  TypeBaseUserDetail,
  TypeNewUserDetail,
} from 'util/models/userDetail/types';
const nanoid = customAlphabet(USER_CODE_ALPHABET, USER_CODE_LENGTH);

const generateUserCode = (): string => {
  return nanoid();
};

export const generateNewUserDetail = (
  baseUserDetail: TypeBaseUserDetail
): TypeNewUserDetail => {
  return {
    ...baseUserDetail,
    code: generateUserCode(),
    demographicData: {},
    emailSubscriptionStatusByKey: defaultEmailSubscriptionStatusByKey,
    friendStatusById: {},
    placeIdsByKey: emptyUserPlaceIdsByKey,
  };
};

export const getUserDetailByUserIdQueryKey = (userId: string): string[] => {
  return [...rootQueryKeysByKey.USER_DETAILS, ':userId', userId];
};
