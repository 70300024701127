import { Box, Button, Typography } from '@mui/material';
import { EnumMatchCategoryKey } from 'util/match/types/categoryTypes';
import { getTopicKeysForCategory } from 'util/match/helpers/questionHelpers';
import { matchCategoriesByKey } from 'util/match/constants/categoryConstants';
import { RiCloseLine } from 'react-icons/ri';
import {
  EnumSidebarFormat,
  SIDEBAR_CONTENT_EDGE_SPACE,
} from 'util/layout/constants';
import { useIntl } from 'react-intl';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from 'components/ui/core/button/IconButton';
import MatchTopic from 'components/match/MatchTopic';

type TypeMatchPageSecondarySidebarProps = {
  activeCategoryKey?: EnumMatchCategoryKey;
  format: EnumSidebarFormat;
  onClose: () => void;
};

export default function MatchPageSecondarySidebar(
  props: TypeMatchPageSecondarySidebarProps
) {
  const { activeCategoryKey, format, onClose } = props;
  const { formatMessage } = useIntl();

  if (!activeCategoryKey) {
    return null;
  }

  return (
    <Box m={SIDEBAR_CONTENT_EDGE_SPACE}>
      <Grid mb={3} container>
        <Grid
          alignItems='center'
          display='flex'
          justifyContent='center'
          xs={6}
          xsOffset={3}
        >
          <Typography fontWeight='bold' textAlign='center'>
            {matchCategoriesByKey[activeCategoryKey].name}
          </Typography>
        </Grid>
        <Grid
          alignItems='center'
          display='flex'
          justifyContent='flex-end'
          xs={3}
        >
          {format === EnumSidebarFormat.ADJACENT ? (
            <IconButton
              onClick={onClose}
              size='small'
              tooltipText={formatMessage({
                defaultMessage: 'Close sidebar',
              })}
            >
              <RiCloseLine />
            </IconButton>
          ) : (
            <Button onClick={onClose}>
              {formatMessage({
                defaultMessage: 'Done',
              })}
            </Button>
          )}
        </Grid>
      </Grid>
      {getTopicKeysForCategory(activeCategoryKey).map((topicKey) => {
        return <MatchTopic key={topicKey} mb={4} topicKey={topicKey} />;
      })}
    </Box>
  );
}
