import {
  formatCurrency,
  formatInteger,
  formatLargeInteger,
} from 'util/common/helpers/numberHelpers';
import {
  EnumMatchQuestionControlType,
  EnumMatchQuestionKey,
  TypeMatchQuestion,
  TypeMatchQuestionBoundControlConfig,
  TypeMatchQuestionRangeControlConfig,
} from 'util/match/types/questionTypes';
import { EnumMatchTopicKey } from 'util/match/types/topicTypes';
import { EnumPlaceDataKey } from 'util/models/place/types';
import { EnumPreferenceFilterVariableKey } from 'util/models/preference/types';
import { getBedroomsCountText, intl } from 'util/lang/helpers';
import dayjs from 'dayjs';

export const orderedMatchQuestionKeysByTopicKey: {
  [key in EnumMatchTopicKey]: EnumMatchQuestionKey[];
} = {
  [EnumMatchTopicKey.AGE]: [EnumMatchQuestionKey.AGE],
  [EnumMatchTopicKey.AGE_GROUPS]: [],
  [EnumMatchTopicKey.COMMUTE_METHOD]: [
    EnumMatchQuestionKey.WALKING_COMMUTE,
    EnumMatchQuestionKey.BIKING_COMMUTE,
    EnumMatchQuestionKey.PUBLIC_TRANSIT_COMMUTE,
    EnumMatchQuestionKey.DRIVING_COMMUTE,
  ],
  [EnumMatchTopicKey.COMMUTE_TIME]: [],
  [EnumMatchTopicKey.EDUCATIONAL_ATTAINMENT]: [],
  [EnumMatchTopicKey.ENTREPRENEURSHIP]: [],
  [EnumMatchTopicKey.FAMILY]: [],
  [EnumMatchTopicKey.GENDER]: [],
  [EnumMatchTopicKey.GENERATION]: [],
  [EnumMatchTopicKey.HOUSE_PRICE]: [EnumMatchQuestionKey.HOUSE_PRICE],
  [EnumMatchTopicKey.HOUSING_AGE]: [],
  [EnumMatchTopicKey.HOUSING_MARKET]: [],
  [EnumMatchTopicKey.HOUSING_VACANCY]: [],
  [EnumMatchTopicKey.HOUSING_YEAR_BUILT]: [
    EnumMatchQuestionKey.HOUSING_YEAR_BUILT,
  ],
  [EnumMatchTopicKey.INCOME]: [EnumMatchQuestionKey.INCOME],
  [EnumMatchTopicKey.INCOME_EQUALITY]: [EnumMatchQuestionKey.INCOME_INEQUALITY],
  [EnumMatchTopicKey.INCOME_SOURCE]: [],
  [EnumMatchTopicKey.MARITAL_STATUS]: [],
  [EnumMatchTopicKey.MILITARY]: [EnumMatchQuestionKey.VETERANS],
  [EnumMatchTopicKey.NATIONALITY]: [],
  [EnumMatchTopicKey.PARENTS]: [EnumMatchQuestionKey.PARENTS_WITH_INFANTS],
  [EnumMatchTopicKey.POVERTY]: [
    EnumMatchQuestionKey.POVERTY,
    EnumMatchQuestionKey.INCOME_DEFICIT,
  ],
  [EnumMatchTopicKey.POPULATION]: [EnumMatchQuestionKey.POPULATION],
  [EnumMatchTopicKey.POPULATION_CHANGE]: [EnumMatchQuestionKey.NEW_RESIDENTS],
  [EnumMatchTopicKey.PROSPERITY]: [],
  [EnumMatchTopicKey.REMOTE_WORK]: [EnumMatchQuestionKey.REMOTE_WORKERS],
  [EnumMatchTopicKey.RENT]: [EnumMatchQuestionKey.RENT],
  [EnumMatchTopicKey.RENT_TO_INCOME]: [],
  [EnumMatchTopicKey.STUDENTS]: [],
  [EnumMatchTopicKey.TENANTS]: [],
  [EnumMatchTopicKey.UNDERGRADUATE_FOCUS]: [
    EnumMatchQuestionKey.BACHELORS_DEGREES_ARTS,
    EnumMatchQuestionKey.BACHELORS_DEGREES_BUSINESS,
    EnumMatchQuestionKey.BACHELORS_DEGREES_EDUCATION,
    EnumMatchQuestionKey.BACHELORS_DEGREES_SCIENCE,
  ],
  [EnumMatchTopicKey.VACATION_HOUSING]: [],
  [EnumMatchTopicKey.WORK_CULTURE]: [EnumMatchQuestionKey.WEEKLY_HOURS_WORKED],
};

const aboveAverageBoundControlConfig: Pick<
  TypeMatchQuestionBoundControlConfig,
  'controlType' | 'max' | 'min'
> = {
  controlType: EnumMatchQuestionControlType.BOUND,
  max: null,
  min: EnumPreferenceFilterVariableKey.AVERAGE,
};

const belowAverageBoundControlConfig: Pick<
  TypeMatchQuestionBoundControlConfig,
  'controlType' | 'max' | 'min'
> = {
  controlType: EnumMatchQuestionControlType.BOUND,
  max: EnumPreferenceFilterVariableKey.AVERAGE,
  min: null,
};

const rentRangeControlConfig: TypeMatchQuestionRangeControlConfig = {
  controlType: EnumMatchQuestionControlType.RANGE,
  getRangeText: (lowerValue: number, upperValue: number) =>
    intl.formatMessage(
      { defaultMessage: '{lowerValue} to {upperValue}' },
      {
        lowerValue: formatCurrency(lowerValue),
        upperValue: formatCurrency(upperValue),
      }
    ),
  getValueText: (value: number) => formatCurrency(value),
  max: 4000,
  min: 0,
  minDistance: 300,
  stepSize: 100,
};

export const matchQuestionsByKey: {
  [key in EnumMatchQuestionKey]: TypeMatchQuestion;
} = {
  [EnumMatchQuestionKey.AGE]: {
    key: EnumMatchQuestionKey.AGE,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          { defaultMessage: '{lowerValue} to {upperValue} years old' },
          { lowerValue, upperValue }
        ),
      getValueText: (value: number) => value.toString(),
      max: 100,
      min: 10,
      minDistance: 10,
      stepSize: 5,
    },
    placeDataKey: EnumPlaceDataKey.MEDIAN_AGE,
  },
  [EnumMatchQuestionKey.BACHELORS_DEGREES_ARTS]: {
    key: EnumMatchQuestionKey.BACHELORS_DEGREES_ARTS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Arts and humanities',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_ARTS_AND_HUMANITIES,
  },
  [EnumMatchQuestionKey.BACHELORS_DEGREES_BUSINESS]: {
    key: EnumMatchQuestionKey.BACHELORS_DEGREES_BUSINESS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Business',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_BUSINESS,
  },
  [EnumMatchQuestionKey.BACHELORS_DEGREES_EDUCATION]: {
    key: EnumMatchQuestionKey.BACHELORS_DEGREES_EDUCATION,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Education',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_EDUCATION,
  },
  [EnumMatchQuestionKey.BACHELORS_DEGREES_SCIENCE]: {
    key: EnumMatchQuestionKey.BACHELORS_DEGREES_SCIENCE,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Science and engineering',
      }),
    },
    placeDataKey:
      EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_SCIENCE_AND_ENGINEERING,
  },
  [EnumMatchQuestionKey.BIKING_COMMUTE]: {
    key: EnumMatchQuestionKey.BIKING_COMMUTE,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Biking',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_BIKE_TO_WORK,
  },
  [EnumMatchQuestionKey.DRIVING_COMMUTE]: {
    key: EnumMatchQuestionKey.DRIVING_COMMUTE,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Driving',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_DRIVE_TO_WORK,
  },
  [EnumMatchQuestionKey.HOUSE_PRICE]: {
    key: EnumMatchQuestionKey.HOUSE_PRICE,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          { defaultMessage: '{lowerValue} to {upperValue}' },
          {
            lowerValue: formatCurrency(lowerValue),
            upperValue: formatCurrency(upperValue),
          }
        ),
      getValueText: (value: number) => formatCurrency(value),
      max: 3000000,
      min: 0,
      minDistance: 50000,
      stepSize: 50000,
    },
    placeDataKey: EnumPlaceDataKey.MEDIAN_HOUSING_VALUE,
  },
  [EnumMatchQuestionKey.HOUSING_YEAR_BUILT]: {
    key: EnumMatchQuestionKey.HOUSING_YEAR_BUILT,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          { defaultMessage: '{lowerValue} to {upperValue}' },
          { lowerValue, upperValue }
        ),
      getValueText: (value: number) => value.toString(),
      max: dayjs().year(),
      min: 1940,
      minDistance: 10,
      stepSize: 10,
    },
    placeDataKey: EnumPlaceDataKey.MEDIAN_HOUSING_YEAR_BUILT,
  },
  [EnumMatchQuestionKey.INCOME]: {
    key: EnumMatchQuestionKey.INCOME,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          { defaultMessage: '{lowerValue} to {upperValue}' },
          {
            lowerValue: formatCurrency(lowerValue),
            upperValue: formatCurrency(upperValue),
          }
        ),
      getValueText: (value: number) => formatCurrency(value),
      max: 650000,
      min: 0,
      minDistance: 25000,
      stepSize: 25000,
    },
    placeDataKey: EnumPlaceDataKey.MEDIAN_ANNUAL_INCOME,
  },
  [EnumMatchQuestionKey.INCOME_DEFICIT]: {
    key: EnumMatchQuestionKey.INCOME_DEFICIT,
    controlConfig: {
      ...belowAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Low income deficit',
      }),
    },
    placeDataKey: EnumPlaceDataKey.INCOME_DEFICIT_PER_CAPITA,
  },
  [EnumMatchQuestionKey.INCOME_INEQUALITY]: {
    key: EnumMatchQuestionKey.INCOME_INEQUALITY,
    controlConfig: {
      ...belowAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Low income inequality',
      }),
    },
    placeDataKey: EnumPlaceDataKey.GINI_INDEX,
  },
  [EnumMatchQuestionKey.NEW_RESIDENTS]: {
    key: EnumMatchQuestionKey.NEW_RESIDENTS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Many new residents',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_MOVED_FROM_OUT_OF_COUNTY,
  },
  [EnumMatchQuestionKey.ONE_BEDROOM_RENT]: {
    key: EnumMatchQuestionKey.ONE_BEDROOM_RENT,
    controlConfig: rentRangeControlConfig,
    placeDataKey: EnumPlaceDataKey.MEDIAN_GROSS_RENT_ONE_BEDROOM,
  },
  [EnumMatchQuestionKey.PARENTS_WITH_INFANTS]: {
    key: EnumMatchQuestionKey.PARENTS_WITH_INFANTS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Many parents with infants',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_WOMEN_WITH_BIRTH_IN_PAST_YEAR,
  },
  [EnumMatchQuestionKey.POPULATION]: {
    key: EnumMatchQuestionKey.POPULATION,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          { defaultMessage: '{lowerValue} to {upperValue} people' },
          {
            lowerValue: formatLargeInteger(lowerValue),
            upperValue: formatLargeInteger(upperValue),
          }
        ),
      getValueText: (value: number) => formatInteger(value),
      max: 8500000,
      min: 0,
      minDistance: 5000,
      stepSize: 5000,
    },
    placeDataKey: EnumPlaceDataKey.POPULATION,
  },
  [EnumMatchQuestionKey.POVERTY]: {
    key: EnumMatchQuestionKey.POVERTY,
    controlConfig: {
      ...belowAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Low poverty',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_IMPOVERISHED,
  },
  [EnumMatchQuestionKey.PUBLIC_TRANSIT_COMMUTE]: {
    key: EnumMatchQuestionKey.PUBLIC_TRANSIT_COMMUTE,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Public transit',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_PUBLIC_TRANSIT_TO_WORK,
  },
  [EnumMatchQuestionKey.REMOTE_WORKERS]: {
    key: EnumMatchQuestionKey.REMOTE_WORKERS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Many remote workers',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_WORK_FROM_HOME,
  },
  [EnumMatchQuestionKey.RENT]: {
    key: EnumMatchQuestionKey.RENT,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.BRANCH,
      defaultQuestionKey: EnumMatchQuestionKey.ONE_BEDROOM_RENT,
      labelText: intl.formatMessage({ defaultMessage: 'Bedrooms' }),
      options: [
        {
          labelText: getBedroomsCountText(0),
          questionKey: EnumMatchQuestionKey.STUDIO_RENT,
        },
        {
          labelText: getBedroomsCountText(1),
          questionKey: EnumMatchQuestionKey.ONE_BEDROOM_RENT,
        },
        {
          labelText: getBedroomsCountText(2),
          questionKey: EnumMatchQuestionKey.TWO_BEDROOM_RENT,
        },
        {
          labelText: getBedroomsCountText(3),
          questionKey: EnumMatchQuestionKey.THREE_BEDROOM_RENT,
        },
      ],
    },
  },
  [EnumMatchQuestionKey.STUDIO_RENT]: {
    key: EnumMatchQuestionKey.STUDIO_RENT,
    controlConfig: rentRangeControlConfig,
    placeDataKey: EnumPlaceDataKey.MEDIAN_GROSS_RENT_STUDIO,
  },
  [EnumMatchQuestionKey.THREE_BEDROOM_RENT]: {
    key: EnumMatchQuestionKey.THREE_BEDROOM_RENT,
    controlConfig: rentRangeControlConfig,
    placeDataKey: EnumPlaceDataKey.MEDIAN_GROSS_RENT_THREE_BEDROOM,
  },
  [EnumMatchQuestionKey.TWO_BEDROOM_RENT]: {
    key: EnumMatchQuestionKey.TWO_BEDROOM_RENT,
    controlConfig: rentRangeControlConfig,
    placeDataKey: EnumPlaceDataKey.MEDIAN_GROSS_RENT_TWO_BEDROOM,
  },
  [EnumMatchQuestionKey.VETERANS]: {
    key: EnumMatchQuestionKey.VETERANS,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Many veterans',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_VETERAN,
  },
  [EnumMatchQuestionKey.WALKING_COMMUTE]: {
    key: EnumMatchQuestionKey.WALKING_COMMUTE,
    controlConfig: {
      ...aboveAverageBoundControlConfig,
      labelText: intl.formatMessage({
        defaultMessage: 'Walking',
      }),
    },
    placeDataKey: EnumPlaceDataKey.PERCENT_WALK_TO_WORK,
  },
  [EnumMatchQuestionKey.WEEKLY_HOURS_WORKED]: {
    key: EnumMatchQuestionKey.WEEKLY_HOURS_WORKED,
    controlConfig: {
      controlType: EnumMatchQuestionControlType.RANGE,
      getRangeText: (lowerValue: number, upperValue: number) =>
        intl.formatMessage(
          {
            defaultMessage: '{lowerValue} to {upperValue} hours per week',
          },
          { lowerValue, upperValue }
        ),
      getValueText: (value: number) => value.toString(),
      max: 70,
      min: 0,
      minDistance: 5,
      stepSize: 5,
    },
    placeDataKey: EnumPlaceDataKey.MEAN_WEEKLY_HOURS_WORKED,
  },
};
