import { ReactNode } from 'react';

export enum EnumPageContainerMaxSize {
  SMALL = 'SMALL',
  NARROW = 'NARROW',
  DEFAULT = 'DEFAULT',
  LARGE = 'LARGE',
  FULL = 'FULL',
}

export enum EnumPageSidebarRank {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export type TypePageContainerAs = 'div' | 'footer' | 'header' | 'main';

export type TypePageSectionOptions = {
  element: ReactNode;
  hidden?: boolean;
  key: string;
};
