import { TypePhoto } from 'util/models/common/types';

export enum EnumPlaceDataKey {
  ANNUAL_INCOME_PER_CAPITA = 'annualIncomePerCapita',
  GINI_INDEX = 'giniIndex',
  INCOME_DEFICIT_PER_CAPITA = 'incomeDeficitPerCapita',
  MEAN_WEEKLY_HOURS_WORKED = 'meanWeeklyHoursWorked',
  MEDIAN_AGE = 'medianAge',
  MEDIAN_ANNUAL_EARNINGS = 'medianAnnualEarnings',
  MEDIAN_ANNUAL_INCOME = 'medianAnnualIncome',
  MEDIAN_GROSS_RENT_FOUR_BEDROOM = 'medianGrossRentFourBedroom',
  MEDIAN_GROSS_RENT_ONE_BEDROOM = 'medianGrossRentOneBedroom',
  MEDIAN_GROSS_RENT_STUDIO = 'medianGrossRentStudio',
  MEDIAN_GROSS_RENT_THREE_BEDROOM = 'medianGrossRentThreeBedroom',
  MEDIAN_GROSS_RENT_TWO_BEDROOM = 'medianGrossRentTwoBedroom',
  MEDIAN_HOUSING_VALUE = 'medianHousingValue',
  MEDIAN_HOUSING_YEAR_BUILT = 'medianHousingYearBuilt',
  MEDIAN_OWNER_OCCUPIED_HOUSING_YEAR_BUILT = 'medianOwnerOccupiedHousingYearBuilt', // TODO
  MEDIAN_RENTER_OCCUPIED_HOUSING_YEAR_BUILT = 'medianRenterOccupiedHousingYearBuilt', // TODO
  MODE_UPPER_BOUND_COMMUTE_MINUTES = 'modeUpperBoundCommuteMinutes', // TODO
  MODE_UPPER_BOUND_HOUSEHOLD_INCOME = 'modeUpperBoundHouseholdIncome',
  MODE_UPPER_BOUND_NON_FAMILY_HOUSEHOLD_INCOME = 'modeUpperBoundNonFamilyHouseholdIncome',
  PERCENT_ASSOCIATES_DEGREE = 'percentAssociatesDegree', // TODO
  PERCENT_BABY_BOOMER = 'percentBabyBoomer', // TODO
  PERCENT_BACHELORS_DEGREE = 'percentBachelorsDegree', // TODO
  PERCENT_BACHELORS_DEGREE_ARTS_AND_HUMANITIES = 'percentBachelorsDegreeArtsAndHumanities',
  PERCENT_BACHELORS_DEGREE_BUSINESS = 'percentBachelorsDegreeBusiness',
  PERCENT_BACHELORS_DEGREE_EDUCATION = 'percentBachelorsDegreeEducation',
  PERCENT_BACHELORS_DEGREE_SCIENCE_AND_ENGINEERING = 'percentBachelorsDegreeScienceAndEngineering',
  PERCENT_BIKE_TO_WORK = 'percentBikeToWork',
  PERCENT_DOCTORATE_DEGREE = 'percentDoctorateDegree', // TODO
  PERCENT_DRIVE_TO_WORK = 'percentDriveToWork',
  PERCENT_FAMILY_HOUSEHOLDS = 'percentFamilyHouseholds', // TODO
  PERCENT_FEMALE = 'percentFemale', // TODO
  PERCENT_FEMALE_BABY_BOOMER = 'percentFemaleBabyBoomer', // TODO
  PERCENT_FEMALE_DIVORCED = 'percentFemaleDivorced', // TODO
  PERCENT_FEMALE_GEN_ALPHA = 'percentFemaleGenAlpha', // TODO
  PERCENT_FEMALE_GEN_X = 'percentFemaleGenX', // TODO
  PERCENT_FEMALE_GEN_Y = 'percentFemaleGenY', // TODO
  PERCENT_FEMALE_GEN_Z = 'percentFemaleGenZ', // TODO
  PERCENT_FEMALE_NEVER_MARRIED = 'percentFemaleNeverMarried', // TODO
  PERCENT_FEMALE_NOW_MARRIED = 'percentFemaleNowMarried', // TODO
  PERCENT_FEMALE_SENIOR = 'percentFemaleSenior', // TODO
  PERCENT_FEMALE_SILENT_GENERATION = 'percentFemaleSilentGeneration', // TODO
  PERCENT_FOREIGN_BORN_AFGHANISTAN = 'percentForeignBornAfghanistan', // TODO
  PERCENT_FOREIGN_BORN_ALBANIA = 'percentForeignBornAlbania', // TODO
  PERCENT_FOREIGN_BORN_ARGENTINA = 'percentForeignBornArgentina', // TODO
  PERCENT_FOREIGN_BORN_ARMENIA = 'percentForeignBornArmenia', // TODO
  PERCENT_FOREIGN_BORN_AUSTRALIA = 'percentForeignBornAustralia', // TODO
  PERCENT_FOREIGN_BORN_AUSTRIA = 'percentForeignBornAustria', // TODO
  PERCENT_FOREIGN_BORN_BAHAMAS = 'percentForeignBornBahamas', // TODO
  PERCENT_FOREIGN_BORN_BANGLADESH = 'percentForeignBornBangladesh', // TODO
  PERCENT_FOREIGN_BORN_BARBADOS = 'percentForeignBornBarbados', // TODO
  PERCENT_FOREIGN_BORN_BELARUS = 'percentForeignBornBelarus', // TODO
  PERCENT_FOREIGN_BORN_BELGIUM = 'percentForeignBornBelgium', // TODO
  PERCENT_FOREIGN_BORN_BELIZE = 'percentForeignBornBelize', // TODO
  PERCENT_FOREIGN_BORN_BOLIVIA = 'percentForeignBornBolivia', // TODO
  PERCENT_FOREIGN_BORN_BOSNIA_AND_HERZEGOVINA = 'percentForeignBornBosniaAndHerzegovina', // TODO
  PERCENT_FOREIGN_BORN_BRAZIL = 'percentForeignBornBrazil',
  PERCENT_FOREIGN_BORN_BULGARIA = 'percentForeignBornBulgaria',
  PERCENT_FOREIGN_BORN_BURMA = 'percentForeignBornBurma',
  PERCENT_FOREIGN_BORN_CABO_VERDE = 'percentForeignBornCaboVerde',
  PERCENT_FOREIGN_BORN_CAMBODIA = 'percentForeignBornCambodia',
  PERCENT_FOREIGN_BORN_CAMEROON = 'percentForeignBornCameroon',
  PERCENT_FOREIGN_BORN_CANADA = 'percentForeignBornCanada',
  PERCENT_FOREIGN_BORN_CHILE = 'percentForeignBornChile',
  PERCENT_FOREIGN_BORN_CHINA = 'percentForeignBornChina',
  PERCENT_FOREIGN_BORN_COLOMBIA = 'percentForeignBornColombia',
  PERCENT_FOREIGN_BORN_CONGO = 'percentForeignBornCongo',
  PERCENT_FOREIGN_BORN_COSTA_RICA = 'percentForeignBornCostaRica',
  PERCENT_FOREIGN_BORN_CROATIA = 'percentForeignBornCroatia',
  PERCENT_FOREIGN_BORN_CUBA = 'percentForeignBornCuba',
  PERCENT_FOREIGN_BORN_CZECH_REPUBLIC_OR_SLOVAKIA = 'percentForeignBornCzechRepublicOrSlovakia',
  PERCENT_FOREIGN_BORN_DEMOCRATIC_REPUBLIC_OF_CONGO_ZAIRE = 'percentForeignBornDemocraticRepublicOfCongoZaire',
  PERCENT_FOREIGN_BORN_DENMARK = 'percentForeignBornDenmark',
  PERCENT_FOREIGN_BORN_DOMINICA = 'percentForeignBornDominica',
  PERCENT_FOREIGN_BORN_DOMINICAN_REPUBLIC = 'percentForeignBornDominicanRepublic',
  PERCENT_FOREIGN_BORN_ECUADOR = 'percentForeignBornEcuador',
  PERCENT_FOREIGN_BORN_EGYPT = 'percentForeignBornEgypt',
  PERCENT_FOREIGN_BORN_EL_SALVADOR = 'percentForeignBornElSalvador',
  PERCENT_FOREIGN_BORN_ENGLAND = 'percentForeignBornEngland',
  PERCENT_FOREIGN_BORN_ERITREA = 'percentForeignBornEritrea',
  PERCENT_FOREIGN_BORN_ETHIOPIA = 'percentForeignBornEthiopia',
  PERCENT_FOREIGN_BORN_FIJI = 'percentForeignBornFiji',
  PERCENT_FOREIGN_BORN_FRANCE = 'percentForeignBornFrance',
  PERCENT_FOREIGN_BORN_GERMANY = 'percentForeignBornGermany',
  PERCENT_FOREIGN_BORN_GHANA = 'percentForeignBornGhana',
  PERCENT_FOREIGN_BORN_GREECE = 'percentForeignBornGreece',
  PERCENT_FOREIGN_BORN_GRENADA = 'percentForeignBornGrenada',
  PERCENT_FOREIGN_BORN_GUATEMALA = 'percentForeignBornGuatemala',
  PERCENT_FOREIGN_BORN_GUYANA = 'percentForeignBornGuyana',
  PERCENT_FOREIGN_BORN_HAITI = 'percentForeignBornHaiti',
  PERCENT_FOREIGN_BORN_HONDURAS = 'percentForeignBornHonduras',
  PERCENT_FOREIGN_BORN_HONG_KONG = 'percentForeignBornHongKong',
  PERCENT_FOREIGN_BORN_HUNGARY = 'percentForeignBornHungary',
  PERCENT_FOREIGN_BORN_INDIA = 'percentForeignBornIndia',
  PERCENT_FOREIGN_BORN_INDONESIA = 'percentForeignBornIndonesia',
  PERCENT_FOREIGN_BORN_IRAN = 'percentForeignBornIran',
  PERCENT_FOREIGN_BORN_IRAQ = 'percentForeignBornIraq',
  PERCENT_FOREIGN_BORN_IRELAND = 'percentForeignBornIreland',
  PERCENT_FOREIGN_BORN_ISRAEL = 'percentForeignBornIsrael',
  PERCENT_FOREIGN_BORN_ITALY = 'percentForeignBornItaly',
  PERCENT_FOREIGN_BORN_JAMAICA = 'percentForeignBornJamaica',
  PERCENT_FOREIGN_BORN_JAPAN = 'percentForeignBornJapan',
  PERCENT_FOREIGN_BORN_JORDAN = 'percentForeignBornJordan',
  PERCENT_FOREIGN_BORN_KAZAKHSTAN = 'percentForeignBornKazakhstan',
  PERCENT_FOREIGN_BORN_KENYA = 'percentForeignBornKenya',
  PERCENT_FOREIGN_BORN_KOREA = 'percentForeignBornKorea',
  PERCENT_FOREIGN_BORN_KUWAIT = 'percentForeignBornKuwait',
  PERCENT_FOREIGN_BORN_LAOS = 'percentForeignBornLaos',
  PERCENT_FOREIGN_BORN_LATVIA = 'percentForeignBornLatvia',
  PERCENT_FOREIGN_BORN_LEBANON = 'percentForeignBornLebanon',
  PERCENT_FOREIGN_BORN_LIBERIA = 'percentForeignBornLiberia',
  PERCENT_FOREIGN_BORN_LITHUANIA = 'percentForeignBornLithuania',
  PERCENT_FOREIGN_BORN_MALAYSIA = 'percentForeignBornMalaysia',
  PERCENT_FOREIGN_BORN_MEXICO = 'percentForeignBornMexico',
  PERCENT_FOREIGN_BORN_MICRONESIA = 'percentForeignBornMicronesia',
  PERCENT_FOREIGN_BORN_MOLDOVA = 'percentForeignBornMoldova',
  PERCENT_FOREIGN_BORN_MOROCCO = 'percentForeignBornMorocco',
  PERCENT_FOREIGN_BORN_NEPAL = 'percentForeignBornNepal',
  PERCENT_FOREIGN_BORN_NETHERLANDS = 'percentForeignBornNetherlands',
  PERCENT_FOREIGN_BORN_NICARAGUA = 'percentForeignBornNicaragua',
  PERCENT_FOREIGN_BORN_NIGERIA = 'percentForeignBornNigeria',
  PERCENT_FOREIGN_BORN_NORTH_MACEDONIA = 'percentForeignBornNorthMacedonia',
  PERCENT_FOREIGN_BORN_NORWAY = 'percentForeignBornNorway',
  PERCENT_FOREIGN_BORN_PAKISTAN = 'percentForeignBornPakistan',
  PERCENT_FOREIGN_BORN_PANAMA = 'percentForeignBornPanama',
  PERCENT_FOREIGN_BORN_PERU = 'percentForeignBornPeru',
  PERCENT_FOREIGN_BORN_PHILIPPINES = 'percentForeignBornPhilippines',
  PERCENT_FOREIGN_BORN_POLAND = 'percentForeignBornPoland',
  PERCENT_FOREIGN_BORN_PORTUGAL = 'percentForeignBornPortugal',
  PERCENT_FOREIGN_BORN_ROMANIA = 'percentForeignBornRomania',
  PERCENT_FOREIGN_BORN_RUSSIA = 'percentForeignBornRussia',
  PERCENT_FOREIGN_BORN_SAUDI_ARABIA = 'percentForeignBornSaudiArabia',
  PERCENT_FOREIGN_BORN_SCOTLAND = 'percentForeignBornScotland',
  PERCENT_FOREIGN_BORN_SENEGAL = 'percentForeignBornSenegal',
  PERCENT_FOREIGN_BORN_SERBIA = 'percentForeignBornSerbia',
  PERCENT_FOREIGN_BORN_SIERRA_LEONE = 'percentForeignBornSierraLeone',
  PERCENT_FOREIGN_BORN_SINGAPORE = 'percentForeignBornSingapore',
  PERCENT_FOREIGN_BORN_SOMALIA = 'percentForeignBornSomalia',
  PERCENT_FOREIGN_BORN_SOUTH_AFRICA = 'percentForeignBornSouthAfrica',
  PERCENT_FOREIGN_BORN_SPAIN = 'percentForeignBornSpain',
  PERCENT_FOREIGN_BORN_SRI_LANKA = 'percentForeignBornSriLanka',
  PERCENT_FOREIGN_BORN_ST_VINCENT_AND_THE_GRENADINES = 'percentForeignBornStVincentAndTheGrenadines',
  PERCENT_FOREIGN_BORN_SUDAN = 'percentForeignBornSudan',
  PERCENT_FOREIGN_BORN_SWEDEN = 'percentForeignBornSweden',
  PERCENT_FOREIGN_BORN_SWITZERLAND = 'percentForeignBornSwitzerland',
  PERCENT_FOREIGN_BORN_SYRIA = 'percentForeignBornSyria',
  PERCENT_FOREIGN_BORN_TAIWAN = 'percentForeignBornTaiwan',
  PERCENT_FOREIGN_BORN_THAILAND = 'percentForeignBornThailand',
  PERCENT_FOREIGN_BORN_TRINIDAD_AND_TOBAGO = 'percentForeignBornTrinidadAndTobago',
  PERCENT_FOREIGN_BORN_TURKEY = 'percentForeignBornTurkey',
  PERCENT_FOREIGN_BORN_UGANDA = 'percentForeignBornUganda',
  PERCENT_FOREIGN_BORN_UKRAINE = 'percentForeignBornUkraine',
  PERCENT_FOREIGN_BORN_URUGUAY = 'percentForeignBornUruguay',
  PERCENT_FOREIGN_BORN_UZBEKISTAN = 'percentForeignBornUzbekistan',
  PERCENT_FOREIGN_BORN_VENEZUELA = 'percentForeignBornVenezuela',
  PERCENT_FOREIGN_BORN_VIETNAM = 'percentForeignBornVietnam',
  PERCENT_FOREIGN_BORN_YEMEN = 'percentForeignBornYemen',
  PERCENT_FOREIGN_BORN_ZIMBABWE = 'percentForeignBornZimbabwe',
  PERCENT_GEN_ALPHA = 'percentGenAlpha', // TODO
  PERCENT_GEN_X = 'percentGenX', // TODO
  PERCENT_GEN_Y = 'percentGenY', // TODO
  PERCENT_GEN_Z = 'percentGenZ', // TODO
  PERCENT_GRADUATE_COLLEGE_STUDENTS = 'percentGraduateCollegeStudents', // TODO
  PERCENT_HIGH_SCHOOL_DIPLOMA = 'percentHighSchoolDiploma', // TODO
  PERCENT_IMPOVERISHED = 'percentImpoverished',
  PERCENT_INCOME_INTEREST_DIVIDENDS_AND_RENTAL_INCOME = 'percentIncomeInterestDividendsAndRentalIncome', // TODO
  PERCENT_INCOME_SELF_EMPLOYMENT = 'percentIncomeSelfEmployment', // TODO
  PERCENT_INCOME_WAGES = 'percentIncomeWages', // TODO
  PERCENT_INTEREST_DIVIDENDS_AND_RENTAL_INCOME = 'percentInterestDividendsAndRentalIncome', // TODO
  PERCENT_MALE = 'percentMale', // TODO
  PERCENT_MALE_BABY_BOOMER = 'percentMaleBabyBoomer', // TODO
  PERCENT_MALE_DIVORCED = 'percentMaleDivorced', // TODO
  PERCENT_MALE_GEN_ALPHA = 'percentMaleGenAlpha', // TODO
  PERCENT_MALE_GEN_X = 'percentMaleGenX', // TODO
  PERCENT_MALE_GEN_Y = 'percentMaleGenY', // TODO
  PERCENT_MALE_GEN_Z = 'percentMaleGenZ', // TODO
  PERCENT_MALE_NEVER_MARRIED = 'percentMaleNeverMarried', // TODO
  PERCENT_MALE_NOW_MARRIED = 'percentMaleNowMarried', // TODO
  PERCENT_MALE_SENIOR = 'percentMaleSenior', // TODO
  PERCENT_MALE_SILENT_GENERATION = 'percentMaleSilentGeneration', // TODO
  PERCENT_MASTERS_DEGREE = 'percentMastersDegree', // TODO
  PERCENT_MEDIAN_GROSS_RENT_OF_HOUSEHOLD_INCOME = 'percentMedianGrossRentOfHouseholdIncome', // TODO
  PERCENT_MOVED_FROM_OUT_OF_COUNTY = 'percentMovedFromOutOfCounty',
  PERCENT_NEW_HOUSING = 'percentNewHousing', // TODO
  PERCENT_NEW_OWNER_OCCUPIED_HOUSING = 'percentNewOwnerOccupiedHousing', // TODO
  PERCENT_NEW_RENTER_OCCUPIED_HOUSING = 'percentNewRenterOccupiedHousing', // TODO
  PERCENT_OLD_HOUSING = 'percentOldHousing', // TODO
  PERCENT_OLD_OWNER_OCCUPIED_HOUSING = 'percentOldOwnerOccupiedHousing', // TODO
  PERCENT_OLD_RENTER_OCCUPIED_HOUSING = 'percentOldRenterOccupiedHousing', // TODO
  PERCENT_OWNER_OCCUPIED_HOUSING = 'percentOwnerOccupiedHousing', // TODO
  PERCENT_PROFESSIONAL_DEGREE = 'percentProfessionalDegree', // TODO
  PERCENT_PUBLIC_TRANSIT_TO_WORK = 'percentPublicTransitToWork',
  PERCENT_SELF_EMPLOYED_WITH_INCOME = 'percentSelfEmployedWithIncome', // TODO
  PERCENT_SENIOR = 'percentSenior', // TODO
  PERCENT_SILENT_GENERATION = 'percentSilentGeneration', // TODO
  PERCENT_SOME_COLLEGE = 'percentSomeCollege', // TODO
  PERCENT_UNDERGRADUATE_COLLEGE_STUDENTS = 'percentUndergraduateCollegeStudents', // TODO
  PERCENT_VACANT_HOUSING_FOR_RENT = 'percentVacantHousingForRent', // TODO
  PERCENT_VACANT_HOUSING_FOR_SALE = 'percentVacantHousingForSale', // TODO
  PERCENT_VACANT_HOUSING_RESIDENCE_ELSEWHERE = 'percentVacantHousingResidenceElsewhere',
  PERCENT_VETERAN = 'percentVeteran',
  PERCENT_WALK_TO_WORK = 'percentWalkToWork',
  PERCENT_WOMEN_WITH_BIRTH_IN_PAST_YEAR = 'percentWomenWithBirthInPastYear',
  PERCENT_WORK_FROM_HOME = 'percentWorkFromHome',
  POPULATION = 'population',
}

export enum EnumPlaceType {
  ADMIN_AREA_1 = 'adminArea1',
  LOCALITY = 'locality',
}

export type TypeMatchedPlace = {
  matchPercent?: number;
  place: TypePlace;
};

export type TypePlace = {
  id: string;
  placeId: string;
  description?: string | null;
  external: TypePlaceExternalIdsByKey;
  fullName: string;
  geometry: TypePlaceGeometry;
  longName: string;
  name: string;
  parent: TypeParentPlaceIdsByKey;
  photos: TypePhoto[];
  placeType: EnumPlaceType;
  shortName: string;
  slug: string;
  data: TypePlaceData;
  _geo: TypePlaceGeo;
};

type TypePlaceExternalIdsByKey = {
  fipsCode?: string;
  googlePlaceId?: string;
};

type TypePlaceGeometry = {
  bbox: number[];
};

type TypeParentPlaceIdsByKey = {
  continentPlaceIds: string[];
  countryPlaceIds?: string[];
  adminArea1PlaceIds?: string[];
  localityPlaceIds?: string[];
};

type TypePlaceGeo = {
  lat: number;
  lng: number;
};

export type TypePlaceData = { [key in EnumPlaceDataKey]?: TypePlaceDataValue };
export type TypePlaceDataValue = number | null | undefined;
