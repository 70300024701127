import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { EnumMatchCategoryKey } from 'util/match/types/categoryTypes';
import {
  EnumSidebarFormat,
  EnumSizeKey,
  SIDEBAR_CONTENT_EDGE_SPACE,
} from 'util/layout/constants';
import { getCategory } from 'util/match/helpers/questionHelpers';
import { orderedMatchCategoryKeys } from 'util/match/constants/categoryConstants';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useIntl } from 'react-intl';
import MatchCategoryQuestionCountChip from './MatchCategoryQuestionCountChip';
import Spinner from 'components/ui/core/feedback/Spinner';

export type TypeMatchPagePrimarySidebarProps = {
  activeCategoryKey?: EnumMatchCategoryKey;
  format: EnumSidebarFormat;
  loading?: boolean;
  onClickCategory: (categoryKey: EnumMatchCategoryKey) => void;
  onClose: () => void;
  onSave: () => void;
};

export default function MatchPagePrimarySidebar(
  props: TypeMatchPagePrimarySidebarProps
) {
  const {
    activeCategoryKey,
    format,
    loading = false,
    onClickCategory,
    onClose,
    onSave,
  } = props;
  const { formatMessage } = useIntl();

  return (
    <>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        m={SIDEBAR_CONTENT_EDGE_SPACE}
        spacing={1}
      >
        {format === EnumSidebarFormat.MODAL && (
          <Button onClick={onClose} size='small'>
            {formatMessage({ defaultMessage: 'Cancel' })}
          </Button>
        )}
        <Typography component='div' variant='h6'>
          {formatMessage({ defaultMessage: 'Your preferences' })}
        </Typography>
        {loading ? (
          <Spinner size={EnumSizeKey.SM} />
        ) : (
          <Button onClick={onSave} size='small' variant='contained'>
            {formatMessage({ defaultMessage: 'Save' })}
          </Button>
        )}
      </Stack>
      <List>
        {orderedMatchCategoryKeys.map((categoryKey) => {
          const category = getCategory(categoryKey);
          if (!category) {
            return null;
          }

          return (
            <ListItem key={categoryKey} disablePadding>
              <ListItemButton
                onClick={() => onClickCategory(categoryKey)}
                selected={categoryKey === activeCategoryKey}
              >
                <ListItemAvatar>
                  <Avatar>{category.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={category.name} />
                <MatchCategoryQuestionCountChip categoryKey={categoryKey} />
                <RiArrowRightSLine />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
