import {
  Slider,
  SliderProps,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';

export type TypeRangeInputProps = StackProps & {
  getRangeText: (lowerValue: number, upperValue: number) => string;
  getValueText: (value: number) => string;
  inputProps: Partial<SliderProps> & {
    onChange: (value: number[]) => void;
    value: number[];
  };
  max?: number;
  min?: number;
  minDistance?: number;
  stepSize?: number;
};

export default function RangeInput(props: TypeRangeInputProps) {
  const {
    getRangeText,
    getValueText,
    inputProps,
    max = 100,
    min = 0,
    minDistance = 10,
    stepSize = null,
    ...stackProps
  } = props;
  const { onChange, value, ...restInputProps } = inputProps;

  const handleChange = (
    _event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], max - minDistance);
        onChange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], min + minDistance);
        onChange([clamped - minDistance, clamped]);
      }
    } else {
      onChange(newValue as number[]);
    }
  };

  return (
    <Stack spacing={1} {...stackProps}>
      <Slider
        disableSwap
        max={max}
        min={min}
        onChange={handleChange}
        step={stepSize}
        value={value}
        valueLabelDisplay='auto'
        valueLabelFormat={getValueText}
        {...restInputProps}
      />
      <Typography textAlign='center'>
        {getRangeText(value[0], value[1])}
      </Typography>
    </Stack>
  );
}
