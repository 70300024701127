import { Button, ButtonProps, CircularProgress } from '@mui/material';

export type TypeLoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

export default function LoadingButton(props: TypeLoadingButtonProps) {
  const { children, disabled, loading = false, ...rest } = props;

  return (
    <Button
      {...rest}
      disabled={disabled || loading}
      endIcon={
        loading ? <CircularProgress color='inherit' size={15} /> : undefined
      }
    >
      {children}
    </Button>
  );
}
