import { Dialog, DialogProps, Slide } from '@mui/material';
import { forwardRef, ReactElement, Ref } from 'react';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction='up' ref={ref} {...props} />;
  }
);

export type TypeLayerProps = DialogProps;

export default function Layer(props: TypeLayerProps) {
  return <Dialog {...props} TransitionComponent={Transition} fullScreen />;
}
