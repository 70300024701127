import { CircularProgress, CircularProgressProps } from '@mui/material';
import { EnumSizeKey } from 'util/layout/constants';

export type TypeSpinnerProps = Omit<CircularProgressProps, 'size'> & {
  size?: EnumSizeKey;
};

export default function Spinner(props: TypeSpinnerProps) {
  const { size, ...rest } = props;
  return <CircularProgress {...rest} size={getSpinnerSize(size)} />;
}

const getSpinnerSize = (sizeKey?: EnumSizeKey): number | undefined => {
  switch (sizeKey) {
    case EnumSizeKey.XS:
      return 10;
    case EnumSizeKey.SM:
      return 20;
    case EnumSizeKey.MD:
      return 30;
    case EnumSizeKey.LG:
      return 40;
    case EnumSizeKey.XL:
      return 50;
    default:
      return undefined;
  }
};
