import { Alert, Stack, TextField } from '@mui/material';
import { isEmailAddressValid, isFormSubmittable } from 'util/form/helpers';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import LoadingButton from 'components/ui/core/button/LoadingButton';
import useAuth from 'util/auth/hooks/useAuth';

type TypeResetPasswordFormValue = {
  email: string;
};

export default function ResetPasswordForm() {
  const { resetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { formState, handleSubmit, register } =
    useForm<TypeResetPasswordFormValue>({
      mode: 'onChange',
      reValidateMode: 'onChange',
    });
  const { formatMessage } = useIntl();

  const { errors, isDirty, isSubmitting, isValid } = formState;

  const submit = async (formValue: TypeResetPasswordFormValue) => {
    if (
      !isFormSubmittable({
        dirty: isDirty,
        submitting: isSubmitting,
        valid: isValid,
        loading,
      })
    ) {
      return;
    }

    setLoading(true);

    try {
      await resetPassword(formValue.email);
    } catch (error: any) {}

    setSubmitted(true);
    setLoading(false);
  };

  if (submitted) {
    return (
      <Alert severity='success' variant='outlined'>
        {formatMessage({
          defaultMessage: 'Please check your inbox for the next step.',
        })}
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing={3}>
        <TextField
          error={!!errors.email}
          helperText={
            !!errors.email
              ? formatMessage({
                  defaultMessage: 'A valid email address is required',
                })
              : undefined
          }
          label={formatMessage({ defaultMessage: 'Email address' })}
          type='email'
          required
          {...register('email', { validate: isEmailAddressValid })}
        />
        <LoadingButton
          disabled={
            !isFormSubmittable({
              dirty: isDirty,
              submitting: isSubmitting,
              valid: isValid,
              loading,
            })
          }
          loading={loading}
          size='large'
          type='submit'
          variant='contained'
        >
          {formatMessage({ defaultMessage: 'Send reset password email' })}
        </LoadingButton>
      </Stack>
    </form>
  );
}
