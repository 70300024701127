import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  EnumMatchQuestionKey,
  TypeMatchQuestionBranchControlConfig,
} from 'util/match/types/questionTypes';
import { matchQuestionsByKey } from 'util/match/constants/questionConstants';
import MatchQuestion from 'components/match/MatchQuestion';

export type TypeMatchQuestionBranchFormControlProps = {
  controlConfig: TypeMatchQuestionBranchControlConfig;
  questionKey: EnumMatchQuestionKey;
};

export default function MatchQuestionBranchFormControl(
  props: TypeMatchQuestionBranchFormControlProps
) {
  const { controlConfig, questionKey } = props;
  const { control, resetField, watch } = useFormContext();
  const watchedValue = watch(questionKey);

  const labelId = `${questionKey}-label`;

  const resetChildren = () => {
    for (const option of controlConfig.options) {
      resetField(option.questionKey);
    }
  };

  return (
    <>
      <Box mb={3}>
        <Controller
          control={control}
          name={questionKey}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <InputLabel id={labelId}>{controlConfig.labelText}</InputLabel>
                <Select
                  {...field}
                  label={controlConfig.labelText}
                  labelId={labelId}
                  onChange={(event) => {
                    resetChildren();
                    field.onChange(event);
                  }}
                  size='small'
                >
                  {controlConfig.options.map((option) => {
                    return (
                      <MenuItem
                        key={option.questionKey}
                        value={option.questionKey}
                      >
                        {option.labelText}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            );
          }}
          rules={{
            validate: (value) => value in matchQuestionsByKey,
          }}
        />
      </Box>
      <MatchQuestion questionKey={watchedValue} />
    </>
  );
}
