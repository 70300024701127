import { intl } from 'util/lang/helpers';
import { TypeLinkConfig } from 'util/common/types';

export const footerLinks: TypeLinkConfig[] = [
  {
    path: '/about',
    text: intl.formatMessage({ defaultMessage: 'About' }),
  },
  {
    path: '/contact',
    text: intl.formatMessage({ defaultMessage: 'Contact' }),
  },
  {
    external: true,
    path: 'https://maptitan-store.myteespring.co',
    text: intl.formatMessage({ defaultMessage: 'Shirts & Hoodies' }),
  },
  {
    path: '/terms',
    text: intl.formatMessage({ defaultMessage: 'Terms' }),
  },
  {
    path: '/privacy',
    text: intl.formatMessage({ defaultMessage: 'Privacy' }),
  },
];
