import { EnumContactSectionKey, TypeContactSection } from 'util/contact/types';
import { intl } from 'util/lang/helpers';

export const contactSectionsByKey: {
  [key in EnumContactSectionKey]: TypeContactSection;
} = {
  [EnumContactSectionKey.CONTACT]: {
    key: EnumContactSectionKey.CONTACT,
    email: 'contact@maptitan.com',
    titleText: intl.formatMessage({
      defaultMessage: 'For questions and comments',
    }),
  },
  [EnumContactSectionKey.PRESS]: {
    key: EnumContactSectionKey.PRESS,
    email: 'press@maptitan.com',
    titleText: intl.formatMessage({ defaultMessage: 'For press and media' }),
  },
};

export const orderedContactSectionKeys: EnumContactSectionKey[] = [
  EnumContactSectionKey.CONTACT,
  EnumContactSectionKey.PRESS,
];
