import { EnumRouteKey } from 'util/routing/types';
import { findRouteKey } from 'util/routing/helpers';
import { matchRoutes, useLocation } from 'react-router-dom';
import { routes } from 'util/routing/constants';
import { useMemo } from 'react';

export const useCurrentRouteKey = (): EnumRouteKey => {
  const location = useLocation();
  const matchedRoutes = useMemo(() => {
    return matchRoutes(routes, location) ?? [];
  }, [location]);

  return findRouteKey(matchedRoutes[0]?.route.path);
};
