import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Snackbar,
} from '@mui/material';
import {
  contactSectionsByKey,
  orderedContactSectionKeys,
} from 'util/contact/constants';
import { DEFAULT_TOAST_MS_TIMEOUT_SHORT } from 'util/layout/constants';
import { FiCopy, FiX } from 'react-icons/fi';
import { SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import IconButton from 'components/ui/core/button/IconButton';
import Footer from 'components/footer/Footer';
import Grid from '@mui/material/Unstable_Grid2';
import Page from 'components/ui/layout/page/Page';
import SimpleHeader from 'components/ui/layout/header/SimpleHeader';

export default function ContactPage() {
  const { formatMessage } = useIntl();
  const [copyToastIsVisible, setCopyToastIsVisible] = useState(false);

  const closeCopyToast = (_event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopyToastIsVisible(false);
  };

  const openCopyToast = (_text: string, successful: boolean) => {
    if (!successful) {
      return;
    }

    setCopyToastIsVisible(true);
  };

  return (
    <>
      <Page
        footer={<Footer />}
        header={
          <SimpleHeader
            titleText={formatMessage({ defaultMessage: 'Get in touch' })}
          />
        }
        htmlTitle={formatMessage({ defaultMessage: 'Contact' })}
      >
        <Grid mb={6} spacing={3} container>
          {orderedContactSectionKeys.map((key) => {
            const section = contactSectionsByKey[key];
            if (!section) {
              return null;
            }

            return (
              <Grid key={key} xs={12} md={6}>
                <Card variant='outlined'>
                  <CardHeader title={section.titleText} />
                  <CardContent>
                    <Button
                      component={Link}
                      href={`mailto:${section.email}`}
                      rel='noreferrer'
                      target='_blank'
                    >
                      {section.email}
                    </Button>
                    <CopyToClipboard
                      onCopy={openCopyToast}
                      text={section.email}
                    >
                      <IconButton
                        size='small'
                        tooltipText={formatMessage({
                          defaultMessage: 'Copy email address',
                        })}
                      >
                        <FiCopy />
                      </IconButton>
                    </CopyToClipboard>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Page>
      <Snackbar
        action={
          <IconButton
            color='inherit'
            onClick={closeCopyToast}
            size='small'
            tooltipText={formatMessage({
              defaultMessage: 'Close',
            })}
          >
            <FiX />
          </IconButton>
        }
        autoHideDuration={DEFAULT_TOAST_MS_TIMEOUT_SHORT}
        message={formatMessage({ defaultMessage: 'Copied the email address!' })}
        onClose={closeCopyToast}
        open={copyToastIsVisible}
      />
    </>
  );
}
