import { Box, Link, Stack, Typography } from '@mui/material';
import { footerLinks } from 'util/footer/constants';
import { hasPlacePageFeatureFlag } from 'util/user/featureFlags/helpers';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/brand/logo.svg';
import { ReactNode } from 'react';
import { useBreakpointValue, useSemanticBreakpoints } from 'util/theme/hooks';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import FooterLinksMenu from 'components/footer/FooterLinksMenu';

export type TypeFooterProps = {
  narrow?: boolean;
};

export default function Footer(props: TypeFooterProps) {
  const { narrow = false } = props;

  const madeInIsVisible = useBreakpointValue({
    xs: true,
    md: false,
    xl: !narrow,
  });
  const matchesCalculatedIsVisible = useBreakpointValue({
    xs: true,
    lg: false,
    xl: !narrow,
  });
  const { mobile, desktop } = useSemanticBreakpoints();
  const { formatMessage } = useIntl();

  return (
    <Stack
      alignItems='center'
      direction={mobile ? 'column-reverse' : 'row'}
      fontSize='sm'
      justifyContent='space-evenly'
      pt={2}
    >
      <Box alignItems='center' display='flex'>
        <Typography>&copy; {dayjs().format('YYYY')} MapTitan</Typography>
        {!desktop ? (
          <>
            <Bullet />
            <FooterLinksMenu />
          </>
        ) : (
          footerLinks.map((footerLink) => (
            <Stack key={footerLink.text} alignItems='center' direction='row'>
              <Bullet />
              {footerLink.external ? (
                <Link href={footerLink.path} rel='noreferrer' target='_blank'>
                  {footerLink.text}
                </Link>
              ) : (
                <Link component={RouterLink} to={footerLink.path}>
                  {footerLink.text}
                </Link>
              )}
            </Stack>
          ))
        )}
      </Box>
      {madeInIsVisible && (
        <Box display='flex'>
          <Typography mr={1}>
            {formatMessage(
              { defaultMessage: 'Made in <link>{placeName}</link>' },
              {
                link: (chunks: ReactNode) =>
                  hasPlacePageFeatureFlag() ? (
                    <Link component={RouterLink} to='/places/chicago-il-usa'>
                      {chunks}
                    </Link>
                  ) : (
                    <>{chunks}</>
                  ),
                placeName: 'Chicago, IL',
              }
            )}
          </Typography>
        </Box>
      )}
      {matchesCalculatedIsVisible && (
        <Box alignItems='center' display='flex'>
          <Link component={RouterLink} to='/match'>
            {formatMessage({
              defaultMessage: 'Millions of matches calculated',
            })}
          </Link>
          <Box ml={1}>
            <Logo height={12} />
          </Box>
        </Box>
      )}
    </Stack>
  );
}

const Bullet = () => (
  <Box aria-hidden='true' mx={1}>
    ·
  </Box>
);
