import { clamp, formatInteger } from 'util/common/helpers/numberHelpers';
import { Stack, TextField, Typography } from '@mui/material';
import { TypeRangeInputProps } from 'components/ui/core/form/RangeInput';
import { useIntl } from 'react-intl';

export type TypeLargeRangeInputProps = Omit<
  TypeRangeInputProps,
  'getValueText' | 'stepSize'
>;

export default function LargeRangeInput(props: TypeLargeRangeInputProps) {
  const {
    getRangeText,
    inputProps,
    max = 100,
    min = 0,
    minDistance = 100,
    ...stackProps
  } = props;
  const { onChange, value } = inputProps;

  const { formatMessage } = useIntl();

  const handleBlur = (
    newValue: (number | string)[],
    activeInputIndex: number
  ) => {
    const clampedValue0 = clamp(
      +formatInteger(newValue[0], { separated: false }),
      min,
      max
    );
    const clampedValue1 = clamp(
      +formatInteger(newValue[1], { separated: false }),
      min,
      max
    );

    if (clampedValue1 - clampedValue0 < minDistance) {
      if (activeInputIndex === 0) {
        const clamped = Math.min(clampedValue0, max - minDistance);
        onChange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(clampedValue1, min + minDistance);
        onChange([clamped - minDistance, clamped]);
      }
    } else {
      onChange([clampedValue0, clampedValue1]);
    }
  };

  const handleChange = (newValue: (number | string)[]) => {
    onChange([
      +formatInteger(newValue[0], { separated: false }),
      +formatInteger(newValue[1], { separated: false }),
    ]);
  };

  return (
    <Stack spacing={1} {...stackProps}>
      <Stack direction='row' spacing={1} {...stackProps}>
        <TextField
          label={formatMessage({ defaultMessage: 'Min' })}
          onBlur={(event) => handleBlur([event.target.value, value[1]], 0)}
          onChange={(event) => handleChange([event.target.value, value[1]])}
          size='small'
          value={value[0]}
        />
        <TextField
          label={formatMessage({ defaultMessage: 'Max' })}
          onBlur={(event) => handleBlur([value[0], event.target.value], 1)}
          onChange={(event) => handleChange([value[0], event.target.value])}
          size='small'
          value={value[1]}
        />
      </Stack>
      <Typography textAlign='center'>
        {getRangeText(value[0], value[1])}
      </Typography>
    </Stack>
  );
}
