import { FiEye, FiEyeOff } from 'react-icons/fi';
import { forwardRef, MouseEvent, Ref } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useIntl } from 'react-intl';
import { useToggle } from 'util/common/hooks';
import IconButton from 'components/ui/core/button/IconButton';

export type TypePasswordInputProps = TextFieldProps;

const PasswordInput = forwardRef(function PasswordInput(
  props: TypePasswordInputProps,
  ref: Ref<HTMLInputElement>
) {
  const { InputProps = {}, ...rest } = props;

  const { formatMessage } = useIntl();
  const { active: visible, toggle } = useToggle();

  const handleVisibilityButtonMouseDown = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      label={formatMessage({ defaultMessage: 'Password' })}
      ref={ref}
      type={visible ? 'text' : 'password'}
      variant='outlined'
      {...rest}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              onClick={toggle}
              onMouseDown={handleVisibilityButtonMouseDown}
              tooltipText={formatMessage({
                defaultMessage: 'Toggle password visibility',
              })}
            >
              {visible ? <FiEyeOff /> : <FiEye />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
});

export default PasswordInput;
