import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/brand/logo.svg';

export default function BrandButtonLink() {
  return (
    <Link component={RouterLink} to='/' underline='none'>
      <Stack alignItems='center' direction='row' spacing={2}>
        <Logo height={25} />
        <Typography color='text.primary' component='span' variant='h6'>
          MapTitan
        </Typography>
      </Stack>
    </Link>
  );
}
