import { Alert, AlertTitle, Button, Stack, TextField } from '@mui/material';
import { hasLoginFeatureFlag } from 'util/user/featureFlags/helpers';
import {
  isEmailAddressValid,
  isFormSubmittable,
  isPasswordValid,
} from 'util/form/helpers';
import { PASSWORD_MIN_LENGTH } from 'util/form/constants';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { useToggle } from 'util/common/hooks';
import ForgotPasswordModal from 'components/auth/ForgotPasswordModal';
import LoadingButton from 'components/ui/core/button/LoadingButton';
import PasswordInput from 'components/ui/core/form/PasswordInput';
import useAuth from 'util/auth/hooks/useAuth';

type TypeLoginFormValue = {
  email: string;
  inviteCode: string;
  password: string;
};

export default function LoginForm() {
  const { logIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState<string>();
  const {
    active: forgotPasswordModalIsOpen,
    toggle: toggleForgotPasswordModal,
  } = useToggle();
  const { formState, handleSubmit, register } = useForm<TypeLoginFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { formatMessage } = useIntl();

  const { errors, isDirty, isSubmitting, isValid } = formState;

  const submit = async (formValue: TypeLoginFormValue) => {
    if (
      !isFormSubmittable({
        dirty: isDirty,
        submitting: isSubmitting,
        valid: isValid,
        loading,
      })
    ) {
      return;
    }

    if (
      !hasLoginFeatureFlag() &&
      (!formValue.inviteCode.length ||
        formValue.inviteCode.trim() !== '' ||
        formValue.inviteCode.length != 8)
    ) {
      setErrorText(
        formatMessage({
          defaultMessage: 'Logging in is not available at this time.',
        })
      );
      return;
    }

    setLoading(true);
    setErrorText(undefined);

    try {
      await logIn(formValue.email, formValue.password);
    } catch (error: any) {
      setErrorText(
        formatMessage({
          defaultMessage:
            'An error occurred. Are your email and password correct? If so, please try logging in again soon.',
        })
      );
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <Stack mb={6} spacing={3}>
          <TextField
            error={!!errors.email}
            helperText={
              !!errors.email
                ? formatMessage({
                    defaultMessage: 'A valid email address is required',
                  })
                : undefined
            }
            label={formatMessage({ defaultMessage: 'Email address' })}
            type='email'
            required
            {...register('email', { validate: isEmailAddressValid })}
          />
          <PasswordInput
            error={!!errors.password}
            helperText={formatMessage(
              {
                defaultMessage: 'At least {minLength} characters',
              },
              { minLength: PASSWORD_MIN_LENGTH }
            )}
            label={formatMessage({ defaultMessage: 'Password' })}
            required
            {...register('password', { validate: isPasswordValid })}
          />
          {!hasLoginFeatureFlag() && (
            <TextField
              error={!!errors.inviteCode}
              helperText={
                !!errors.inviteCode
                  ? formatMessage({
                      defaultMessage:
                        'A valid invite code is required. Note: Not everyone has received one yet',
                    })
                  : undefined
              }
              label={formatMessage({ defaultMessage: 'Invite code' })}
              required
              {...register('inviteCode', { required: true })}
            />
          )}
          {hasLoginFeatureFlag() && (
            <Button
              onClick={toggleForgotPasswordModal}
              size='small'
              sx={{ alignSelf: 'flex-end' }}
            >
              {formatMessage({ defaultMessage: 'Forgot password?' })}
            </Button>
          )}
          <LoadingButton
            disabled={
              !isFormSubmittable({
                dirty: isDirty,
                submitting: isSubmitting,
                valid: isValid,
                loading,
              })
            }
            loading={loading}
            size='large'
            type='submit'
            variant='contained'
          >
            {formatMessage({ defaultMessage: 'Log in' })}
          </LoadingButton>
          {errorText && (
            <Alert severity='error' variant='outlined'>
              <AlertTitle>
                {formatMessage({ defaultMessage: 'Oops!' })}
              </AlertTitle>
              {errorText}
            </Alert>
          )}
        </Stack>
      </form>
      <ForgotPasswordModal
        onCancel={toggleForgotPasswordModal}
        open={forgotPasswordModalIsOpen}
      />
    </>
  );
}
