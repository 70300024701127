import { Box, BoxProps, Card, CardMedia } from '@mui/material';
import { ReactNode } from 'react';
import CloudsImage from 'assets/img/clouds.jpg';

const DEFAULT_MIN_HEIGHT = 300;

export type TypeCoverPhotoCardProps = BoxProps & {
  accessory?: ReactNode;
  children: ReactNode;
  fallbackImageSource?: string;
  imageSource?: string;
  minHeight?: number;
};

export default function CoverPhotoCard(props: TypeCoverPhotoCardProps) {
  const {
    accessory,
    children,
    fallbackImageSource = CloudsImage,
    imageSource,
    minHeight = DEFAULT_MIN_HEIGHT,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Card
        sx={{
          position: 'relative',
          minHeight,
        }}
      >
        <CardMedia
          image={imageSource ?? fallbackImageSource}
          sx={{
            '&:after': {
              background: 'linear-gradient(to top, black, rgba(0,0,0,0))',
              bottom: 0,
              content: '""',
              height: '64%',
              position: 'absolute',
              width: '100%',
            },
            minHeight,
          }}
        />
        <Box bottom={0} p={3} position='absolute' top={0} width='100%'>
          <Box
            display='flex'
            flexDirection='column'
            height='100%'
            justifyContent={accessory ? 'space-between' : 'flex-end'}
          >
            {accessory && <Box alignSelf='flex-end'>{accessory}</Box>}
            <Box>{children}</Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
