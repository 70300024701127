import { Alert, AlertTitle } from '@mui/material';
import { useIntl } from 'react-intl';

export default function ComingSoonSignupBanner() {
  const { formatMessage } = useIntl();

  return (
    <Alert severity='info' variant='outlined'>
      <AlertTitle>{formatMessage({ defaultMessage: 'Note' })}</AlertTitle>
      {formatMessage({
        defaultMessage:
          'Registration is currently invite-only. Please check back later this month for public access.',
      })}
    </Alert>
  );
}
