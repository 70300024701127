import {
  EnumMatchQuestionControlType,
  EnumMatchQuestionKey,
} from 'util/match/types/questionTypes';
import { getQuestion } from 'util/match/helpers/questionHelpers';
import MatchQuestionBoundFormControl from 'components/match/form/MatchQuestionBoundFormControl';
import MatchQuestionBranchFormControl from 'components/match/form/MatchQuestionBranchFormControl';
import MatchQuestionRangeFormControl from 'components/match/form/MatchQuestionRangeFormControl';

export type TypeMatchQuestionProps = {
  questionKey: EnumMatchQuestionKey;
};

export default function MatchQuestion(props: TypeMatchQuestionProps) {
  const { questionKey } = props;
  const question = getQuestion(questionKey);

  if (!question) {
    return null;
  }

  const { controlConfig } = question;

  switch (controlConfig.controlType) {
    case EnumMatchQuestionControlType.BOUND:
      return (
        <MatchQuestionBoundFormControl
          controlConfig={controlConfig}
          questionKey={question.key}
        />
      );
    case EnumMatchQuestionControlType.BRANCH:
      return (
        <MatchQuestionBranchFormControl
          controlConfig={controlConfig}
          questionKey={question.key}
        />
      );
    case EnumMatchQuestionControlType.RANGE:
      return (
        <MatchQuestionRangeFormControl
          controlConfig={controlConfig}
          questionKey={question.key}
        />
      );
    default:
      return null;
  }
}
