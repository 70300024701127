import {
  primarySidebarColumnWidthsByBreakpointKey,
  secondarySidebarColumnWidthsByBreakpointKey,
} from 'util/layout/constants';
import { useBreakpointValue } from 'util/theme/hooks';

export type TypeUseSidebarColumnWidthReturnValue = {
  primarySidebarColumnWidth: number;
  secondarySidebarColumnWidth: number;
};

export default function useSidebarColumnWidth(): TypeUseSidebarColumnWidthReturnValue {
  const primarySidebarColumnWidth =
    useBreakpointValue(primarySidebarColumnWidthsByBreakpointKey) ?? 0;
  const secondarySidebarColumnWidth =
    useBreakpointValue(secondarySidebarColumnWidthsByBreakpointKey) ?? 0;

  return { primarySidebarColumnWidth, secondarySidebarColumnWidth };
}
