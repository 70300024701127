import { EnumAboutSectionKey, TypeAboutSection } from 'util/about/types';
import { intl } from 'util/lang/helpers';
import { ReactComponent as HappyDarkGraphic } from 'assets/graphics/happy-dark.svg';
import { ReactComponent as HappyLightGraphic } from 'assets/graphics/happy-light.svg';
import { ReactComponent as NeutralDarkGraphic } from 'assets/graphics/neutral-dark.svg';
import { ReactComponent as NeutralLightGraphic } from 'assets/graphics/neutral-light.svg';
import { ReactComponent as SadDarkGraphic } from 'assets/graphics/sad-dark.svg';
import { ReactComponent as SadLightGraphic } from 'assets/graphics/sad-light.svg';
import { ReactComponent as SmileDarkGraphic } from 'assets/graphics/smile-dark.svg';
import { ReactComponent as SmileLightGraphic } from 'assets/graphics/smile-light.svg';

export const aboutSectionsByKey: {
  [key in EnumAboutSectionKey]: TypeAboutSection;
} = {
  [EnumAboutSectionKey.FAILED_SOLUTIONS]: {
    key: EnumAboutSectionKey.FAILED_SOLUTIONS,
    darkImage: <NeutralDarkGraphic />,
    description: intl.formatMessage({
      defaultMessage:
        "You can turn to the existing tools and information to find answers but you're left with confusion and more questions. But it's not your fault. Of the little information available, most of it is written in someone else's perspective. It doesn't work because they don't know you. And the other resources require you to spend hours that you don't have doing difficult research.",
    }),
    lightImage: <NeutralLightGraphic />,
    titleText: intl.formatMessage({
      defaultMessage: "Existing tools don't work",
    }),
  },
  [EnumAboutSectionKey.PROBLEM]: {
    key: EnumAboutSectionKey.PROBLEM,
    darkImage: <SadDarkGraphic />,
    description: intl.formatMessage({
      defaultMessage:
        "Whether you're moving within the same area, across the country, or from abroad, there are so many unknowns about the place you're headed. What is the community like? Will you enjoy living there? How does it compare with other places? These questions are overwhelming and answering them takes a lot of time and effort.",
    }),
    lightImage: <SadLightGraphic />,
    titleText: intl.formatMessage({
      defaultMessage: 'Moving is full of unknowns',
    }),
  },
  [EnumAboutSectionKey.RESULT]: {
    key: EnumAboutSectionKey.RESULT,
    darkImage: <HappyDarkGraphic />,
    description: intl.formatMessage({
      defaultMessage:
        "So when it's time to move, you'll have absolute confidence in your decision. There's so much possibility when you love where you live. In work and in life, you'll be set-up to succeed and the community will inspire your growth. And be sure to congratulate yourself. You took the steps needed to ace this move.",
    }),
    lightImage: <HappyLightGraphic />,
    titleText: intl.formatMessage({
      defaultMessage: "The best move you'll make",
    }),
  },
  [EnumAboutSectionKey.SOLUTION]: {
    key: EnumAboutSectionKey.SOLUTION,
    darkImage: <SmileDarkGraphic />,
    description: intl.formatMessage({
      defaultMessage:
        'MapTitan takes this weight off your mind. Unlike other tools, MapTitan does the research for you... fast. And it really is "for you". The personalized results and information are customized to fit your situation and interests. Using MapTitan is like having a super power.',
    }),
    lightImage: <SmileLightGraphic />,
    titleText: intl.formatMessage({
      defaultMessage: 'MapTitan gives you guidance',
    }),
  },
};

export const orderedAboutSectionKeys: EnumAboutSectionKey[] = [
  EnumAboutSectionKey.PROBLEM,
  EnumAboutSectionKey.FAILED_SOLUTIONS,
  EnumAboutSectionKey.SOLUTION,
  EnumAboutSectionKey.RESULT,
];
