import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from 'util/common/constants/firebaseConstants';
import {
  TypeNewPreference,
  TypePartialPreference,
  TypePreference,
} from 'util/models/preference/types';

const preferencesCollection = collection(db, 'preferences');

export const createPreference = async (
  preference: TypeNewPreference
): Promise<TypePreference> => {
  const documentReference = await addDoc(preferencesCollection, preference);
  return {
    id: documentReference.id,
    ...preference,
  };
};

export const fetchPreference = async (
  preferenceId: string
): Promise<TypePreference> => {
  const preferenceReference = doc(db, 'preferences', preferenceId);
  const preferenceSnapshot = await getDoc(preferenceReference);
  if (preferenceSnapshot.exists()) {
    return {
      ...preferenceSnapshot.data(),
      id: preferenceSnapshot.id,
    } as TypePreference;
  } else {
    throw new Error();
  }
};

export const updatePreference = (
  preferenceId: string,
  partialPreference: TypePartialPreference
): Promise<void> => {
  const preferenceReference = doc(db, 'preferences', preferenceId);
  return updateDoc(preferenceReference, partialPreference);
};
