import { EnumPlaceType } from 'util/models/place/types';
import { rootQueryKeysByKey } from 'util/common/constants/queryConstants';
import {
  TypeBasePreference,
  TypeNewPreference,
} from 'util/models/preference/types';
import { USA_PLACE_ID } from 'util/place/constants/commonConstants';

export const generateNewPreference = (
  basePreference: TypeBasePreference
): TypeNewPreference => {
  return {
    ...basePreference,
    filtersByKey: {},
    location: {
      placeIds: [USA_PLACE_ID],
    },
    placeType: EnumPlaceType.LOCALITY,
  };
};

export const getPreferenceQueryKey = (preferenceId: string): string[] => {
  return [...rootQueryKeysByKey.PREFERENCES, ':preferenceId', preferenceId];
};
