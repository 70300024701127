import { Button, Link, Menu, MenuItem, MenuList } from '@mui/material';
import { footerLinks } from 'util/footer/constants';
import { Link as RouterLink } from 'react-router-dom';
import { MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';

export default function FooterLinksMenu() {
  const { formatMessage } = useIntl();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = !!anchorElement;

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  return (
    <>
      <Button
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={openMenu}
      >
        {formatMessage({ defaultMessage: 'More...' })}
      </Button>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        open={open}
        onClose={closeMenu}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <MenuList>
          {footerLinks.map((footerLink) => {
            if (footerLink.external) {
              return (
                <MenuItem
                  key={footerLink.text}
                  component={Link}
                  href={footerLink.path}
                  rel='noreferrer'
                  target='_blank'
                >
                  {footerLink.text}
                </MenuItem>
              );
            }

            return (
              <MenuItem
                key={footerLink.text}
                component={RouterLink}
                to={footerLink.path}
              >
                {footerLink.text}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}
