import { EnumPlaceDataKey } from 'util/models/place/types';
import { EnumPreferenceFilterVariableKey } from 'util/models/preference/types';

export enum EnumBoundKey {
  MAX = 'max',
  MIN = 'min',
}

export enum EnumMatchQuestionKey {
  AGE = 'age',
  BACHELORS_DEGREES_ARTS = 'bachelorsDegreesArts',
  BACHELORS_DEGREES_BUSINESS = 'bachelorsDegreesBusiness',
  BACHELORS_DEGREES_EDUCATION = 'bachelorsDegreesEducation',
  BACHELORS_DEGREES_SCIENCE = 'bachelorsDegreesScience',
  BIKING_COMMUTE = 'bikingCommute',
  DRIVING_COMMUTE = 'drivingCommute',
  HOUSE_PRICE = 'housePrice',
  HOUSING_YEAR_BUILT = 'housingYearBuilt',
  INCOME = 'income',
  INCOME_DEFICIT = 'incomeDeficit',
  INCOME_INEQUALITY = 'incomeInequality',
  NEW_RESIDENTS = 'newResidents',
  ONE_BEDROOM_RENT = 'oneBedroomRent',
  PARENTS_WITH_INFANTS = 'parentsWithInfants',
  POPULATION = 'population',
  POVERTY = 'poverty',
  PUBLIC_TRANSIT_COMMUTE = 'publicTransitCommute',
  REMOTE_WORKERS = 'remoteWorkers',
  RENT = 'rent',
  STUDIO_RENT = 'studioRent',
  THREE_BEDROOM_RENT = 'threeBedroomRent',
  TWO_BEDROOM_RENT = 'twoBedroomRent',
  VETERANS = 'veterans',
  WALKING_COMMUTE = 'walkingCommute',
  WEEKLY_HOURS_WORKED = 'weeklyHoursWorked',
}

export type TypeMatchQuestion = {
  key: EnumMatchQuestionKey;
  controlConfig: TypeMatchQuestionControlConfig;
  placeDataKey?: EnumPlaceDataKey; // Used to determine if the question maps to a place data key or if it is a meta question
};

export enum EnumMatchQuestionControlType {
  BOUND = 'bound',
  BOUND_RANGE = 'boundRange',
  BRANCH = 'branch',
  RANGE = 'range',
  RATING = 'rating',
  SELECT = 'select',
}

export type TypeMatchQuestionControlConfig =
  | TypeMatchQuestionBoundControlConfig
  | TypeMatchQuestionBoundRangeControlConfig
  | TypeMatchQuestionBranchControlConfig
  | TypeMatchQuestionRangeControlConfig
  | TypeMatchQuestionRatingControlConfig
  | TypeMatchQuestionSelectControlConfig;

export type TypeMatchQuestionBoundControlConfig = {
  controlType: EnumMatchQuestionControlType.BOUND;
  labelText: string;
  max: EnumPreferenceFilterVariableKey | null;
  min: EnumPreferenceFilterVariableKey | null;
};

export type TypeMatchQuestionBoundRangeControlConfig = Omit<
  TypeMatchQuestionRangeControlConfig,
  'getRangeText'
> & {
  controlType: EnumMatchQuestionControlType.BOUND_RANGE;
  fixedBound: EnumBoundKey;
};

// A meta question used to switch selection between several questions
export type TypeMatchQuestionBranchControlConfig = {
  controlType: EnumMatchQuestionControlType.BRANCH;
  defaultQuestionKey?: EnumMatchQuestionKey;
  labelText: string;
  multiple?: boolean;
  options: TypeBranchOption[];
  searchable?: boolean;
};

export type TypeMatchQuestionRangeControlConfig = {
  controlType: EnumMatchQuestionControlType.RANGE;
  getValueText: (value: number) => string;
  getRangeText: (lowerValue: number, upperValue: number) => string;
  max: number;
  min: number;
  minDistance: number;
  stepSize: number;
};

export type TypeMatchQuestionRatingControlConfig = {
  controlType: EnumMatchQuestionControlType.RATING;
  getValueText: (value: number) => string;
};

export type TypeMatchQuestionSelectControlConfig = {
  controlType: EnumMatchQuestionControlType.SELECT;
  options: TypeSelectOption[];
};

type TypeBranchOption = {
  labelText: string;
  questionKey: EnumMatchQuestionKey;
};

type TypeSelectOption = {
  labelText: string;
  value: number;
};
