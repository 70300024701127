import { ReactElement } from 'react';

export enum EnumMatchCategoryKey {
  AGE = 'AGE',
  DEMOGRAPHICS = 'DEMOGRAPHICS',
  ECONOMY = 'ECONOMY',
  HOUSING = 'HOUSING',
  JOBS = 'JOBS',
  POPULATION = 'POPULATION',
  RELATIONSHIPS = 'RELATIONSHIPS',
  TRANSPORTATION = 'TRANSPORTATION',
}

export type TypeMatchCategory = {
  key: EnumMatchCategoryKey;
  icon: ReactElement;
  name: string;
};
