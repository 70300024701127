import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { EnumRouteKey } from 'util/routing/types';
import { hasPlacePageFeatureFlag } from 'util/user/featureFlags/helpers';
import AboutPage from 'components/about/AboutPage';
import AuthenticatedRouteWrapper from 'components/routing/AuthenticatedRouteWrapper';
import ContactPage from 'components/contact/ContactPage';
import HomePage from 'components/home/HomePage';
import LoginPage from 'components/login/LoginPage';
import MatchPage from 'components/match/MatchPage';
import NavWrapper from 'components/nav/NavWrapper';
import PageSidebarProvider from 'components/ui/layout/page/PageSidebarProvider';
import PlacePage from 'components/place/PlacePage';
import PrivacyPage from 'components/legal/PrivacyPage';
import SignupPage from 'components/signup/SignupPage';
import TermsPage from 'components/legal/TermsPage';
import UnauthenticatedRouteWrapper from 'components/routing/UnauthenticatedRouteWrapper';

export const routePathsByRouteKey: { [key in EnumRouteKey]: string } = {
  [EnumRouteKey.ABOUT]: 'about',
  [EnumRouteKey.CONTACT]: 'contact',
  [EnumRouteKey.LOGIN]: 'login',
  [EnumRouteKey.MATCH]: 'match',
  [EnumRouteKey.PLACE]: 'places/:slug',
  [EnumRouteKey.PRIVACY]: 'privacy',
  [EnumRouteKey.SIGNUP]: 'signup',
  [EnumRouteKey.TERMS]: 'terms',
  [EnumRouteKey.HOME]: '*',
};

export const routes: RouteObject[] = [
  {
    path: routePathsByRouteKey[EnumRouteKey.ABOUT],
    element: <NavWrapper component={<AboutPage />} />,
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.CONTACT],
    element: <NavWrapper component={<ContactPage />} />,
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.LOGIN],
    element: (
      <UnauthenticatedRouteWrapper>
        <LoginPage />
      </UnauthenticatedRouteWrapper>
    ),
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.MATCH],
    element: (
      <AuthenticatedRouteWrapper>
        <PageSidebarProvider>
          <NavWrapper component={<MatchPage />} />
        </PageSidebarProvider>
      </AuthenticatedRouteWrapper>
    ),
  },
  ...(hasPlacePageFeatureFlag()
    ? [
        {
          path: routePathsByRouteKey[EnumRouteKey.PLACE],
          element: <NavWrapper component={<PlacePage />} />,
        },
      ]
    : []),
  {
    path: routePathsByRouteKey[EnumRouteKey.PRIVACY],
    element: <NavWrapper component={<PrivacyPage />} />,
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.SIGNUP],
    element: (
      <UnauthenticatedRouteWrapper>
        <SignupPage />
      </UnauthenticatedRouteWrapper>
    ),
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.TERMS],
    element: <NavWrapper component={<TermsPage />} />,
  },
  {
    path: routePathsByRouteKey[EnumRouteKey.HOME],
    element: (
      <UnauthenticatedRouteWrapper>
        <NavWrapper component={<HomePage />} />
      </UnauthenticatedRouteWrapper>
    ),
  },
];

export const router = createBrowserRouter(routes);
