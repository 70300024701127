import {
  EnumPlaceDataKey,
  TypeMatchedPlace,
  TypePlace,
  TypePlaceData,
} from 'util/models/place/types';
import {
  EnumPreferenceFilterVariableKey,
  TypePreferenceFiltersByKey,
} from 'util/models/preference/types';
import {
  getAverageValueByPlaceDataKey,
  getStatsByPlaceDataKey,
} from 'util/place/helpers/commonHelpers';
import { isNil } from 'util/common/helpers/objectHelpers';

export const calculateMatchedPlaces = (
  places: TypePlace[],
  preferenceFiltersByKey?: TypePreferenceFiltersByKey
): TypeMatchedPlace[] => {
  return (places ?? [])
    .map((place) => {
      return {
        matchPercent: calculateMatchPercent(place, preferenceFiltersByKey),
        place,
      };
    })
    .sort(
      (matchedPlaceA, matchedPlaceB) =>
        (matchedPlaceB.matchPercent ?? 0) - (matchedPlaceA.matchPercent ?? 0)
    );
};

export const calculateMatchPercent = (
  place: TypePlace,
  preferenceFiltersByKey?: TypePreferenceFiltersByKey
): number | undefined => {
  if (!preferenceFiltersByKey || !Object.keys(preferenceFiltersByKey).length) {
    return undefined;
  }

  const distance = calculateDistanceToPreference(
    preferenceFiltersByKey,
    place.data
  );

  return 1 - Math.min(1, distance);
};

export const calculateDistanceToPreference = (
  preferenceFiltersByKey: TypePreferenceFiltersByKey,
  placeData: TypePlaceData
) => {
  const squaredDistance = (
    Object.keys(preferenceFiltersByKey) as EnumPlaceDataKey[]
  ).reduce((distance, placeDataKey) => {
    const filter = preferenceFiltersByKey[placeDataKey];
    const dataValue = placeData[placeDataKey];

    if (dataValue == null) {
      return distance;
    }

    const { average, min, max } = getStatsByPlaceDataKey(placeDataKey);
    const maxDistance = max - min;

    let rangeMax = max;
    let rangeMin = min;
    if (!isNil(filter?.max)) {
      if (filter?.max === EnumPreferenceFilterVariableKey.AVERAGE) {
        rangeMax = average;
      } else {
        rangeMax = filter?.max as number;
      }
    }

    if (!isNil(filter?.min)) {
      if (filter?.min === EnumPreferenceFilterVariableKey.AVERAGE) {
        rangeMin = getAverageValueByPlaceDataKey(placeDataKey);
      } else {
        rangeMin = filter?.min as number;
      }
    }

    let linearDistance = 0;
    if (dataValue < rangeMin || dataValue > rangeMax) {
      linearDistance = Math.min(
        Math.abs(rangeMax - dataValue),
        Math.abs(rangeMin - dataValue)
      );
    }

    return distance + Math.pow(linearDistance / maxDistance, 2);
  }, 0);

  return Math.sqrt(squaredDistance);
};
