import { EnumMatchCategoryKey } from 'util/match/types/categoryTypes';
import { EnumMatchTopicKey, TypeMatchTopic } from 'util/match/types/topicTypes';
import { intl } from 'util/lang/helpers';

export const orderedMatchTopicKeysByCategoryKey: {
  [key in EnumMatchCategoryKey]: EnumMatchTopicKey[];
} = {
  [EnumMatchCategoryKey.AGE]: [
    EnumMatchTopicKey.AGE,
    EnumMatchTopicKey.GENERATION,
    EnumMatchTopicKey.AGE_GROUPS,
  ],
  [EnumMatchCategoryKey.DEMOGRAPHICS]: [
    EnumMatchTopicKey.GENDER,
    EnumMatchTopicKey.STUDENTS,
    EnumMatchTopicKey.EDUCATIONAL_ATTAINMENT,
    EnumMatchTopicKey.UNDERGRADUATE_FOCUS,
    EnumMatchTopicKey.NATIONALITY,
    EnumMatchTopicKey.MILITARY,
  ],
  [EnumMatchCategoryKey.ECONOMY]: [
    EnumMatchTopicKey.INCOME,
    EnumMatchTopicKey.RENT_TO_INCOME,
    EnumMatchTopicKey.INCOME_EQUALITY,
    EnumMatchTopicKey.POVERTY,
    EnumMatchTopicKey.PROSPERITY,
    EnumMatchTopicKey.INCOME_SOURCE,
    EnumMatchTopicKey.ENTREPRENEURSHIP,
  ],
  [EnumMatchCategoryKey.HOUSING]: [
    EnumMatchTopicKey.RENT,
    EnumMatchTopicKey.HOUSE_PRICE,
    EnumMatchTopicKey.HOUSING_MARKET,
    EnumMatchTopicKey.TENANTS,
    EnumMatchTopicKey.HOUSING_AGE,
    EnumMatchTopicKey.HOUSING_YEAR_BUILT,
    EnumMatchTopicKey.HOUSING_VACANCY,
    EnumMatchTopicKey.VACATION_HOUSING,
  ],
  [EnumMatchCategoryKey.JOBS]: [
    EnumMatchTopicKey.REMOTE_WORK,
    EnumMatchTopicKey.WORK_CULTURE,
  ],
  [EnumMatchCategoryKey.POPULATION]: [
    EnumMatchTopicKey.POPULATION,
    EnumMatchTopicKey.POPULATION_CHANGE,
  ],
  [EnumMatchCategoryKey.RELATIONSHIPS]: [
    EnumMatchTopicKey.FAMILY,
    EnumMatchTopicKey.PARENTS,
    EnumMatchTopicKey.MARITAL_STATUS,
  ],
  [EnumMatchCategoryKey.TRANSPORTATION]: [
    EnumMatchTopicKey.COMMUTE_METHOD,
    EnumMatchTopicKey.COMMUTE_TIME,
  ],
};

export const matchTopicsByKey: { [key in EnumMatchTopicKey]: TypeMatchTopic } =
  {
    [EnumMatchTopicKey.AGE]: {
      key: EnumMatchTopicKey.AGE,
      name: intl.formatMessage({ defaultMessage: 'Typical Age' }),
    },
    [EnumMatchTopicKey.AGE_GROUPS]: {
      key: EnumMatchTopicKey.AGE_GROUPS,
      name: intl.formatMessage({ defaultMessage: 'Age Groups' }),
    },
    [EnumMatchTopicKey.COMMUTE_TIME]: {
      key: EnumMatchTopicKey.COMMUTE_TIME,
      name: intl.formatMessage({ defaultMessage: 'Commute Time' }),
    },
    [EnumMatchTopicKey.COMMUTE_METHOD]: {
      key: EnumMatchTopicKey.COMMUTE_METHOD,
      name: intl.formatMessage({ defaultMessage: 'Commute Method' }),
    },
    [EnumMatchTopicKey.EDUCATIONAL_ATTAINMENT]: {
      key: EnumMatchTopicKey.EDUCATIONAL_ATTAINMENT,
      name: intl.formatMessage({ defaultMessage: 'Educational Attainment' }),
    },
    [EnumMatchTopicKey.ENTREPRENEURSHIP]: {
      key: EnumMatchTopicKey.ENTREPRENEURSHIP,
      name: intl.formatMessage({ defaultMessage: 'Entrepreneurship' }),
    },
    [EnumMatchTopicKey.FAMILY]: {
      key: EnumMatchTopicKey.FAMILY,
      name: intl.formatMessage({ defaultMessage: 'Family' }),
    },
    [EnumMatchTopicKey.GENDER]: {
      key: EnumMatchTopicKey.GENDER,
      name: intl.formatMessage({ defaultMessage: 'Gender' }),
    },
    [EnumMatchTopicKey.GENERATION]: {
      key: EnumMatchTopicKey.GENERATION,
      name: intl.formatMessage({ defaultMessage: 'Generation' }),
    },
    [EnumMatchTopicKey.HOUSING_AGE]: {
      key: EnumMatchTopicKey.HOUSING_AGE,
      name: intl.formatMessage({ defaultMessage: 'Housing Age' }),
    },
    [EnumMatchTopicKey.HOUSING_MARKET]: {
      key: EnumMatchTopicKey.HOUSING_MARKET,
      name: intl.formatMessage({ defaultMessage: 'Housing Market' }),
    },
    [EnumMatchTopicKey.HOUSE_PRICE]: {
      key: EnumMatchTopicKey.HOUSE_PRICE,
      name: intl.formatMessage({ defaultMessage: 'House Price' }),
    },
    [EnumMatchTopicKey.HOUSING_VACANCY]: {
      key: EnumMatchTopicKey.HOUSING_VACANCY,
      name: intl.formatMessage({ defaultMessage: 'Vacancy' }),
    },
    [EnumMatchTopicKey.HOUSING_YEAR_BUILT]: {
      key: EnumMatchTopicKey.HOUSING_YEAR_BUILT,
      name: intl.formatMessage({ defaultMessage: 'Typical Year Built' }),
    },
    [EnumMatchTopicKey.INCOME]: {
      key: EnumMatchTopicKey.INCOME,
      name: intl.formatMessage({ defaultMessage: 'Average Income' }),
    },
    [EnumMatchTopicKey.INCOME_EQUALITY]: {
      key: EnumMatchTopicKey.INCOME_EQUALITY,
      name: intl.formatMessage({ defaultMessage: 'Income Inequality' }),
    },
    [EnumMatchTopicKey.INCOME_SOURCE]: {
      key: EnumMatchTopicKey.INCOME_SOURCE,
      name: intl.formatMessage({ defaultMessage: 'Income Source' }),
    },
    [EnumMatchTopicKey.MARITAL_STATUS]: {
      key: EnumMatchTopicKey.MARITAL_STATUS,
      name: intl.formatMessage({ defaultMessage: 'Marital Status' }),
    },
    [EnumMatchTopicKey.MILITARY]: {
      key: EnumMatchTopicKey.MILITARY,
      name: intl.formatMessage({ defaultMessage: 'Military' }),
    },
    [EnumMatchTopicKey.NATIONALITY]: {
      key: EnumMatchTopicKey.NATIONALITY,
      name: intl.formatMessage({ defaultMessage: 'Nationality' }),
    },
    [EnumMatchTopicKey.PARENTS]: {
      key: EnumMatchTopicKey.PARENTS,
      name: intl.formatMessage({ defaultMessage: 'Parents' }),
    },
    [EnumMatchTopicKey.POPULATION]: {
      key: EnumMatchTopicKey.POPULATION,
      name: intl.formatMessage({ defaultMessage: 'Population' }),
    },
    [EnumMatchTopicKey.POPULATION_CHANGE]: {
      key: EnumMatchTopicKey.POPULATION_CHANGE,
      name: intl.formatMessage({ defaultMessage: 'Population Change' }),
    },
    [EnumMatchTopicKey.POVERTY]: {
      key: EnumMatchTopicKey.POVERTY,
      name: intl.formatMessage({ defaultMessage: 'Poverty' }),
    },
    [EnumMatchTopicKey.PROSPERITY]: {
      key: EnumMatchTopicKey.PROSPERITY,
      name: intl.formatMessage({ defaultMessage: 'Prosperity' }),
    },
    [EnumMatchTopicKey.REMOTE_WORK]: {
      key: EnumMatchTopicKey.REMOTE_WORK,
      name: intl.formatMessage({ defaultMessage: 'Remote Work' }),
    },
    [EnumMatchTopicKey.RENT]: {
      key: EnumMatchTopicKey.RENT,
      name: intl.formatMessage({ defaultMessage: 'Rent' }),
    },
    [EnumMatchTopicKey.RENT_TO_INCOME]: {
      key: EnumMatchTopicKey.RENT_TO_INCOME,
      name: intl.formatMessage({ defaultMessage: 'Rent-to-Income' }),
    },
    [EnumMatchTopicKey.STUDENTS]: {
      key: EnumMatchTopicKey.STUDENTS,
      name: intl.formatMessage({ defaultMessage: 'Students' }),
    },
    [EnumMatchTopicKey.TENANTS]: {
      key: EnumMatchTopicKey.TENANTS,
      name: intl.formatMessage({ defaultMessage: 'Tenants' }),
    },
    [EnumMatchTopicKey.UNDERGRADUATE_FOCUS]: {
      key: EnumMatchTopicKey.UNDERGRADUATE_FOCUS,
      name: intl.formatMessage({ defaultMessage: 'Typical Undergrad Focus' }),
    },
    [EnumMatchTopicKey.VACATION_HOUSING]: {
      key: EnumMatchTopicKey.VACATION_HOUSING,
      name: intl.formatMessage({ defaultMessage: 'Vacation Housing' }),
    },
    [EnumMatchTopicKey.WORK_CULTURE]: {
      key: EnumMatchTopicKey.WORK_CULTURE,
      name: intl.formatMessage({ defaultMessage: 'Work Culture' }),
    },
  };
