import { EnumSizeKey, placeCardHeightsBySizeKey } from 'util/layout/constants';
import { TypeMatchedPlace } from 'util/models/place/types';
import { Typography } from '@mui/material';
import CoverPhotoCard from 'components/ui/core/card/CoverPhotoCard';
import MatchPercentChip from 'components/match/MatchPercentChip';

export type TypeMatchedPlaceCardProps = {
  matchedPlace: TypeMatchedPlace;
};

export default function MatchedPlaceCard(props: TypeMatchedPlaceCardProps) {
  const { matchedPlace } = props;
  const { matchPercent, place } = matchedPlace;

  // TODO - Account for Cloud Storage photos
  const imageSource = place.photos[0]?.url?.includes('://')
    ? place.photos[0].url
    : undefined;

  return (
    <CoverPhotoCard
      imageSource={imageSource}
      minHeight={placeCardHeightsBySizeKey.md}
    >
      <Typography color='white' component='div' variant='h5'>
        {place.longName}
      </Typography>
      <MatchPercentChip percent={matchPercent} size={EnumSizeKey.SM} />
    </CoverPhotoCard>
  );
}
