import { Chip } from '@mui/material';
import { EnumMatchCategoryKey } from 'util/match/types/categoryTypes';
import { getAnsweredQuestionCountForCategory } from 'util/match/helpers/formHelpers';
import { TypeMatchFormValue } from 'util/match/types/formTypes';
import { useFormContext } from 'react-hook-form';
import { useMemo } from 'react';

export type TypeMatchCategoryQuestionCountChip = {
  categoryKey: EnumMatchCategoryKey;
};

export default function MatchCategoryQuestionCountChip(
  props: TypeMatchCategoryQuestionCountChip
) {
  const { categoryKey } = props;

  const { watch } = useFormContext();
  const watchedFormValue = watch() as TypeMatchFormValue;

  const count = useMemo(() => {
    return getAnsweredQuestionCountForCategory(categoryKey, watchedFormValue);
  }, [categoryKey, watchedFormValue]);

  return count > 0 ? <Chip label={count} size='small' /> : null;
}
