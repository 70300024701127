import { hasMatchScopeFeatureFlag } from 'util/user/featureFlags/helpers';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import CustomHeader from 'components/ui/layout/header/CustomHeader';
import MatchScopeBreadcrumbs from 'components/match/scope/MatchScopeBreadcrumbs';

export default function MatchPageHeader() {
  const { formatMessage } = useIntl();

  return (
    <CustomHeader>
      {hasMatchScopeFeatureFlag() && <MatchScopeBreadcrumbs />}
      <Typography component='h1' variant='h6'>
        {formatMessage({
          defaultMessage: 'Your best cities in the United States',
        })}
      </Typography>
    </CustomHeader>
  );
}
