import {
  connectAuthEmulator,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';
import {
  connectFirestoreEmulator,
  doc,
  Firestore,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { isLocal, isProduction } from 'util/browser/helpers';
import localityPlaces from 'util/common/constants/localityPlaces.json';

const seed = async (db: Firestore) => {
  if (!isLocal()) {
    return;
  }

  for (const place of localityPlaces) {
    await setDoc(doc(db, 'places', place.placeId), place);
  }
};

const EMULATOR_FIREBASE_AUTH_DOMAIN = 'http://localhost:9099';
const EMULATOR_FIREBASE_FIRESTORE_HOST = 'localhost';
const EMULATOR_FIREBASE_FIRESTORE_PORT = 8080;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  ...(isProduction()
    ? { measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID }
    : {}),
};

export const app = initializeApp(firebaseConfig);
export const analytics = isProduction() ? getAnalytics(app) : undefined;
export const auth = getAuth(app);
export const db = getFirestore(app);

export const facebookAuthProvider = new FacebookAuthProvider();
export const googleAuthProvider = new GoogleAuthProvider();
export const twitterAuthProvider = new TwitterAuthProvider();

if (isLocal()) {
  connectAuthEmulator(auth, EMULATOR_FIREBASE_AUTH_DOMAIN);
  connectFirestoreEmulator(
    db,
    EMULATOR_FIREBASE_FIRESTORE_HOST,
    EMULATOR_FIREBASE_FIRESTORE_PORT
  );

  // seed(db).then(() => {
  //   console.log('Done');
  // });
}
