import { Button, Stack } from '@mui/material';
import { calculateMatchedPlaces } from 'util/match/helpers/matchHelpers';
import {
  convertMatchFormValueToPreferenceFiltersByKey,
  convertPreferenceFiltersByKeyToMatchFormValue,
  getDefaultMatchFormValue,
} from 'util/match/helpers/formHelpers';
import { EnumMatchCategoryKey } from 'util/match/types/categoryTypes';
import { EnumPageContainerMaxSize } from 'util/layout/types';
import { EnumSidebarFormat } from 'util/layout/constants';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { isDeepEqual } from 'util/common/helpers/objectHelpers';
import { RiEqualizerLine } from 'react-icons/ri';
import { TypeMatchFormValue } from 'util/match/types/formTypes';
import { TypeMatchedPlace } from 'util/models/place/types';
import { TypePreference } from 'util/models/preference/types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMatchPlacesQuery } from 'util/place/hooks';
import {
  usePrimaryPreferenceQuery,
  useUpdatePreferenceMutation,
} from 'util/models/preference/hooks';
import Footer from 'components/footer/Footer';
import MatchPageHeader from 'components/match/MatchPageHeader';
import MatchPagePrimarySidebar from 'components/match/MatchPagePrimarySidebar';
import MatchPageSecondarySidebar from 'components/match/MatchPageSecondarySidebar';
import MatchedPlacesList from 'components/match/place/MatchedPlacesList';
import Page from 'components/ui/layout/page/Page';
import usePageSidebars from 'util/layout/hooks/usePageSidebars';

export default function MatchPage() {
  const formProps = useForm<TypeMatchFormValue>({
    defaultValues: getDefaultMatchFormValue(),
  });
  const { handleSubmit, reset } = formProps;

  const { formatMessage } = useIntl();
  const {
    closePrimarySidebar,
    closeSecondarySidebar,
    openPrimarySidebar,
    openSecondarySidebar,
    primarySidebarIsOpen,
    secondarySidebarIsOpen,
    sidebarFormat,
  } = usePageSidebars();
  const [activeCategoryKey, setActiveCategoryKey] =
    useState<EnumMatchCategoryKey>();

  const fillMatchForm = (preference: TypePreference) => {
    const editedFormValue = convertPreferenceFiltersByKeyToMatchFormValue(
      preference.filtersByKey
    );

    reset(editedFormValue, { keepDefaultValues: true });
  };

  const { loadingPreference, preference, preferenceErrored } =
    usePrimaryPreferenceQuery();

  const { loadingPlaces, places = [], placesErrored } = useMatchPlacesQuery();

  const { mutateAsync: updatePreference } = useUpdatePreferenceMutation();

  const [matchedPlaces, setMatchedPlaces] = useState<TypeMatchedPlace[]>([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (
      loadingPreference ||
      preferenceErrored ||
      !preference ||
      !Object.keys(preference.filtersByKey).length
    ) {
      return;
    }

    fillMatchForm(preference);
  }, [loadingPreference, preference]);

  useEffect(() => {
    if (loadingPlaces || placesErrored) {
      return;
    }

    setMatchedPlaces(calculateMatchedPlaces(places, preference?.filtersByKey));
  }, [loadingPlaces, placesErrored, preference, places]);

  const openCategory = (categoryKey: EnumMatchCategoryKey) => {
    setActiveCategoryKey(categoryKey);
    openSecondarySidebar();
  };

  const closeCategory = () => {
    setActiveCategoryKey(undefined);
    closeSecondarySidebar();
  };

  const closeModalSidebars = () => {
    if (sidebarFormat === EnumSidebarFormat.MODAL) {
      closeSecondarySidebar();
      closePrimarySidebar();
    }
  };

  const submit: SubmitHandler<TypeMatchFormValue> = async (formValue) => {
    if (!preference) {
      return;
    }

    setSubmitting(true);
    closeModalSidebars();

    const preferenceFiltersByKey =
      convertMatchFormValueToPreferenceFiltersByKey(formValue);

    if (!isDeepEqual(preferenceFiltersByKey, preference.filtersByKey)) {
      await updatePreference({
        preferenceId: preference.id,
        partialPreference: {
          filtersByKey: preferenceFiltersByKey,
        },
      });
      setMatchedPlaces(calculateMatchedPlaces(places, preferenceFiltersByKey));
    }

    setSubmitting(false);
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(submit)}>
        <Page
          footer={<Footer narrow />}
          header={<MatchPageHeader />}
          htmlTitle={formatMessage({
            defaultMessage: 'Match - Your personalized best places to live',
          })}
          maxSize={EnumPageContainerMaxSize.NARROW}
          primarySidebar={
            <MatchPagePrimarySidebar
              activeCategoryKey={activeCategoryKey}
              format={sidebarFormat}
              loading={loadingPreference || submitting}
              onClickCategory={openCategory}
              onClose={closeModalSidebars}
              onSave={handleSubmit(submit)}
            />
          }
          primarySidebarProps={{ open: primarySidebarIsOpen }}
          secondarySidebar={
            <MatchPageSecondarySidebar
              activeCategoryKey={activeCategoryKey}
              format={sidebarFormat}
              onClose={closeCategory}
            />
          }
          secondarySidebarProps={{ open: secondarySidebarIsOpen }}
        >
          <Stack mb={3} spacing={3}>
            {sidebarFormat == EnumSidebarFormat.MODAL && (
              <Button
                onClick={openPrimarySidebar}
                size='large'
                startIcon={<RiEqualizerLine />}
                variant='contained'
                fullWidth
              >
                {formatMessage({
                  defaultMessage: 'Choose preferences',
                })}
              </Button>
            )}
            <MatchedPlacesList
              loading={loadingPlaces || submitting}
              matchedPlaces={matchedPlaces}
            />
          </Stack>
        </Page>
      </form>
    </FormProvider>
  );
}
