import {
  EnumPlaceDataKey,
  EnumPlaceType,
  TypePlace,
} from 'util/models/place/types';
import { rootQueryKeysByKey } from 'util/common/constants/queryConstants';
import { statsByPlaceDataKey } from 'util/place/constants/statsConstants';
import { TypePlaceDataStatsByKey } from 'util/place/types/commonTypes';

const getAdminArea1ParentPlaceIds = (
  place?: TypePlace
): string[] | undefined => {
  return getParentPlaceIds(place, EnumPlaceType.ADMIN_AREA_1);
};

export const getAverageValueByPlaceDataKey = (
  key: EnumPlaceDataKey
): number => {
  return getStatsByPlaceDataKey(key).average;
};

export const getStatsByPlaceDataKey = (
  key: EnumPlaceDataKey
): TypePlaceDataStatsByKey => {
  return statsByPlaceDataKey[key];
};

export const getMatchPlacesQueryKey = (): string[] => {
  return rootQueryKeysByKey.MATCH_PLACES;
};

const getParentPlaceIds = (
  place?: TypePlace,
  placeType: EnumPlaceType = EnumPlaceType.ADMIN_AREA_1
): string[] | undefined => {
  return place?.parent[`${placeType}PlaceIds`];
};

export const getPlacePagePath = (place: TypePlace) => {
  return `/places/${place.slug}`;
};

export const getPlaceQueryKey = (placeId: string): string[] => {
  return [...rootQueryKeysByKey.PLACES, ':placeId', placeId];
};

export const getPlaceBySlugQueryKey = (slug: string): string[] => {
  return [...rootQueryKeysByKey.PLACES, 'slug', slug];
};

export const getPrimaryAdminArea1ParentPlaceId = (
  place?: TypePlace
): string | undefined => {
  return getAdminArea1ParentPlaceIds(place)?.[0];
};
