import { PartialBy } from 'util/common/types/utilityTypes';

export enum EnumFriendStatusKey {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REQUESTED = 'requested',
}

export enum EnumGenderKey {
  FEMALE = 'female',
  MALE = 'male',
  NON_BINARY = 'nonBinary',
}

export enum EnumMovingReasonKey {
  BETTER_AREA = 'betterArea',
  BETTER_HOUSING = 'betterHousing', // Formerly 'betterHome'
  BETTER_SCHOOLS = 'betterSchools',
  BIGGER_HOUSING = 'biggerHousing', // Formerly 'biggerHome'
  CHEAPER_HOUSING = 'cheaperHousing', // Formerly 'cheaperHome'
  CLOSER_TO_FAMILY = 'closerToFamily', // Formerly 'closeToFamily'
  CLOSER_TO_WORK = 'closerToWork',
  COLLEGE = 'college',
  COMMUNITY = 'community',
  ESTABLISH_A_HOUSEHOLD = 'establishAHousehold',
  FAMILY = 'family',
  FINANCIAL = 'financial',
  FRESH_START = 'freshStart',
  HEALTH = 'health',
  HOUSING = 'housing',
  JOB_SEARCHING = 'jobSearching',
  JOB_TRANSFER = 'jobTransfer',
  LIFESTYLE = 'lifestyle',
  MAKE_MORE_MONEY = 'makeMoreMoney',
  NEW_EXPERIENCE = 'newExperience',
  NEW_JOB = 'newJob',
  OWN_A_HOME = 'ownAHome',
  PCS = 'pcs',
  RETIREMENT = 'retirement',
  SAVE_MONEY = 'saveMoney',
  SMALLER_HOUSING = 'smallerHome', // Formerly 'smallerHome'
  START_A_FAMILY = 'startAFamily',
  STARTUP = 'startup',
}

export enum EnumMovingTimeframeKey {
  MORE_THAN_TWO_YEARS = 'moreThanTwoYears',
  ONE_YEAR = 'oneYear',
  SIX_MONTHS = 'sixMonths',
  THREE_MONTHS = 'threeMonths',
  TWO_YEARS = 'twoYears',
  UNSURE = 'unsure',
}

// TODO
export enum EnumOccupationKey {}

export enum EnumEmailSubscriptionKey {
  SPOTLGIHT = 'spotlight',
}

export type TypeBaseUserDetail = PartialBy<
  Omit<
    TypeNewUserDetail,
    | 'code'
    | 'emailSubscriptionStatusByKey'
    | 'friendStatusById'
    | 'placeIdsByKey'
  >,
  'demographicData'
>;

export type TypeEmailSubscriptionStatusByKey = {
  [EnumEmailSubscriptionKey.SPOTLGIHT]: boolean;
};

export type TypeFriendStatusById = {
  [userDetailId: string]: EnumFriendStatusKey;
};

export type TypeNewUserDetail = Omit<TypeUserDetail, 'id'>;

export type TypeUserDemographicData = {
  annualIncome?: number;
  birthDate?: string;
  genderKey?: EnumGenderKey;
  movingReasonKey?: EnumMovingReasonKey;
  movingTimeframeKey?: EnumMovingTimeframeKey;
  occupationKey?: EnumOccupationKey;
};

export type TypeUserDetail = {
  id: string;
  avatarUrl?: string;
  bio?: string;
  code: string;
  demographicData: TypeUserDemographicData;
  emailSubscriptionStatusByKey: TypeEmailSubscriptionStatusByKey;
  firstName: string;
  friendStatusById: TypeFriendStatusById;
  lastName?: string;
  placeIdsByKey: TypeUserPlaceIdsByKey;
  primaryPreferenceId: string;
  userId: string;
  username?: string;
};

export enum EnumUserPlaceKey {
  CURRENT = 'current',
  PAST = 'past',
  VISITED = 'visited',
  WISHLIST = 'wishlist',
}

export type TypeUserPlaceIdsByKey = {
  [EnumUserPlaceKey.CURRENT]: string[];
  [EnumUserPlaceKey.PAST]: string[];
  [EnumUserPlaceKey.VISITED]: string[];
  [EnumUserPlaceKey.WISHLIST]: string[];
};
