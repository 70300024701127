import { Controller, useFormContext } from 'react-hook-form';
import {
  EnumMatchQuestionKey,
  TypeMatchQuestionRangeControlConfig,
} from 'util/match/types/questionTypes';
import LargeRangeInput from 'components/ui/core/form/LargeRangeInput';
import RangeInput from 'components/ui/core/form/RangeInput';

export type TypeMatchQuestionRangeFormControlProps = {
  controlConfig: TypeMatchQuestionRangeControlConfig;
  questionKey: EnumMatchQuestionKey;
};

export default function MatchQuestionRangeFormControl(
  props: TypeMatchQuestionRangeFormControlProps
) {
  const { controlConfig, questionKey } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={questionKey}
      control={control}
      render={({ field }) => {
        const commonInputProps = {
          getRangeText: controlConfig.getRangeText,
          inputProps: field,
          max: controlConfig.max,
          min: controlConfig.min,
          minDistance: controlConfig.minDistance,
        };

        return isLargeRange(controlConfig.min, controlConfig.max) ? (
          <LargeRangeInput {...commonInputProps} />
        ) : (
          <RangeInput
            {...commonInputProps}
            getValueText={controlConfig.getValueText}
            mx={2}
            stepSize={controlConfig.stepSize}
          />
        );
      }}
      rules={{
        max: controlConfig.max,
        min: controlConfig.min,
      }}
    />
  );
}

const LARGE_RANGE_THRESHOLD = 5000;

const isLargeRange = (min: number, max: number): boolean => {
  return max - min >= LARGE_RANGE_THRESHOLD;
};
