import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import useAuth from 'util/auth/hooks/useAuth';

export type TypeAuthenticatedRouteWrapperProps = {
  children: ReactElement;
};

export default function AuthenticatedRouteWrapper(
  props: TypeAuthenticatedRouteWrapperProps
) {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    return <Navigate to='/login' replace />;
  }

  return children;
}
