import { getPlacePagePath } from 'util/place/helpers/commonHelpers';
import { Link } from 'react-router-dom';
import { placeCardHeightsBySizeKey } from 'util/layout/constants';
import { Skeleton, Stack } from '@mui/material';
import { TypeMatchedPlace } from 'util/models/place/types';
import MatchedPlaceCard from 'components/match/place/MatchedPlaceCard';
import { hasPlacePageFeatureFlag } from 'util/user/featureFlags/helpers';

const emptyArray = Array.from(Array(3));

export type TypeMatchedPlacesListProps = {
  loading?: boolean;
  matchedPlaces: TypeMatchedPlace[];
};

export default function MatchedPlacesList(props: TypeMatchedPlacesListProps) {
  const { loading = false, matchedPlaces } = props;

  return (
    <Stack
      component='ol'
      m={0}
      p={0}
      spacing={3}
      sx={{
        listStyleType: 'none',
      }}
    >
      {loading || !matchedPlaces.length
        ? emptyArray.map((_, index) => (
            <Skeleton
              key={index}
              height={placeCardHeightsBySizeKey.md}
              variant='rounded'
            />
          ))
        : matchedPlaces.map((matchedPlace) => (
            <li key={matchedPlace.place.placeId}>
              {hasPlacePageFeatureFlag() ? (
                <Link to={getPlacePagePath(matchedPlace.place)}>
                  <MatchedPlaceCard matchedPlace={matchedPlace} />
                </Link>
              ) : (
                <MatchedPlaceCard matchedPlace={matchedPlace} />
              )}
            </li>
          ))}
    </Stack>
  );
}
