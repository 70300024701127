import {
  EnumEmailSubscriptionKey,
  EnumUserPlaceKey,
  TypeEmailSubscriptionStatusByKey,
  TypeUserPlaceIdsByKey,
} from 'util/models/userDetail/types';

export const USER_CODE_ALPHABET =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const USER_CODE_LENGTH = 12;

export const defaultEmailSubscriptionStatusByKey: TypeEmailSubscriptionStatusByKey =
  {
    [EnumEmailSubscriptionKey.SPOTLGIHT]: true,
  };

export const emptyUserPlaceIdsByKey: TypeUserPlaceIdsByKey = {
  [EnumUserPlaceKey.CURRENT]: [],
  [EnumUserPlaceKey.PAST]: [],
  [EnumUserPlaceKey.VISITED]: [],
  [EnumUserPlaceKey.WISHLIST]: [],
};
