import { DEFAULT_HTML_TITLE } from 'util/browser/constants';
import Helmet from 'react-helmet';

export type TypeHtmlTitleProps = {
  title?: string;
};

export default function HtmlTitle(props: TypeHtmlTitleProps) {
  const { title } = props;

  const fullTitle = title ? `${title} - MapTitan` : DEFAULT_HTML_TITLE;

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
}
