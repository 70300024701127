import { EnumPlaceDataKey, EnumPlaceType } from 'util/models/place/types';
import { PartialBy } from 'util/common/types/utilityTypes';

export enum EnumPreferenceFilterVariableKey {
  AVERAGE = 'average',
}

export type TypeBasePreference = PartialBy<
  TypeNewPreference,
  'filtersByKey' | 'location' | 'placeType'
>;

export type TypeNewPreference = Omit<TypePreference, 'id'>;

export type TypePartialPreference = PartialBy<
  Omit<TypeNewPreference, 'userId'>,
  'filtersByKey' | 'location' | 'placeType'
>;

export type TypePreference = {
  id: string;
  filtersByKey: TypePreferenceFiltersByKey;
  location: TypePreferenceLocation;
  placeType: EnumPlaceType;
  userId: string;
};

export type TypePreferenceFiltersByKey = {
  [key in EnumPlaceDataKey]?: TypePreferenceFilter;
};

export type TypePreferenceFilter = {
  max: TypePreferenceFilterRangeValue;
  min: TypePreferenceFilterRangeValue;
};

export type TypePreferenceFilterRangeValue =
  | EnumPreferenceFilterVariableKey
  | null
  | number;

export type TypePreferenceLocation = {
  placeIds: string[];
  radius?: number;
};

export type TypeUpdatePreferenceMutationContext = {
  previousPreference?: TypePreference;
};
