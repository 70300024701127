import { Box } from '@mui/material';
import { EnumPageContainerMaxSize } from 'util/layout/types';
import { ReactElement } from 'react';
import PageContainer from 'components/ui/layout/page/PageContainer';

export type TypePageToolbarProps = {
  children: ReactElement;
  maxSize?: EnumPageContainerMaxSize;
};

export default function PageToolbar(props: TypePageToolbarProps) {
  const { children, maxSize } = props;

  return (
    <Box mb={3} py={2}>
      <PageContainer maxSize={maxSize}>{children}</PageContainer>
    </Box>
  );
}
