import { Box, Typography } from '@mui/material';
import { hasSignupFeatureFlag } from 'util/user/featureFlags/helpers';
import { useIntl } from 'react-intl';
import BrandButtonLink from 'components/common/brand/BrandButtonLink';
import ComingSoonSignupBanner from 'components/signup/ComingSoonSignupBanner';
import MinimalPage from 'components/ui/layout/minimalPage/MinimalPage';
import SideFeatureImage from 'assets/img/park_city-ut-usa.jpg';
import SignupFooter from 'components/signup/SignupFooter';
import SignupFormToggle from 'components/signup/SignupToggle';
import SignupSideFeature from 'components/signup/SignupSideFeature';

export default function SignupPage() {
  const { formatMessage } = useIntl();

  return (
    <MinimalPage
      footer={<SignupFooter />}
      header={<BrandButtonLink />}
      htmlTitle={formatMessage({ defaultMessage: 'Sign up for free' })}
      sideFeature={<SignupSideFeature />}
      sideFeatureProps={{
        alignItems: 'flex-end',
        style: {
          backgroundImage: `url(${SideFeatureImage})`,
        },
      }}
    >
      <Box mb={6}>
        <Typography component='h1' mb={4} textAlign='center' variant='h4'>
          {formatMessage({ defaultMessage: 'Sign up' })}
        </Typography>
        {hasSignupFeatureFlag() ? (
          <SignupFormToggle />
        ) : (
          <ComingSoonSignupBanner />
        )}
      </Box>
    </MinimalPage>
  );
}
