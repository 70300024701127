import { addDoc, collection, query, where } from 'firebase/firestore';
import { db } from 'util/common/constants/firebaseConstants';
import { getFirstDoc } from 'util/common/services/queryServices';
import {
  TypeNewUserDetail,
  TypeUserDetail,
} from 'util/models/userDetail/types';

const userDetailsCollection = collection(db, 'userDetails');

export const createUserDetail = async (
  userDetail: TypeNewUserDetail
): Promise<TypeUserDetail> => {
  const documentReference = await addDoc(userDetailsCollection, userDetail);
  return {
    id: documentReference.id,
    ...userDetail,
  };
};

export const fetchUserDetailByUserId = async (
  userId: string
): Promise<TypeUserDetail> => {
  const userIdQuery = query(
    userDetailsCollection,
    where('userId', '==', userId)
  );
  return getFirstDoc<TypeUserDetail>(userIdQuery);
};
