import { createContext, ReactNode } from 'react';
import { EnumSidebarFormat } from 'util/layout/constants';
import useSidebarsManager, {
  TypeUseSidebarsManagerReturnValue,
} from 'util/layout/hooks/useSidebarsManager';

export type TypePageSidebarContextValue = TypeUseSidebarsManagerReturnValue;

export const PageSidebarContext = createContext<TypePageSidebarContextValue>({
  closePrimarySidebar: () => {},
  closeSecondarySidebar: () => {},
  openPrimarySidebar: () => {},
  openSecondarySidebar: () => {},
  primarySidebarIsOpen: false,
  secondarySidebarIsOpen: false,
  sidebarFormat: EnumSidebarFormat.ADJACENT,
});

export type TypePageSidebarProviderProps = {
  children: ReactNode;
};

export default function PageSidebarProvider(
  props: TypePageSidebarProviderProps
) {
  const { children } = props;
  const value = useSidebarsManager();

  return (
    <PageSidebarContext.Provider value={value}>
      {children}
    </PageSidebarContext.Provider>
  );
}
