import { EnumPageSidebarRank } from 'util/layout/types';
import { NAVBAR_HEIGHT } from 'components/nav/NavBar';
import { ReactNode } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import useSidebarColumnWidth from 'util/layout/hooks/useSidebarColumnWidth';

export type TypePageSidebarProps = {
  children: ReactNode;
  rank: EnumPageSidebarRank;
};

export default function PageSidebar(props: TypePageSidebarProps) {
  const { children, rank } = props;
  const { primarySidebarColumnWidth, secondarySidebarColumnWidth } =
    useSidebarColumnWidth();

  return (
    <Grid
      borderRight='1px solid'
      borderColor='divider'
      component='aside'
      height={`calc(100vh - ${NAVBAR_HEIGHT}px)`}
      xsOffset={
        rank === EnumPageSidebarRank.SECONDARY
          ? primarySidebarColumnWidth
          : undefined
      }
      position='fixed'
      xs={
        rank === EnumPageSidebarRank.SECONDARY
          ? secondarySidebarColumnWidth
          : primarySidebarColumnWidth
      }
      sx={{
        overflowY: 'auto',
      }}
    >
      {children}
    </Grid>
  );
}
