import {
  createUserDetail,
  fetchUserDetailByUserId,
} from 'util/models/userDetail/services';
import { defaultUseQueryOptions } from 'util/common/constants/queryConstants';
import {
  generateNewUserDetail,
  getUserDetailByUserIdQueryKey,
} from 'util/models/userDetail/helpers';
import {
  TypeBaseUserDetail,
  TypeUserDetail,
} from 'util/models/userDetail/types';
import {
  TypeUseMutationOptions,
  TypeUseQueryOptions,
} from 'util/common/types/queryTypes';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useCreateUserDetailMutation = (
  mutationOptions: TypeUseMutationOptions = {}
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: (userDetail: TypeBaseUserDetail) =>
      createUserDetail(generateNewUserDetail(userDetail)),
  });
};

export const useUserDetailByUserIdQuery = (
  userId: string,
  queryOptions: TypeUseQueryOptions = {}
) => {
  const { data, isLoading, ...rest } = useQuery({
    ...defaultUseQueryOptions,
    ...queryOptions,
    queryKey: getUserDetailByUserIdQueryKey(userId),
    queryFn: () => fetchUserDetailByUserId(userId),
    select: (data: TypeUserDetail) => data,
  });

  return {
    ...rest,
    loadingUserDetail: isLoading,
    userDetail: data,
  };
};
