export enum EnumContactSectionKey {
  CONTACT = 'CONTACT',
  PRESS = 'PRESS',
}

export type TypeContactSection = {
  key: EnumContactSectionKey;
  email: string;
  titleText: string;
};
