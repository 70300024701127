import {
  AppBar,
  Button,
  Container,
  Divider,
  Stack,
  Toolbar,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useBreakpointValue, useSemanticBreakpoints } from 'util/theme/hooks';
import { useIntl } from 'react-intl';
import { useMenu } from 'util/common/hooks';
import BrandButtonLink from 'components/common/brand/BrandButtonLink';
import IconButton from 'components/ui/core/button/IconButton';
import useAuth from 'util/auth/hooks/useAuth';
import UserAvatar from 'components/user/UserAvatar';
import UserMenu from 'components/user/UserMenu';

export const NAVBAR_HEIGHT = 65;

export default function NavBar() {
  const { isAuthenticated } = useAuth();
  const { formatMessage } = useIntl();
  const signupButtonText = useBreakpointValue({
    xs: formatMessage({ defaultMessage: 'Sign up' }),
    sm: formatMessage({ defaultMessage: 'Sign up free' }),
  });
  const { anchorElement, closeMenu, open, openMenu } = useMenu();
  const { mobile } = useSemanticBreakpoints();

  return (
    <>
      <AppBar component='nav' position='fixed' sx={{ height: NAVBAR_HEIGHT }}>
        <Container maxWidth={false} sx={{ height: '100%' }}>
          <Toolbar sx={{ height: '100%' }} disableGutters>
            <Stack direction='row' flexGrow={1} spacing={1}>
              <BrandButtonLink />
            </Stack>
            <Stack direction='row' spacing={1}>
              {isAuthenticated() && !mobile && (
                <>
                  <IconButton
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    onClick={openMenu}
                    tooltipText={formatMessage({ defaultMessage: 'You' })}
                  >
                    <UserAvatar />
                  </IconButton>
                  <UserMenu
                    anchorElement={anchorElement}
                    onClose={closeMenu}
                    open={open}
                  />
                </>
              )}
              {!isAuthenticated() && (
                <>
                  {!mobile && (
                    <Button component={RouterLink} to='/login'>
                      {formatMessage({ defaultMessage: 'Log in' })}
                    </Button>
                  )}
                  <Button
                    component={RouterLink}
                    to='/signup'
                    variant='contained'
                  >
                    {signupButtonText}
                  </Button>
                </>
              )}
            </Stack>
          </Toolbar>
        </Container>
        <Divider />
      </AppBar>
      {/* This toolbar pushes the main content down because the AppBar has a fixed position. Details: 
      https://mui.com/material-ui/react-app-bar/#fixed-placement */}
      <Toolbar sx={{ height: NAVBAR_HEIGHT }} />
    </>
  );
}
