import { Box } from '@mui/material';
import { EnumPageContainerMaxSize } from 'util/layout/types';
import { ReactNode } from 'react';
import PageContainer from 'components/ui/layout/page/PageContainer';

export type TypeMinimalPageContainerProps = {
  children: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  maxSize?: EnumPageContainerMaxSize;
};

export default function MinimalPageContainer(
  props: TypeMinimalPageContainerProps
) {
  const { children, footer, header, maxSize } = props;

  return (
    <Box
      alignItems='flex-start'
      display='flex'
      flexDirection='column'
      height='100%'
      justifyContent='space-between'
      px={4}
      py={3}
    >
      {header && (
        <Box component='nav' mb={3}>
          {header}
        </Box>
      )}
      <PageContainer maxSize={maxSize}>
        <Box component='main' width='100%'>
          {children}
        </Box>
      </PageContainer>
      {footer && (
        <Box component='footer' mt={3} width='100%'>
          {footer}
        </Box>
      )}
    </Box>
  );
}
