import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { intl } from 'util/lang/helpers';
import {
  RiEmotionLaughLine,
  RiHandHeartLine,
  RiLineChartLine,
  RiSearchEyeLine,
  RiUserHeartLine,
  RiWindyLine,
} from 'react-icons/ri';
import { useIntl } from 'react-intl';

export default function ValuesGrid() {
  const { formatMessage } = useIntl();

  return (
    <Box>
      <Typography component='h2' variant='h6'>
        {formatMessage({
          defaultMessage: 'Our values',
        })}
      </Typography>
      <Grid py={3} spacing={3} container>
        {values.map((value) => {
          return (
            <Grid key={value.name} xs={6} md={4} item>
              <Card sx={{ height: '100%' }} variant='outlined'>
                <CardContent>
                  <Stack alignItems='center' spacing={2}>
                    <Avatar
                      sx={{
                        backgroundColor: 'primary.main',
                        height: 50,
                        width: 50,
                      }}
                    >
                      {value.icon}
                    </Avatar>
                    <Typography component='h3' textAlign='center' variant='h6'>
                      {value.name}
                    </Typography>
                    <Typography
                      component='p'
                      textAlign='center'
                      variant='body1'
                    >
                      {value.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

const values = [
  {
    description: intl.formatMessage({
      defaultMessage:
        "We're all unique. Your experience should be as individual as your interests, skills, and needs are.",
    }),
    icon: <RiUserHeartLine />,
    name: intl.formatMessage({
      defaultMessage: 'Empower Individuality',
    }),
  },
  {
    description: intl.formatMessage({
      defaultMessage:
        'The environments we live in should fuel our well-being. Our healthiest lives are our best lives.',
    }),
    icon: <RiWindyLine />,
    name: intl.formatMessage({
      defaultMessage: 'Emphasize Wellness',
    }),
  },
  {
    description: intl.formatMessage({
      defaultMessage:
        "Fun is how we connect with the wonder and excitement of life. There's joy in all we do.",
    }),
    icon: <RiEmotionLaughLine />,
    name: intl.formatMessage({
      defaultMessage: 'Have Fun',
    }),
  },
  {
    description: intl.formatMessage({
      defaultMessage:
        'Personally and within the community, we look to find the better ways. We seek opportunities to improve ourselves and the lives of others.',
    }),
    icon: <RiLineChartLine />,
    name: intl.formatMessage({
      defaultMessage: 'Seek Improvement',
    }),
  },
  {
    description: intl.formatMessage({
      defaultMessage:
        'We approach how and why things work with childlike wonder. This way, we can craft more fulfilling communities and tools.',
    }),
    icon: <RiSearchEyeLine />,
    name: intl.formatMessage({
      defaultMessage: 'Be Curious',
    }),
  },
  {
    description: intl.formatMessage({
      defaultMessage:
        "We're respectful, peaceful, and generous towards others. Regardless of our backgrounds or experiences, we are one.",
    }),
    icon: <RiHandHeartLine />,
    name: intl.formatMessage({
      defaultMessage: 'Spread Kindness',
    }),
  },
];
