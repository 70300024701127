import { CssBaseline } from '@mui/material';
import { queryClient } from 'util/common/constants/queryConstants';
import { QueryClientProvider } from '@tanstack/react-query';
import AuthProvider from 'components/auth/AuthProvider';
import LangProvider from 'components/lang/LangProvider';
import RouterProvider from 'components/routing/RouterProvider';
import ThemeModeProvider from 'components/ui/theme/ThemeModeProvider';
import ThemeProvider from 'components/ui/theme/ThemeProvider';

export default function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <LangProvider>
          <ThemeModeProvider>
            <ThemeProvider>
              <>
                <CssBaseline />
                <RouterProvider />
              </>
            </ThemeProvider>
          </ThemeModeProvider>
        </LangProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
