import { EnumSizeKey } from 'util/layout/constants';

export enum EnumSemanticBreakpointKey {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

export enum EnumThemeMode {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export type TypeBreakpointKey =
  | EnumSizeKey.XS
  | EnumSizeKey.SM
  | EnumSizeKey.MD
  | EnumSizeKey.LG
  | EnumSizeKey.XL;

export type TypeTypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'button'
  | 'caption'
  | 'inherit'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'overline'
  | undefined;
