import { Box } from '@mui/material';
import HeaderText from 'components/ui/layout/header/HeaderText';

export type TypeSimpleHeaderProps = {
  subtitleText?: string;
  titleText: string;
};

export default function SimpleHeader(props: TypeSimpleHeaderProps) {
  const { subtitleText, titleText } = props;

  return (
    <Box py={4}>
      <HeaderText subtitle={subtitleText} titleText={titleText} />
    </Box>
  );
}
