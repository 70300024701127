import { intl } from 'util/lang/helpers';
import { RawIntlProvider } from 'react-intl';
import { ReactNode } from 'react';

export type TypeLangProviderProps = {
  children: ReactNode;
};

export default function LangProvider(props: TypeLangProviderProps) {
  const { children } = props;
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
