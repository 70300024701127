import { Dialog, DialogContent, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ModalTitle from 'components/ui/core/modal/ModalTitle';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';

export type TypeForgotPasswordModalProps = {
  onCancel: () => void;
  open: boolean;
};

export default function ForgotPasswordModal(
  props: TypeForgotPasswordModalProps
) {
  const { onCancel, open } = props;
  const { formatMessage } = useIntl();

  return (
    <Dialog onClose={onCancel} open={open}>
      <ModalTitle onClose={onCancel}>
        {formatMessage({ defaultMessage: 'Forgot password?' })}
      </ModalTitle>
      <DialogContent>
        <Typography mb={3}>
          {formatMessage({
            defaultMessage:
              "Enter the email address associated with your account, and we'll send you an email with a link to reset your password.",
          })}
        </Typography>
        <ResetPasswordForm />
      </DialogContent>
    </Dialog>
  );
}
