import {
  darkTheme,
  lightTheme,
  semanticBreakpointKeyMap,
} from 'util/theme/constants';
import {
  EnumSemanticBreakpointKey,
  EnumThemeMode,
  TypeBreakpointKey,
} from 'util/theme/types';
import {
  getClosestBreakpointValue,
  isDarkThemeMode,
  isDesktopScreenSize,
  isMobileScreenSize,
  isTabletScreenSize,
  isThemeMode,
} from 'util/theme/helpers';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import {
  ThemeModeContext,
  TypeThemeModeContextValue,
} from 'components/ui/theme/ThemeModeProvider';
import { useContext, useMemo } from 'react';
import { useLocalStorage } from 'util/browser/hooks';

/**
 * React hook used to get the current responsive media breakpoint.
 *
 * For SSR, you can use a package like [is-mobile](https://github.com/kaimallea/isMobile)
 * to get the default breakpoint value from the user-agent.
 */
export const useBreakpointKey = (): TypeBreakpointKey => {
  const theme = useTheme();
  const valuesByBreakpointKey: { [key in TypeBreakpointKey]: boolean } = {
    xs: useMediaQuery(theme.breakpoints.only('xs')),
    sm: useMediaQuery(theme.breakpoints.only('sm')),
    md: useMediaQuery(theme.breakpoints.only('md')),
    lg: useMediaQuery(theme.breakpoints.only('lg')),
    xl: useMediaQuery(theme.breakpoints.only('xl')),
  };

  return (
    (Object.keys(valuesByBreakpointKey) as TypeBreakpointKey[]).filter(
      (breakpointKey) => !!valuesByBreakpointKey[breakpointKey]
    )[0] ?? 'xs'
  );
};

/**
 * React hook for getting the value for the current breakpoint from the
 * provided responsive values object.
 *
 * For SSR, you can use a package like [is-mobile](https://github.com/kaimallea/isMobile)
 * to get the default breakpoint value from the user-agent
 *
 * @example
 * const width = useBreakpointValue({ xs: '150px', md: '250px' })
 */
export function useBreakpointValue<T = any>(
  valuesByBreakpointKey: Partial<Record<TypeBreakpointKey, T>>
): T | undefined {
  const currentBreakpointKey = useBreakpointKey();
  return getClosestBreakpointValue(valuesByBreakpointKey, currentBreakpointKey);
}

export const useGetTheme = (): Theme => {
  const { themeMode } = useThemeMode();

  return useMemo(() => {
    return isDarkThemeMode(themeMode) ? darkTheme : lightTheme;
  }, [themeMode]);
};

export type TypeUseSemanticBreakpointsReturnValue = {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
};

export const useSemanticBreakpoints =
  (): TypeUseSemanticBreakpointsReturnValue => {
    const semanticBreakpointKey =
      useBreakpointValue(semanticBreakpointKeyMap) ??
      EnumSemanticBreakpointKey.DESKTOP;

    return useMemo(() => {
      return {
        mobile: isMobileScreenSize(semanticBreakpointKey),
        tablet: isTabletScreenSize(semanticBreakpointKey),
        desktop: isDesktopScreenSize(semanticBreakpointKey),
      };
    }, [semanticBreakpointKey]);
  };

export const useThemeMode = (): TypeThemeModeContextValue => {
  return useContext(ThemeModeContext);
};

export const useThemeModeManager = (): TypeThemeModeContextValue => {
  const [mode, setMode] = useLocalStorage<EnumThemeMode>('maptitan-theme');
  const darkModeIsPreferred = useMediaQuery('(prefers-color-scheme: dark)');

  const toggleDarkThemeMode = () => {
    if (isDarkThemeMode(mode)) {
      setMode(EnumThemeMode.LIGHT);
    } else {
      setMode(EnumThemeMode.DARK);
    }
  };

  const activeMode = useMemo(() => {
    return isThemeMode(mode)
      ? mode
      : darkModeIsPreferred
      ? EnumThemeMode.DARK
      : EnumThemeMode.LIGHT;
  }, [mode]);

  return {
    setThemeMode: setMode,
    themeMode: activeMode,
    toggleDarkThemeMode,
  };
};
