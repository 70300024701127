import { DialogTitle, DialogTitleProps } from '@mui/material';
import { FiX } from 'react-icons/fi';
import { useIntl } from 'react-intl';
import IconButton from 'components/ui/core/button/IconButton';

export type TypeModalTitleProps = DialogTitleProps & {
  onClose: () => void;
};

export default function ModalTitle(props: TypeModalTitleProps) {
  const { children, onClose, ...rest } = props;
  const { formatMessage } = useIntl();

  return (
    <DialogTitle
      alignItems='center'
      display='flex'
      justifyContent='space-between'
      {...rest}
    >
      {children}
      <IconButton
        onClick={onClose}
        tooltipText={formatMessage({ defaultMessage: 'Close' })}
      >
        <FiX />
      </IconButton>
    </DialogTitle>
  );
}
