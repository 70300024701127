import { EnumRouteKey } from 'util/routing/types';
import { routePathsByRouteKey } from 'util/routing/constants';

export const findRouteKey = (routePath?: string): EnumRouteKey => {
  if (!routePath) {
    return EnumRouteKey.HOME;
  }

  const routeKey = (Object.keys(routePathsByRouteKey) as EnumRouteKey[]).find(
    (routeKey) => routePathsByRouteKey[routeKey] === routePath
  );

  return routeKey ?? EnumRouteKey.HOME;
};
