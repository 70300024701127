import { Button, Grid, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import FeaturedOnLogos from 'components/home/FeaturedOnLogos';
import Footer from 'components/footer/Footer';
import headerImage from 'assets/img/platte_river-denver-co-usa.jpg';
import Page from 'components/ui/layout/page/Page';
import TestimonialsGrid from 'components/home/TestimonialsGrid';
import ValuesGrid from 'components/home/ValuesGrid';

export default function TemporaryLandingPage() {
  const { formatMessage } = useIntl();

  return (
    <Page
      footer={<Footer />}
      header={
        <Grid alignItems='center' justifyContent='center' py={6} container>
          <Grid lg={8} mb={3} md={10} mr='auto' item>
            <Typography
              color='white'
              component='h1'
              sx={{ textShadow: '0 0 4px #000' }}
              variant='h2'
            >
              {formatMessage(
                {
                  defaultMessage:
                    'Find where <highlight>you</highlight> belong',
                },
                {
                  highlight: (chunks: ReactNode) => (
                    <Typography
                      component='span'
                      mr={1}
                      sx={{ fontFamily: 'Caveat' }}
                      variant='h1'
                    >
                      {chunks}
                    </Typography>
                  ),
                }
              )}
            </Typography>
            <Typography
              color='white'
              component='div'
              fontWeight='bold'
              mb={3}
              sx={{ textShadow: '0 0 5px #000' }}
              variant='subtitle1'
            >
              {formatMessage({
                defaultMessage:
                  'Discover the best cities and towns based on your unique preferences for demographics, housing, and more.',
              })}
            </Typography>
            <Button
              component={RouterLink}
              size='large'
              to='/signup'
              variant='contained'
            >
              {formatMessage({
                defaultMessage: 'See your places →',
              })}
            </Button>
          </Grid>
        </Grid>
      }
      headerProps={{
        backgroundImageUrl: headerImage,
      }}
    >
      <Stack my={6} spacing={5}>
        <ValuesGrid />
        <TestimonialsGrid />
        <FeaturedOnLogos my={5} />
      </Stack>
    </Page>
  );
}
