import { Avatar } from '@mui/material';
import { FiUser } from 'react-icons/fi';

export default function UserAvatar() {
  return (
    <Avatar>
      <FiUser />
    </Avatar>
  );
}
