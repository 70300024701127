import {
  EMAIL_MAX_LENGTH,
  EMAIL_MIN_LENGTH,
  EMAIL_REGEX,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  PASSWORD_MIN_LENGTH,
} from 'util/form/constants';

const isEmpty = (value: any): boolean => value == null || value.length === 0;

type TypeHasLengthOptions = {
  whitespace?: boolean;
};

const hasMaxLength = (
  value: string,
  maxLength: number,
  options?: TypeHasLengthOptions
): boolean => {
  const { whitespace = false } = options ?? {};
  const trimmedValue = whitespace ? value : value.trim();
  return isEmpty(trimmedValue) || trimmedValue.length <= maxLength;
};

const hasMinLength = (
  value: string,
  minLength: number,
  options?: TypeHasLengthOptions
): boolean => {
  const { whitespace = false } = options ?? {};
  const trimmedValue = whitespace ? value : value.trim();
  return !isEmpty(trimmedValue) && trimmedValue.length >= minLength;
};

export const isEmailAddressValid = (emailAddress: string): boolean => {
  if (
    !emailAddress ||
    !hasMaxLength(emailAddress, EMAIL_MAX_LENGTH) ||
    !hasMinLength(emailAddress, EMAIL_MIN_LENGTH)
  ) {
    return false;
  }

  return EMAIL_REGEX.test(emailAddress);
};

export const isNameValid = (name: string): boolean => {
  return (
    !!name &&
    hasMaxLength(name, NAME_MAX_LENGTH) &&
    hasMinLength(name, NAME_MIN_LENGTH)
  );
};

export const isPasswordValid = (password: string): boolean => {
  return (
    !!password &&
    hasMinLength(password, PASSWORD_MIN_LENGTH, { whitespace: true })
  );
};

type TypeIsFormSubmittableOptions = {
  dirty: boolean;
  loading?: boolean;
  submitting: boolean;
  valid: boolean;
};

export const isFormSubmittable = (
  options: TypeIsFormSubmittableOptions
): boolean => {
  const { dirty, loading = false, submitting, valid } = options;
  return dirty && !loading && !submitting && valid;
};
