import { EnumPlaceDataKey } from 'util/models/place/types';
import { TypePlaceDataStatsByKey } from 'util/place/types/commonTypes';

export const statsByPlaceDataKey: Record<
  EnumPlaceDataKey,
  TypePlaceDataStatsByKey
> = {
  medianAge: {
    average: 40.8,
    max: 90.1,
    min: 0,
  },
  medianGrossRentFourBedroom: {
    average: 1398,
    max: 3501,
    min: 99,
  },
  medianGrossRentOneBedroom: {
    average: 617,
    max: 3501,
    min: 99,
  },
  medianGrossRentStudio: {
    average: 777,
    max: 3501,
    min: 99,
  },
  medianGrossRentThreeBedroom: {
    average: 982,
    max: 3501,
    min: 99,
  },
  medianGrossRentTwoBedroom: {
    average: 792,
    max: 3501,
    min: 99,
  },
  percentBikeToWork: {
    average: 0,
    max: 35.533,
    min: 0,
  },
  percentDriveToWork: {
    average: 88.40305000000001,
    max: 100,
    min: 0,
  },
  percentOwnerOccupiedHousing: {
    average: 73.5294,
    max: 100,
    min: 0,
  },
  percentVacantHousingForRent: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentVacantHousingForSale: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentVacantHousingResidenceElsewhere: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentMovedFromOutOfCounty: {
    average: 4.15705,
    max: 100,
    min: 0,
  },
  percentPublicTransitToWork: {
    average: 0,
    max: 55.2286,
    min: 0,
  },
  percentWalkToWork: {
    average: 1.40915,
    max: 76.5152,
    min: 0,
  },
  percentWorkFromHome: {
    average: 3.7731000000000003,
    max: 82.6896,
    min: 0,
  },
  percentNewHousing: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentOldHousing: {
    average: 13.2075,
    max: 100,
    min: 0,
  },
  medianHousingYearBuilt: {
    average: 1972,
    max: 2013,
    min: 0,
  },
  percentNewOwnerOccupiedHousing: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentOldOwnerOccupiedHousing: {
    average: 12.5,
    max: 100,
    min: 0,
  },
  percentNewRenterOccupiedHousing: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentOldRenterOccupiedHousing: {
    average: 5.7143,
    max: 100,
    min: 0,
  },
  medianOwnerOccupiedHousingYearBuilt: {
    average: 1971,
    max: 2014,
    min: 0,
  },
  medianRenterOccupiedHousingYearBuilt: {
    average: 1974,
    max: 2014,
    min: 0,
  },
  percentMedianGrossRentOfHouseholdIncome: {
    average: 27.6,
    max: 51,
    min: 9,
  },
  percentMaleNeverMarried: {
    average: 30.6626,
    max: 100,
    min: 0,
  },
  percentMaleNowMarried: {
    average: 53.19185,
    max: 100,
    min: 0,
  },
  percentMaleDivorced: {
    average: 9.7872,
    max: 100,
    min: 0,
  },
  percentFemaleNeverMarried: {
    average: 23.40985,
    max: 100,
    min: 0,
  },
  percentFemaleNowMarried: {
    average: 50.67935,
    max: 100,
    min: 0,
  },
  percentFemaleDivorced: {
    average: 12,
    max: 100,
    min: 0,
  },
  percentWomenWithBirthInPastYear: {
    average: 3.6885,
    max: 100,
    min: 0,
  },
  percentUndergraduateCollegeStudents: {
    average: 2.8205999999999998,
    max: 100,
    min: 0,
  },
  percentGraduateCollegeStudents: {
    average: 0.2915,
    max: 53.0435,
    min: 0,
  },
  percentHighSchoolDiploma: {
    average: 28.2018,
    max: 100,
    min: 0,
  },
  percentSomeCollege: {
    average: 13.6364,
    max: 100,
    min: 0,
  },
  percentAssociatesDegree: {
    average: 8.3631,
    max: 100,
    min: 0,
  },
  percentBachelorsDegree: {
    average: 12.3348,
    max: 100,
    min: 0,
  },
  percentMastersDegree: {
    average: 4.2148,
    max: 100,
    min: 0,
  },
  percentProfessionalDegree: {
    average: 0.3106,
    max: 68,
    min: 0,
  },
  percentDoctorateDegree: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentBachelorsDegreeScienceAndEngineering: {
    average: 26.8817,
    max: 100,
    min: 0,
  },
  percentBachelorsDegreeBusiness: {
    average: 15.8302,
    max: 100,
    min: 0,
  },
  percentBachelorsDegreeEducation: {
    average: 15.2174,
    max: 100,
    min: 0,
  },
  percentBachelorsDegreeArtsAndHumanities: {
    average: 19.18035,
    max: 100,
    min: 0,
  },
  percentImpoverished: {
    average: 11.0259,
    max: 100,
    min: 0,
  },
  incomeDeficitPerCapita: {
    average: 334.2508,
    max: 11475.625,
    min: 5.5975,
  },
  percentSelfEmployedWithIncome: {
    average: 8.8534,
    max: 100,
    min: 0,
  },
  percentInterestDividendsAndRentalIncome: {
    average: 16.5606,
    max: 100,
    min: 0,
  },
  percentIncomeWages: {
    average: 95.6939,
    max: 131.9141,
    min: 14.7432,
  },
  percentIncomeSelfEmployment: {
    average: 4.9688,
    max: 100,
    min: -11.533,
  },
  percentIncomeInterestDividendsAndRentalIncome: {
    average: 3.7452,
    max: 596.9982,
    min: -2.5833,
  },
  giniIndex: {
    average: 0.4002,
    max: 1,
    min: 0.0007,
  },
  modeUpperBoundNonFamilyHouseholdIncome: {
    average: 25000,
    max: 300000,
    min: 10000,
  },
  medianAnnualIncome: {
    average: 33042,
    max: 250001,
    min: 2499,
  },
  annualIncomePerCapita: {
    average: 27034,
    max: 603587,
    min: 796,
  },
  medianAnnualEarnings: {
    average: 43257,
    max: 250001,
    min: 2499,
  },
  modeUpperBoundHouseholdIncome: {
    average: 75000,
    max: 300000,
    min: 10000,
  },
  percentVeteran: {
    average: 7.43005,
    max: 100,
    min: 0,
  },
  meanWeeklyHoursWorked: {
    average: 39,
    max: 81.9,
    min: 17.3,
  },
  percentForeignBornDenmark: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIreland: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNorway: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSweden: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornEngland: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornScotland: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornAustria: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBelgium: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornFrance: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGermany: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNetherlands: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSwitzerland: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGreece: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornItaly: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPortugal: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSpain: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornAlbania: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBelarus: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBosniaAndHerzegovina: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBulgaria: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCroatia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCzechRepublicOrSlovakia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornHungary: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornLatvia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornLithuania: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornMoldova: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNorthMacedonia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPoland: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornRomania: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornRussia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSerbia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornUkraine: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornChina: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornHongKong: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornTaiwan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornJapan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornKorea: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornAfghanistan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBangladesh: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIndia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIran: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornKazakhstan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNepal: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPakistan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSriLanka: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornUzbekistan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBurma: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCambodia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIndonesia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornLaos: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornMalaysia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPhilippines: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSingapore: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornThailand: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornVietnam: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornArmenia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIraq: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornIsrael: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornJordan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornKuwait: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornLebanon: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSaudiArabia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSyria: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornTurkey: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornYemen: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornEritrea: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornEthiopia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornKenya: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSomalia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornUganda: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornZimbabwe: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCameroon: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCongo: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornDemocraticRepublicOfCongoZaire: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornEgypt: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornMorocco: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSudan: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSouthAfrica: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCaboVerde: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGhana: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornLiberia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNigeria: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSenegal: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornSierraLeone: {
    average: 0,
    max: 87.5,
    min: 0,
  },
  percentForeignBornAustralia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornFiji: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornMicronesia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBahamas: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBarbados: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCuba: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornDominica: {
    average: 0,
    max: 80.203,
    min: 0,
  },
  percentForeignBornDominicanRepublic: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGrenada: {
    average: 0,
    max: 77.7778,
    min: 0,
  },
  percentForeignBornHaiti: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornJamaica: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornStVincentAndTheGrenadines: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornTrinidadAndTobago: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBelize: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCostaRica: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornElSalvador: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGuatemala: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornHonduras: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornMexico: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornNicaragua: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPanama: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornArgentina: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBolivia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornBrazil: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornChile: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornColombia: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornEcuador: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornGuyana: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornPeru: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornUruguay: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornVenezuela: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentForeignBornCanada: {
    average: 0,
    max: 100,
    min: 0,
  },
  percentFamilyHouseholds: {
    average: 65.7136,
    max: 100,
    min: 0,
  },
  population: {
    average: 1010,
    max: 8379552,
    min: 0,
  },
  percentMale: {
    average: 49.02865,
    max: 100,
    min: 0,
  },
  percentMaleGenAlpha: {
    average: 11.467649999999999,
    max: 100,
    min: 0,
  },
  percentMaleGenZ: {
    average: 18.451500000000003,
    max: 100,
    min: 0,
  },
  percentMaleGenY: {
    average: 17.7578,
    max: 100,
    min: 0,
  },
  percentMaleGenX: {
    average: 17.653399999999998,
    max: 100,
    min: 0,
  },
  percentMaleBabyBoomer: {
    average: 22.8571,
    max: 100,
    min: 0,
  },
  percentMaleSilentGeneration: {
    average: 5.48235,
    max: 100,
    min: 0,
  },
  percentFemale: {
    average: 50.84245,
    max: 100,
    min: 0,
  },
  percentFemaleGenAlpha: {
    average: 10.505949999999999,
    max: 89.881,
    min: 0,
  },
  percentFemaleGenZ: {
    average: 16.7211,
    max: 100,
    min: 0,
  },
  percentFemaleGenY: {
    average: 17.15885,
    max: 100,
    min: 0,
  },
  percentFemaleGenX: {
    average: 17.46495,
    max: 100,
    min: 0,
  },
  percentFemaleBabyBoomer: {
    average: 24.087899999999998,
    max: 100,
    min: 0,
  },
  percentFemaleSilentGeneration: {
    average: 7.89555,
    max: 100,
    min: 0,
  },
  percentGenAlpha: {
    average: 11.31245,
    max: 82.967,
    min: 0,
  },
  percentGenZ: {
    average: 17.917450000000002,
    max: 100,
    min: 0,
  },
  percentGenY: {
    average: 17.6831,
    max: 100,
    min: 0,
  },
  percentGenX: {
    average: 17.7686,
    max: 100,
    min: 0,
  },
  percentBabyBoomer: {
    average: 23.664900000000003,
    max: 100,
    min: 0,
  },
  percentSilentGeneration: {
    average: 7.0175,
    max: 100,
    min: 0,
  },
  percentMaleSenior: {
    average: 15.503,
    max: 100,
    min: 0,
  },
  percentFemaleSenior: {
    average: 19.0209,
    max: 100,
    min: 0,
  },
  percentSenior: {
    average: 17.600250000000003,
    max: 100,
    min: 0,
  },
  modeUpperBoundCommuteMinutes: {
    average: 20,
    max: 120,
    min: 5,
  },
  medianHousingValue: {
    average: 132900,
    max: 2000001,
    min: 9999,
  },
};
