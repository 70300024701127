import { ONE_DAY_IN_MS } from 'util/common/constants/datetimeConstants';
import { QueryClient, UseQueryOptions } from '@tanstack/react-query';

export const defaultUseQueryOptions: UseQueryOptions<
  any,
  unknown,
  any,
  string[]
> = { refetchOnWindowFocus: false, staleTime: ONE_DAY_IN_MS };

export const queryClient = new QueryClient();

export const rootQueryKeysByKey: Record<string, string[]> = {
  MATCH_PLACES: ['matchPlaces'],
  PLACES: ['places'],
  PREFERENCES: ['preferences'],
  USER_DETAILS: ['userDetails'],
};
