import { MouseEvent, useCallback, useState } from 'react';
import {
  TypeUseMenuReturnValue,
  TypeUseToggleReturnValue,
} from 'util/common/types';

export const useMenu = (): TypeUseMenuReturnValue => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();

  const closeMenu = () => {
    setAnchorElement(undefined);
  };

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  return {
    open: !!anchorElement,
    anchorElement,
    closeMenu,
    openMenu,
  };
};

export const useToggle = (
  initiallyActive: boolean = false
): TypeUseToggleReturnValue => {
  const [active, setActive] = useState(initiallyActive);

  const toggle = useCallback(() => setActive((active) => !active), []);

  return {
    active,
    setActive,
    toggle,
  };
};
