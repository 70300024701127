import { Box } from '@mui/material';
import { EnumPageContainerMaxSize } from 'util/layout/types';
import { ReactElement } from 'react';
import CoverPhotoHeader from 'components/ui/layout/header/CoverPhotoHeader';
import CustomHeader from 'components/ui/layout/header/CustomHeader';
import PageContainer from 'components/ui/layout/page/PageContainer';
import SimpleHeader from 'components/ui/layout/header/SimpleHeader';

export type TypePageHeaderChildren = ReactElement<
  typeof CoverPhotoHeader | typeof CustomHeader | typeof SimpleHeader
>;

export type TypePageHeaderProps = {
  backgroundColor?: string;
  backgroundImageUrl?: string;
  children: TypePageHeaderChildren;
  maxSize?: EnumPageContainerMaxSize;
};

export default function PageHeader(props: TypePageHeaderProps) {
  const { backgroundColor, backgroundImageUrl, children, maxSize } = props;

  return (
    <Box
      bgcolor={backgroundColor}
      sx={
        backgroundImageUrl
          ? {
              background: `linear-gradient(#00000000, #000), url(${backgroundImageUrl}) no-repeat center/cover`,
            }
          : undefined
      }
    >
      <PageContainer maxSize={maxSize}>{children}</PageContainer>
    </Box>
  );
}
