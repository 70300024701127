import { Alert, AlertTitle, Box, BoxProps } from '@mui/material';
import { useIntl } from 'react-intl';

export type TypeComingSoonLoginBannerProps = BoxProps;

export default function ComingSoonLoginBanner(
  props: TypeComingSoonLoginBannerProps
) {
  const boxProps = props;
  const { formatMessage } = useIntl();

  return (
    <Box {...boxProps}>
      <Alert severity='info' variant='outlined'>
        <AlertTitle>{formatMessage({ defaultMessage: 'Note' })}</AlertTitle>
        {formatMessage({
          defaultMessage:
            "Logins are currently only available for some users. Stay tuned for an announcement about when you'll be able to access your account. Thanks for understanding!",
        })}
      </Alert>
    </Box>
  );
}
