import {
  EnumMatchCategoryKey,
  TypeMatchCategory,
} from 'util/match/types/categoryTypes';
import {
  EnumMatchQuestionControlType,
  EnumMatchQuestionKey,
  TypeMatchQuestion,
  TypeMatchQuestionBranchControlConfig,
  TypeMatchQuestionControlConfig,
} from 'util/match/types/questionTypes';
import { EnumMatchTopicKey, TypeMatchTopic } from 'util/match/types/topicTypes';
import { matchCategoriesByKey } from 'util/match/constants/categoryConstants';
import {
  matchQuestionsByKey,
  orderedMatchQuestionKeysByTopicKey,
} from 'util/match/constants/questionConstants';
import {
  matchTopicsByKey,
  orderedMatchTopicKeysByCategoryKey,
} from 'util/match/constants/topicConstants';

export const getAllQuestionKeys = (): EnumMatchQuestionKey[] => {
  return Object.keys(matchQuestionsByKey) as EnumMatchQuestionKey[];
};

export const getAllQuestionKeysForCategory = (
  categoryKey: EnumMatchCategoryKey
): EnumMatchQuestionKey[] => {
  return getTopicKeysForCategory(categoryKey).reduce(
    (questionKeys: EnumMatchQuestionKey[], topicKey) => {
      return [...questionKeys, ...getAllQuestionKeysForTopic(topicKey)];
    },
    []
  );
};

export const getAllQuestionKeysForTopic = (
  topicKey: EnumMatchTopicKey
): EnumMatchQuestionKey[] => {
  const topicQuestionKeys = getQuestionKeysForTopic(topicKey);
  return topicQuestionKeys.reduce((allQuestionKeys, questionKey) => {
    if (isBranchQuestion(questionKey)) {
      const question = getQuestion(questionKey);
      return [
        ...allQuestionKeys,
        ...(
          question.controlConfig as TypeMatchQuestionBranchControlConfig
        ).options.map((option) => option.questionKey),
      ];
    }

    return allQuestionKeys;
  }, topicQuestionKeys);
};

export const getCategory = (
  categoryKey: EnumMatchCategoryKey
): TypeMatchCategory => {
  return matchCategoriesByKey[categoryKey];
};

export const getQuestion = (
  questionKey: EnumMatchQuestionKey
): TypeMatchQuestion => {
  return matchQuestionsByKey[questionKey];
};

export const getQuestionControlConfig = (
  questionKey: EnumMatchQuestionKey
): TypeMatchQuestionControlConfig => {
  return getQuestion(questionKey).controlConfig;
};

export const getQuestionControlType = (
  questionKey: EnumMatchQuestionKey
): EnumMatchQuestionControlType => {
  return getQuestionControlConfig(questionKey).controlType;
};

export const getQuestionKeysForTopic = (
  topicKey: EnumMatchTopicKey
): EnumMatchQuestionKey[] => {
  return orderedMatchQuestionKeysByTopicKey[topicKey];
};

export const getTopic = (topicKey: EnumMatchTopicKey): TypeMatchTopic => {
  return matchTopicsByKey[topicKey];
};

export const getTopicKeysForCategory = (
  categoryKey: EnumMatchCategoryKey
): EnumMatchTopicKey[] => {
  return orderedMatchTopicKeysByCategoryKey[categoryKey];
};

export const isBoundQuestion = (questionKey: EnumMatchQuestionKey): boolean => {
  return (
    getQuestionControlType(questionKey) === EnumMatchQuestionControlType.BOUND
  );
};

export const isBranchQuestion = (
  questionKey: EnumMatchQuestionKey
): boolean => {
  return (
    getQuestionControlType(questionKey) === EnumMatchQuestionControlType.BRANCH
  );
};

export const isMetaQuestion = (questionKey: EnumMatchQuestionKey): boolean => {
  const { placeDataKey } = getQuestion(questionKey);
  return !placeDataKey;
};
