import { breakpointKeys } from 'util/theme/constants';
import {
  EnumSemanticBreakpointKey,
  EnumThemeMode,
  TypeBreakpointKey,
} from 'util/theme/types';

export function getClosestBreakpointValue<T = any>(
  valuesByBreakpointKey: Partial<Record<TypeBreakpointKey, T>>,
  breakpointKey: TypeBreakpointKey
): T | undefined {
  let index = Object.keys(valuesByBreakpointKey).indexOf(breakpointKey);

  // Found the breakpoint key in the provided values
  if (index >= 0) {
    return valuesByBreakpointKey[breakpointKey];
  }

  let stopIndex = breakpointKeys.indexOf(breakpointKey);
  while (stopIndex >= 0) {
    const key = breakpointKeys[stopIndex];

    if (valuesByBreakpointKey.hasOwnProperty(key)) {
      index = stopIndex;
      break;
    }

    stopIndex -= 1;
  }

  if (index >= 0) {
    const key = breakpointKeys[index];
    return valuesByBreakpointKey[key];
  }

  return undefined;
}

export const isDarkThemeMode = (themeMode: EnumThemeMode): boolean => {
  return themeMode === EnumThemeMode.DARK;
};

export const isDesktopScreenSize = (
  semanticBreakpointKey: EnumSemanticBreakpointKey
): boolean => {
  return semanticBreakpointKey === EnumSemanticBreakpointKey.DESKTOP;
};

export const isMobileScreenSize = (
  semanticBreakpointKey: EnumSemanticBreakpointKey
): boolean => {
  return semanticBreakpointKey === EnumSemanticBreakpointKey.MOBILE;
};

export const isTabletScreenSize = (
  semanticBreakpointKey: EnumSemanticBreakpointKey
): boolean => {
  return semanticBreakpointKey === EnumSemanticBreakpointKey.TABLET;
};

export const isThemeMode = (maybeThemeMode?: string): boolean => {
  return !!maybeThemeMode && maybeThemeMode in EnumThemeMode;
};
