import { createTheme, ThemeOptions } from '@mui/material';
import { EnumSemanticBreakpointKey, TypeBreakpointKey } from 'util/theme/types';
import { EnumSizeKey } from 'util/layout/constants';

export const breakpointKeys: TypeBreakpointKey[] = [
  EnumSizeKey.XS,
  EnumSizeKey.SM,
  EnumSizeKey.MD,
  EnumSizeKey.LG,
  EnumSizeKey.XL,
];

export const semanticBreakpointKeyMap: Partial<
  Record<TypeBreakpointKey, EnumSemanticBreakpointKey>
> = {
  xs: EnumSemanticBreakpointKey.MOBILE,
  md: EnumSemanticBreakpointKey.TABLET,
  lg: EnumSemanticBreakpointKey.DESKTOP,
};

export const breakpointWidthsByKey: Record<TypeBreakpointKey, number> = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const darkPalette = {
  primary: {
    main: '#f3a5a5',
  },
  facebook: {
    main: '#2374e1',
    contrastText: '#fff',
  },
  google: {
    main: '#15c',
    contrastText: '#fff',
  },
  twitter: {
    main: '#1d9bf0',
    contrastText: '#fff',
  },
};

const lightPalette = {
  primary: {
    main: '#e53838',
  },
  facebook: {
    main: '#1b74e4',
    contrastText: '#fff',
  },
  google: {
    main: '#1a73e8',
    contrastText: '#fff',
  },
  twitter: {
    main: '#1d9bf0',
    contrastText: '#fff',
  },
};

const themeOptions: ThemeOptions = {
  breakpoints: {
    values: breakpointWidthsByKey,
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontWeight: 700,
      textTransform: 'none',
    },
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        elevation: 0,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'bottom',
        },
      },
    },
  },
};

export const darkTheme = createTheme({
  ...themeOptions,
  palette: { mode: 'dark', ...darkPalette },
});
export const lightTheme = createTheme({
  ...themeOptions,
  palette: { mode: 'light', ...lightPalette },
});
