import { EnumPlaceDataKey } from 'util/models/place/types';
import {
  formatCurrency,
  formatFloat,
  formatInteger,
  formatLargeInteger,
  formatPercent,
} from 'util/common/helpers/numberHelpers';
import { intl } from 'util/lang/helpers';

export type TypePlaceDataConfig = {
  key: EnumPlaceDataKey;
  formatValue: (value: number) => string;
  labelText: string;
  unitsText?: string;
};

export const placeDataConfigByPlaceDataKey: Record<
  EnumPlaceDataKey,
  TypePlaceDataConfig
> = {
  [EnumPlaceDataKey.ANNUAL_INCOME_PER_CAPITA]: {
    key: EnumPlaceDataKey.ANNUAL_INCOME_PER_CAPITA,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Per capita income',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per person' }),
  },
  [EnumPlaceDataKey.GINI_INDEX]: {
    key: EnumPlaceDataKey.GINI_INDEX,
    formatValue: formatFloat,
    labelText: intl.formatMessage({ defaultMessage: 'Gini index' }),
  },
  [EnumPlaceDataKey.INCOME_DEFICIT_PER_CAPITA]: {
    key: EnumPlaceDataKey.INCOME_DEFICIT_PER_CAPITA,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Income deficit',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per person' }),
  },
  [EnumPlaceDataKey.MEAN_WEEKLY_HOURS_WORKED]: {
    key: EnumPlaceDataKey.MEAN_WEEKLY_HOURS_WORKED,
    formatValue: (value) => formatFloat(value, { roundedToInteger: true }),
    labelText: intl.formatMessage({
      defaultMessage: 'Work week',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Hours per week' }),
  },
  [EnumPlaceDataKey.MEDIAN_AGE]: {
    key: EnumPlaceDataKey.MEDIAN_AGE,
    formatValue: (value) => formatFloat(value, { roundedToInteger: true }),
    labelText: intl.formatMessage({
      defaultMessage: 'Average age',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Years old' }),
  },
  [EnumPlaceDataKey.MEDIAN_ANNUAL_EARNINGS]: {
    key: EnumPlaceDataKey.MEDIAN_ANNUAL_EARNINGS,
    formatValue: (value) => formatFloat(value, { roundedToInteger: true }),
    labelText: intl.formatMessage({
      defaultMessage: 'Work week',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Hours per week' }),
  },
  [EnumPlaceDataKey.MEDIAN_ANNUAL_INCOME]: {
    key: EnumPlaceDataKey.MEDIAN_ANNUAL_INCOME,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average income',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per year' }),
  },
  [EnumPlaceDataKey.MEDIAN_GROSS_RENT_FOUR_BEDROOM]: {
    key: EnumPlaceDataKey.MEDIAN_GROSS_RENT_FOUR_BEDROOM,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average four bedroom rent',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per month' }),
  },
  [EnumPlaceDataKey.MEDIAN_GROSS_RENT_ONE_BEDROOM]: {
    key: EnumPlaceDataKey.MEDIAN_GROSS_RENT_ONE_BEDROOM,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average one bedroom rent',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per month' }),
  },
  [EnumPlaceDataKey.MEDIAN_GROSS_RENT_STUDIO]: {
    key: EnumPlaceDataKey.MEDIAN_GROSS_RENT_STUDIO,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average studio rent',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per month' }),
  },
  [EnumPlaceDataKey.MEDIAN_GROSS_RENT_THREE_BEDROOM]: {
    key: EnumPlaceDataKey.MEDIAN_GROSS_RENT_THREE_BEDROOM,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average three bedroom rent',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per month' }),
  },
  [EnumPlaceDataKey.MEDIAN_GROSS_RENT_TWO_BEDROOM]: {
    key: EnumPlaceDataKey.MEDIAN_GROSS_RENT_TWO_BEDROOM,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average two bedroom rent',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per month' }),
  },
  [EnumPlaceDataKey.MEDIAN_HOUSING_VALUE]: {
    key: EnumPlaceDataKey.MEDIAN_HOUSING_VALUE,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Average house value',
    }),
  },
  [EnumPlaceDataKey.MEDIAN_HOUSING_YEAR_BUILT]: {
    key: EnumPlaceDataKey.MEDIAN_HOUSING_YEAR_BUILT,
    formatValue: (value) => value.toString(),
    labelText: intl.formatMessage({
      defaultMessage: 'Average year built for all housing',
    }),
  },
  [EnumPlaceDataKey.MEDIAN_OWNER_OCCUPIED_HOUSING_YEAR_BUILT]: {
    key: EnumPlaceDataKey.MEDIAN_OWNER_OCCUPIED_HOUSING_YEAR_BUILT,
    formatValue: (value) => value.toString(),
    labelText: intl.formatMessage({
      defaultMessage: 'Average year built for houses',
    }),
  },
  [EnumPlaceDataKey.MEDIAN_RENTER_OCCUPIED_HOUSING_YEAR_BUILT]: {
    key: EnumPlaceDataKey.MEDIAN_RENTER_OCCUPIED_HOUSING_YEAR_BUILT,
    formatValue: (value) => value.toString(),
    labelText: intl.formatMessage({
      defaultMessage: 'Average year built for apartments',
    }),
  },
  [EnumPlaceDataKey.MODE_UPPER_BOUND_COMMUTE_MINUTES]: {
    key: EnumPlaceDataKey.MODE_UPPER_BOUND_COMMUTE_MINUTES,
    formatValue: formatInteger,
    labelText: intl.formatMessage({ defaultMessage: 'Typical commute' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Minutes' }),
  },
  [EnumPlaceDataKey.MODE_UPPER_BOUND_HOUSEHOLD_INCOME]: {
    key: EnumPlaceDataKey.MODE_UPPER_BOUND_HOUSEHOLD_INCOME,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({ defaultMessage: 'Typical family income' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per year' }),
  },
  [EnumPlaceDataKey.MODE_UPPER_BOUND_NON_FAMILY_HOUSEHOLD_INCOME]: {
    key: EnumPlaceDataKey.MODE_UPPER_BOUND_NON_FAMILY_HOUSEHOLD_INCOME,
    formatValue: formatCurrency,
    labelText: intl.formatMessage({
      defaultMessage: 'Typical non-family household income',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Per year' }),
  },
  [EnumPlaceDataKey.PERCENT_ASSOCIATES_DEGREE]: {
    key: EnumPlaceDataKey.PERCENT_ASSOCIATES_DEGREE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Associates degree graduation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BABY_BOOMER]: {
    key: EnumPlaceDataKey.PERCENT_BABY_BOOMER,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Baby Boomers' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Baby boomers' }),
  },
  [EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE]: {
    key: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Bachelors degree graduation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_ARTS_AND_HUMANITIES]: {
    key: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_ARTS_AND_HUMANITIES,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Arts and humanities degrees',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_BUSINESS]: {
    key: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_BUSINESS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Business degrees',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_EDUCATION]: {
    key: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_EDUCATION,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Education degrees',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_SCIENCE_AND_ENGINEERING]: {
    key: EnumPlaceDataKey.PERCENT_BACHELORS_DEGREE_SCIENCE_AND_ENGINEERING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Science and Engineering degrees',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_BIKE_TO_WORK]: {
    key: EnumPlaceDataKey.PERCENT_BIKE_TO_WORK,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Biking commuters' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bikers' }),
  },
  [EnumPlaceDataKey.PERCENT_DOCTORATE_DEGREE]: {
    key: EnumPlaceDataKey.PERCENT_DOCTORATE_DEGREE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Doctorate degree graduation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_DRIVE_TO_WORK]: {
    key: EnumPlaceDataKey.PERCENT_DRIVE_TO_WORK,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Driving commuters' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Drivers' }),
  },
  [EnumPlaceDataKey.PERCENT_FAMILY_HOUSEHOLDS]: {
    key: EnumPlaceDataKey.PERCENT_FAMILY_HOUSEHOLDS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Family households' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Families' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_BABY_BOOMER]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_BABY_BOOMER,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Baby Boomer women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_DIVORCED]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_DIVORCED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Divorced women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_GEN_ALPHA]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_GEN_ALPHA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Alpha girls' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Girls' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_GEN_X]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_GEN_X,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen X women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_GEN_Y]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_GEN_Y,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Y women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_GEN_Z]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_GEN_Z,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Z women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Young women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_NEVER_MARRIED]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_NEVER_MARRIED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Unmarried women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_NOW_MARRIED]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_NOW_MARRIED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Married women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_SENIOR]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_SENIOR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Senior women' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FEMALE_SILENT_GENERATION]: {
    key: EnumPlaceDataKey.PERCENT_FEMALE_SILENT_GENERATION,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Silent Generation women',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AFGHANISTAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AFGHANISTAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Afghanistan-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Afghans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ALBANIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ALBANIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Albania-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Albanians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ARGENTINA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ARGENTINA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Argentina-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Argentines' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ARMENIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ARMENIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Armenia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Armenians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AUSTRALIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AUSTRALIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Australia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Australians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AUSTRIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_AUSTRIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Austria-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Austrians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BAHAMAS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BAHAMAS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Bahamas-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bahamians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BANGLADESH]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BANGLADESH,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Bangladesh-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bangladeshis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BARBADOS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BARBADOS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Barbados-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Barbadians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELARUS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELARUS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Belarus-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Belarusians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELGIUM]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELGIUM,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Belgium-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Belgians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELIZE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BELIZE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Belize-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Belizeans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BOLIVIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BOLIVIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Bolivia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bolivians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BOSNIA_AND_HERZEGOVINA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BOSNIA_AND_HERZEGOVINA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Bosnia and Herzegovina-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bosnians/Herzegovians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BRAZIL]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BRAZIL,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Brazil-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Brazilians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BULGARIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BULGARIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Bulgaria-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bulgarians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BURMA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_BURMA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Burma-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Bermese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CABO_VERDE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CABO_VERDE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Cape Verde-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Cape Verdeans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CAMBODIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CAMBODIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Cambodia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Cambodians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CAMEROON]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CAMEROON,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Cameroon-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Cameroonians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CANADA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CANADA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Canada-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Canadians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CHILE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CHILE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Chile-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Chileans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CHINA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CHINA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'China-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Chinese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_COLOMBIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_COLOMBIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Colombia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Colombians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CONGO]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CONGO,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Congo-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Congolese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_COSTA_RICA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_COSTA_RICA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Costa Rica-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Costa Ricans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CROATIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CROATIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Croatia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Croatians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CUBA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CUBA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Cuba-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Cubans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CZECH_REPUBLIC_OR_SLOVAKIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_CZECH_REPUBLIC_OR_SLOVAKIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Czechia- and Slovakia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Czechs and Slovaks' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DEMOCRATIC_REPUBLIC_OF_CONGO_ZAIRE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DEMOCRATIC_REPUBLIC_OF_CONGO_ZAIRE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Democratic Republic of Congo-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Congolese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DENMARK]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DENMARK,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Denmark-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Danish' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DOMINICA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DOMINICA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Dominica-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Dominican' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DOMINICAN_REPUBLIC]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_DOMINICAN_REPUBLIC,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Dominican Republic-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Dominican' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ECUADOR]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ECUADOR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Ecuador-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Ecuadorians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_EGYPT]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_EGYPT,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Egypt-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Egyptians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_EL_SALVADOR]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_EL_SALVADOR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'El Salvador-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Salvadorans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ENGLAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ENGLAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'England-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'English' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ERITREA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ERITREA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Eritrea-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Eritreans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ETHIOPIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ETHIOPIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Ethiopia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Ethiopians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_FIJI]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_FIJI,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Fiji-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Fijians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_FRANCE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_FRANCE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'France-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'French' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GERMANY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GERMANY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Germany-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Germans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GHANA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GHANA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Ghana-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Ghanaians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GREECE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GREECE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Greece-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Greeks' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GRENADA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GRENADA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Grenana-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Grenandians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GUATEMALA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GUATEMALA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Guatemala-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Guatemalans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GUYANA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_GUYANA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Guyana-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Guyanese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HAITI]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HAITI,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Haiti-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Haitians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HONDURAS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HONDURAS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Honduras-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Hondurans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HONG_KONG]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HONG_KONG,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Hong Kong-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Hongkongers' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HUNGARY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_HUNGARY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Hungary-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Hungarians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_INDIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_INDIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'India-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Indians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_INDONESIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_INDONESIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Indonesia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Indonesians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Iran-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Iranians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRAQ]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRAQ,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Iraq-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Iraqis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRELAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_IRELAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Ireland-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Irish' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ISRAEL]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ISRAEL,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Israel-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Israelis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ITALY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ITALY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Italy-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Italians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JAMAICA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JAMAICA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Jamaica-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Jamaicans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JAPAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JAPAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Japan-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Japanese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JORDAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_JORDAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Jordan-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Jordanians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KAZAKHSTAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KAZAKHSTAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Kazakhstan-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Kazakhs' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KENYA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KENYA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Kenya-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Kenyans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KOREA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KOREA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Korea-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Koreans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KUWAIT]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_KUWAIT,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Kuwait-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Kuwaitis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LAOS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LAOS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Laos-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Loatians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LATVIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LATVIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Latvia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Latvians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LEBANON]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LEBANON,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Lebanon-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Lebanese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LIBERIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LIBERIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Liberia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Liberians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LITHUANIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_LITHUANIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Lithuania-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Lithuanians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MALAYSIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MALAYSIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Malaysia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Malaysians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MEXICO]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MEXICO,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Mexico-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Mexicans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MICRONESIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MICRONESIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Micronesia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Micronesians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MOLDOVA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MOLDOVA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Moldova-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Moldovans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MOROCCO]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_MOROCCO,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Morocco-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Moroccans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NEPAL]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NEPAL,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Nepal-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Nepalese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NETHERLANDS]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NETHERLANDS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Netherlands-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Dutch' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NICARAGUA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NICARAGUA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Nicaragua-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Nicaraguans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NIGERIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NIGERIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Nigeria-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Nigerians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NORTH_MACEDONIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NORTH_MACEDONIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'North Macedonia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Macedonians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NORWAY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_NORWAY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Norway-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Norwegians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PAKISTAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PAKISTAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Pakistan-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Pakistanis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PANAMA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PANAMA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Panama-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Panamanians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PERU]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PERU,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Peru-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Peruvians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PHILIPPINES]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PHILIPPINES,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Philippines-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Filipinos' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_POLAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_POLAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Poland-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Poles' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PORTUGAL]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_PORTUGAL,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Portugal-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Portuguese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ROMANIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ROMANIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Romania-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Romanians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_RUSSIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_RUSSIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Russia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Russians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SAUDI_ARABIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SAUDI_ARABIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Saudi Arabia-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Saudi Arabians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SCOTLAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SCOTLAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Scotland-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Scots' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SENEGAL]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SENEGAL,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Senegal-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Senegalese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SERBIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SERBIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Serbia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Serbians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SIERRA_LEONE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SIERRA_LEONE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Sierra Leone-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Sierra Leoneans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SINGAPORE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SINGAPORE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Singapore-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Singaporeans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SOMALIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SOMALIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Somalia-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Somalians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SOUTH_AFRICA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SOUTH_AFRICA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'South Africa-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'South African' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SPAIN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SPAIN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Spain-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Spanish' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SRI_LANKA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SRI_LANKA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Sri Lanka-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Sri Lankans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ST_VINCENT_AND_THE_GRENADINES]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ST_VINCENT_AND_THE_GRENADINES,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'St. Vincent and the Grenadines-born residents',
    }),
    unitsText: intl.formatMessage({
      defaultMessage: 'Vincentian and Grenadinians',
    }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SUDAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SUDAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Sudan-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Sudanese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SWEDEN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SWEDEN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Sweden-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Swedes' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SWITZERLAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SWITZERLAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Switzerland-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Swiss' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SYRIA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_SYRIA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Syria-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Syrians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TAIWAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TAIWAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Taiwan-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Taiwanese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_THAILAND]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_THAILAND,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Thailand-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Thai' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TRINIDAD_AND_TOBAGO]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TRINIDAD_AND_TOBAGO,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Trinidad and Tobago-born residents',
    }),
    unitsText: intl.formatMessage({
      defaultMessage: 'Trinidadian and Tobagonians',
    }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TURKEY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_TURKEY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Turkey-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Turks' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UGANDA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UGANDA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Uganda-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Ugandans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UKRAINE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UKRAINE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Ukraine-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Ukranians' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_URUGUAY]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_URUGUAY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Uruguay-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Uruguayans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UZBEKISTAN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_UZBEKISTAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Uzbekistan-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Uzbeks' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_VENEZUELA]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_VENEZUELA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Venezuela-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Venezuelans' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_VIETNAM]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_VIETNAM,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Vietnam-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Vietnamese' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_YEMEN]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_YEMEN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Yemen-born residents' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Yemenis' }),
  },
  [EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ZIMBABWE]: {
    key: EnumPlaceDataKey.PERCENT_FOREIGN_BORN_ZIMBABWE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Zimbabwe-born residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Zimbabweans' }),
  },
  [EnumPlaceDataKey.PERCENT_GEN_ALPHA]: {
    key: EnumPlaceDataKey.PERCENT_GEN_ALPHA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Alpha' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Gen Alpha kids' }),
  },
  [EnumPlaceDataKey.PERCENT_GEN_X]: {
    key: EnumPlaceDataKey.PERCENT_GEN_X,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen X' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Xers' }),
  },
  [EnumPlaceDataKey.PERCENT_GEN_Y]: {
    key: EnumPlaceDataKey.PERCENT_GEN_Y,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Y' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Millennials' }),
  },
  [EnumPlaceDataKey.PERCENT_GEN_Z]: {
    key: EnumPlaceDataKey.PERCENT_GEN_Z,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Z' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Zoomers' }),
  },
  [EnumPlaceDataKey.PERCENT_GRADUATE_COLLEGE_STUDENTS]: {
    key: EnumPlaceDataKey.PERCENT_GRADUATE_COLLEGE_STUDENTS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Grad school' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Students' }),
  },
  [EnumPlaceDataKey.PERCENT_HIGH_SCHOOL_DIPLOMA]: {
    key: EnumPlaceDataKey.PERCENT_HIGH_SCHOOL_DIPLOMA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'High school graduation' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_IMPOVERISHED]: {
    key: EnumPlaceDataKey.PERCENT_IMPOVERISHED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Poverty' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Impoverished' }),
  },
  [EnumPlaceDataKey.PERCENT_INCOME_INTEREST_DIVIDENDS_AND_RENTAL_INCOME]: {
    key: EnumPlaceDataKey.PERCENT_INCOME_INTEREST_DIVIDENDS_AND_RENTAL_INCOME,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Investment income' }),
  },
  [EnumPlaceDataKey.PERCENT_INCOME_SELF_EMPLOYMENT]: {
    key: EnumPlaceDataKey.PERCENT_INCOME_SELF_EMPLOYMENT,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Self-employment income' }),
  },
  [EnumPlaceDataKey.PERCENT_INCOME_WAGES]: {
    key: EnumPlaceDataKey.PERCENT_INCOME_WAGES,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Wage income' }),
  },
  [EnumPlaceDataKey.PERCENT_INTEREST_DIVIDENDS_AND_RENTAL_INCOME]: {
    key: EnumPlaceDataKey.PERCENT_INTEREST_DIVIDENDS_AND_RENTAL_INCOME,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Investment market' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Of income' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE]: {
    key: EnumPlaceDataKey.PERCENT_MALE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_BABY_BOOMER]: {
    key: EnumPlaceDataKey.PERCENT_MALE_BABY_BOOMER,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Baby Boomer men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_DIVORCED]: {
    key: EnumPlaceDataKey.PERCENT_MALE_DIVORCED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Divorced men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_GEN_ALPHA]: {
    key: EnumPlaceDataKey.PERCENT_MALE_GEN_ALPHA,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Alpha boys' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Boys' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_GEN_X]: {
    key: EnumPlaceDataKey.PERCENT_MALE_GEN_X,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen X men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_GEN_Y]: {
    key: EnumPlaceDataKey.PERCENT_MALE_GEN_Y,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Y men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_GEN_Z]: {
    key: EnumPlaceDataKey.PERCENT_MALE_GEN_Z,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Gen Z men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Young men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_NEVER_MARRIED]: {
    key: EnumPlaceDataKey.PERCENT_MALE_NEVER_MARRIED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Unmarried men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_NOW_MARRIED]: {
    key: EnumPlaceDataKey.PERCENT_MALE_NOW_MARRIED,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Married men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_SENIOR]: {
    key: EnumPlaceDataKey.PERCENT_MALE_SENIOR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Senior men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MALE_SILENT_GENERATION]: {
    key: EnumPlaceDataKey.PERCENT_MALE_SILENT_GENERATION,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Silent Generation men' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Men' }),
  },
  [EnumPlaceDataKey.PERCENT_MASTERS_DEGREE]: {
    key: EnumPlaceDataKey.PERCENT_MASTERS_DEGREE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Masters degree graduation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_MEDIAN_GROSS_RENT_OF_HOUSEHOLD_INCOME]: {
    key: EnumPlaceDataKey.PERCENT_MEDIAN_GROSS_RENT_OF_HOUSEHOLD_INCOME,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Rent vs. income',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Of income' }),
  },
  [EnumPlaceDataKey.PERCENT_MOVED_FROM_OUT_OF_COUNTY]: {
    key: EnumPlaceDataKey.PERCENT_MOVED_FROM_OUT_OF_COUNTY,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'New residents',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'New residents' }),
  },
  [EnumPlaceDataKey.PERCENT_NEW_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_NEW_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'New housing',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'New buildings' }),
  },
  [EnumPlaceDataKey.PERCENT_NEW_OWNER_OCCUPIED_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_NEW_OWNER_OCCUPIED_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'New houses',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'New houses' }),
  },
  [EnumPlaceDataKey.PERCENT_NEW_RENTER_OCCUPIED_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_NEW_RENTER_OCCUPIED_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'New apartments',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'New buildings' }),
  },
  [EnumPlaceDataKey.PERCENT_OLD_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_OLD_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Old housing',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Old buildings' }),
  },
  [EnumPlaceDataKey.PERCENT_OLD_OWNER_OCCUPIED_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_OLD_OWNER_OCCUPIED_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Old houses',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Old houses' }),
  },
  [EnumPlaceDataKey.PERCENT_OLD_RENTER_OCCUPIED_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_OLD_RENTER_OCCUPIED_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Old apartments',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Old buildings' }),
  },
  [EnumPlaceDataKey.PERCENT_OWNER_OCCUPIED_HOUSING]: {
    key: EnumPlaceDataKey.PERCENT_OWNER_OCCUPIED_HOUSING,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Houses',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Houses' }),
  },
  [EnumPlaceDataKey.PERCENT_PROFESSIONAL_DEGREE]: {
    key: EnumPlaceDataKey.PERCENT_PROFESSIONAL_DEGREE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Professional degree graduation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Graduates' }),
  },
  [EnumPlaceDataKey.PERCENT_PUBLIC_TRANSIT_TO_WORK]: {
    key: EnumPlaceDataKey.PERCENT_PUBLIC_TRANSIT_TO_WORK,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Public transit commuters',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Transit riders' }),
  },
  [EnumPlaceDataKey.PERCENT_SELF_EMPLOYED_WITH_INCOME]: {
    key: EnumPlaceDataKey.PERCENT_SELF_EMPLOYED_WITH_INCOME,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Profitable entrepreneurs',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Entrepreneurs' }),
  },
  [EnumPlaceDataKey.PERCENT_SENIOR]: {
    key: EnumPlaceDataKey.PERCENT_SENIOR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Seniors',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Seniors' }),
  },
  [EnumPlaceDataKey.PERCENT_SILENT_GENERATION]: {
    key: EnumPlaceDataKey.PERCENT_SILENT_GENERATION,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Silent Generation',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Silents' }),
  },
  [EnumPlaceDataKey.PERCENT_SOME_COLLEGE]: {
    key: EnumPlaceDataKey.PERCENT_SOME_COLLEGE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'College experience',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Some college' }),
  },
  [EnumPlaceDataKey.PERCENT_UNDERGRADUATE_COLLEGE_STUDENTS]: {
    key: EnumPlaceDataKey.PERCENT_UNDERGRADUATE_COLLEGE_STUDENTS,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Undergrad students',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Students' }),
  },
  [EnumPlaceDataKey.PERCENT_VACANT_HOUSING_FOR_RENT]: {
    key: EnumPlaceDataKey.PERCENT_VACANT_HOUSING_FOR_RENT,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Rental market',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Vacant' }),
  },
  [EnumPlaceDataKey.PERCENT_VACANT_HOUSING_FOR_SALE]: {
    key: EnumPlaceDataKey.PERCENT_VACANT_HOUSING_FOR_SALE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'House market',
    }),
    unitsText: intl.formatMessage({ defaultMessage: 'Vacant' }),
  },
  [EnumPlaceDataKey.PERCENT_VACANT_HOUSING_RESIDENCE_ELSEWHERE]: {
    key: EnumPlaceDataKey.PERCENT_VACANT_HOUSING_RESIDENCE_ELSEWHERE,
    formatValue: formatPercent,
    labelText: intl.formatMessage({
      defaultMessage: 'Vacation homes',
    }),
  },
  [EnumPlaceDataKey.PERCENT_VETERAN]: {
    key: EnumPlaceDataKey.PERCENT_VETERAN,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Veterans' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Veterans' }),
  },
  [EnumPlaceDataKey.PERCENT_WALK_TO_WORK]: {
    key: EnumPlaceDataKey.PERCENT_WALK_TO_WORK,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Walking commuters' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Walkers' }),
  },
  [EnumPlaceDataKey.PERCENT_WOMEN_WITH_BIRTH_IN_PAST_YEAR]: {
    key: EnumPlaceDataKey.PERCENT_WOMEN_WITH_BIRTH_IN_PAST_YEAR,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'New mothers' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Women' }),
  },
  [EnumPlaceDataKey.PERCENT_WORK_FROM_HOME]: {
    key: EnumPlaceDataKey.PERCENT_WORK_FROM_HOME,
    formatValue: formatPercent,
    labelText: intl.formatMessage({ defaultMessage: 'Work from home' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Remote workers' }),
  },
  [EnumPlaceDataKey.POPULATION]: {
    key: EnumPlaceDataKey.POPULATION,
    formatValue: formatLargeInteger,
    labelText: intl.formatMessage({ defaultMessage: 'Population' }),
    unitsText: intl.formatMessage({ defaultMessage: 'Residents' }),
  },
};
