import {
  EnumMatchCategoryKey,
  TypeMatchCategory,
} from 'util/match/types/categoryTypes';
import { intl } from 'util/lang/helpers';
import {
  RiBriefcaseLine,
  RiCarLine,
  RiGroupLine,
  RiHome4Line,
  RiStore2Line,
  RiUserHeartLine,
  RiUserSearchLine,
  RiUserSharedLine,
} from 'react-icons/ri';

export const matchCategoriesByKey: {
  [key in EnumMatchCategoryKey]: TypeMatchCategory;
} = {
  [EnumMatchCategoryKey.AGE]: {
    key: EnumMatchCategoryKey.AGE,
    icon: <RiUserSharedLine />,
    name: intl.formatMessage({ defaultMessage: 'Age' }),
  },
  [EnumMatchCategoryKey.DEMOGRAPHICS]: {
    key: EnumMatchCategoryKey.DEMOGRAPHICS,
    icon: <RiUserSearchLine />,
    name: intl.formatMessage({ defaultMessage: 'Demographics' }),
  },
  [EnumMatchCategoryKey.ECONOMY]: {
    key: EnumMatchCategoryKey.ECONOMY,
    icon: <RiStore2Line />,
    name: intl.formatMessage({ defaultMessage: 'Economy' }),
  },
  [EnumMatchCategoryKey.HOUSING]: {
    key: EnumMatchCategoryKey.HOUSING,
    icon: <RiHome4Line />,
    name: intl.formatMessage({ defaultMessage: 'Housing' }),
  },
  [EnumMatchCategoryKey.JOBS]: {
    key: EnumMatchCategoryKey.JOBS,
    icon: <RiBriefcaseLine />,
    name: intl.formatMessage({ defaultMessage: 'Jobs' }),
  },
  [EnumMatchCategoryKey.POPULATION]: {
    key: EnumMatchCategoryKey.POPULATION,
    icon: <RiGroupLine />,
    name: intl.formatMessage({ defaultMessage: 'Population' }),
  },
  [EnumMatchCategoryKey.RELATIONSHIPS]: {
    key: EnumMatchCategoryKey.RELATIONSHIPS,
    icon: <RiUserHeartLine />,
    name: intl.formatMessage({ defaultMessage: 'Relationships' }),
  },
  [EnumMatchCategoryKey.TRANSPORTATION]: {
    key: EnumMatchCategoryKey.TRANSPORTATION,
    icon: <RiCarLine />,
    name: intl.formatMessage({ defaultMessage: 'Transportation' }),
  },
};

export const orderedMatchCategoryKeys: EnumMatchCategoryKey[] = [
  EnumMatchCategoryKey.POPULATION,
  EnumMatchCategoryKey.AGE,
  EnumMatchCategoryKey.DEMOGRAPHICS,
  EnumMatchCategoryKey.HOUSING,
  EnumMatchCategoryKey.ECONOMY,
  EnumMatchCategoryKey.JOBS,
  EnumMatchCategoryKey.RELATIONSHIPS,
  EnumMatchCategoryKey.TRANSPORTATION,
];
