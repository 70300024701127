import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { TypeTypographyVariant } from 'util/theme/types';
import { useBreakpointValue } from 'util/theme/hooks';

export type TypeHeaderTextProps = {
  loadingSubtitle?: boolean;
  loadingTitle?: boolean;
  subtitle?: ReactNode;
  subtitleTextColor?: string;
  titleText: string;
  titleTextColor?: string;
};

export default function HeaderText(props: TypeHeaderTextProps) {
  const {
    loadingSubtitle = false,
    loadingTitle = false,
    subtitle,
    subtitleTextColor = 'text.secondary',
    titleText,
    titleTextColor = 'text.primary',
  } = props;

  const subtitleVariant: TypeTypographyVariant = useBreakpointValue({
    xs: 'subtitle1',
    md: 'h6',
  });
  const titleVariant: TypeTypographyVariant = useBreakpointValue({
    xs: 'h4',
    md: 'h3',
  });

  return (
    <Stack>
      {loadingTitle ? (
        <Skeleton height={50} variant='rounded' width={350} />
      ) : (
        <Typography
          color={titleTextColor}
          component='h1'
          variant={titleVariant}
        >
          {titleText}
        </Typography>
      )}

      {subtitle && (
        <Box>
          {loadingSubtitle ? (
            <Skeleton
              height={20}
              sx={{ marginTop: 1 }}
              variant='rounded'
              width={200}
            />
          ) : (
            <>
              {typeof subtitle == 'string' ? (
                <Typography
                  color={subtitleTextColor}
                  component='span'
                  ml={2}
                  variant={subtitleVariant}
                >
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}
