import numeral from 'numeral';

export const clamp = (value: number, min: number, max: number): number => {
  return Math.max(min, Math.min(max, value));
};

export const formatCurrency = (value: number | string): string => {
  return format(value, '$0,0[.]00');
};

export type TypeFormatFloatOptions = {
  roundedToInteger?: boolean;
};

export const formatFloat = (
  value: number | string,
  options?: TypeFormatFloatOptions
): string => {
  const { roundedToInteger = false } = options ?? {};
  return format(value, roundedToInteger ? '0.[0]' : '0.00');
};

export type TypeFormatIntegerOptions = {
  separated?: boolean;
};

export const formatInteger = (
  value: number | string,
  options?: TypeFormatIntegerOptions
): string => {
  const { separated = true } = options ?? {};
  return format(value, separated ? '0,0' : '0');
};

export const formatLargeInteger = (value: number | string): string => {
  return format(value, '0[.]0a');
};

// Expects the value to be a percentage of 1 (e.g. 0.45 for 45%, not 45)
export const formatPercent = (value: number | string): string => {
  return format(value, '0[.]0%');
};

const format = (value: number | string, formatString: string): string => {
  if (value == null) {
    return '';
  }

  return numeral(value).format(formatString);
};
