import { Chip } from '@mui/material';
import { EnumSizeKey } from 'util/layout/constants';
import { formatPercent } from 'util/common/helpers/numberHelpers';
import { useIntl } from 'react-intl';

export type TypeMatchPercentChipSize = EnumSizeKey.SM | EnumSizeKey.MD;

export type TypeMatchPercentChipProps = {
  percent?: number;
  size?: TypeMatchPercentChipSize;
};

export default function MatchPercentChip(props: TypeMatchPercentChipProps) {
  const { percent, size = EnumSizeKey.MD } = props;
  const { formatMessage } = useIntl();

  if (percent == null) {
    return null;
  }

  return (
    <Chip
      color={getMuiColor(percent)}
      label={formatMessage(
        { defaultMessage: '{percent} match' },
        { percent: formatPercent(percent) }
      )}
      size={getMuiSize(size)}
      sx={{
        fontWeight: 'bold',
      }}
    />
  );
}

const getMuiColor = (percent: number) => {
  if (percent < 0.5) {
    return 'error';
  } else if (percent < 0.8) {
    return 'warning';
  } else {
    return 'success';
  }
};

const getMuiSize = (size: TypeMatchPercentChipSize) => {
  switch (size) {
    case EnumSizeKey.SM:
      return 'small';
    default:
      return 'medium';
  }
};
