export const isDeepEqual = (a: any, b: any): boolean => {
  if (isNil(a) || isNil(b) || a instanceof Function || a instanceof RegExp) {
    return a === b;
  } else if (a.constructor !== b.constructor) {
    return false;
  } else if (a === b || a.valueOf() === b.valueOf()) {
    return true;
  } else if (
    (Array.isArray(a) && a.length !== b.length) ||
    a instanceof Date ||
    !(a instanceof Object) ||
    !(b instanceof Object)
  ) {
    return false;
  }

  const aKeys = Object.keys(a);
  return (
    Object.keys(b).every((bKey) => {
      return aKeys.indexOf(bKey) >= 0;
    }) &&
    aKeys.every((aKey) => {
      return isDeepEqual(a[aKey], b[aKey]);
    })
  );
};

export const isNil = (maybeNil: any): boolean => {
  return maybeNil === null || maybeNil === undefined;
};
