import { defaultUseQueryOptions } from 'util/common/constants/queryConstants';
import {
  getMatchPlacesQueryKey,
  getPlaceQueryKey,
  getPlaceBySlugQueryKey,
} from 'util/place/helpers/commonHelpers';
import {
  fetchMatchPlaces,
  fetchPlaceByPlaceId,
  fetchPlaceBySlug,
} from 'util/place/services';
import { TypePlace } from 'util/models/place/types';
import { TypeUseQueryOptions } from 'util/common/types/queryTypes';
import { useQuery } from '@tanstack/react-query';

export const usePlaceByPlaceIdQuery = (
  placeId: string,
  queryOptions: TypeUseQueryOptions = {}
) => {
  const { data, isLoading, ...rest } = useQuery({
    ...defaultUseQueryOptions,
    ...queryOptions,
    queryKey: getPlaceQueryKey(placeId),
    queryFn: () => fetchPlaceByPlaceId(placeId),
    select: (data: TypePlace) => data,
  });

  return {
    ...rest,
    loadingPlace: isLoading,
    place: data,
  };
};

export const usePlaceBySlugQuery = (
  slug: string,
  queryOptions: TypeUseQueryOptions = {}
) => {
  const { data, isLoading, ...rest } = useQuery({
    ...defaultUseQueryOptions,
    ...queryOptions,
    queryKey: getPlaceBySlugQueryKey(slug),
    queryFn: () => fetchPlaceBySlug(slug),
    select: (data: TypePlace) => data,
  });

  return {
    ...rest,
    loadingPlace: isLoading,
    place: data,
  };
};

export const useMatchPlacesQuery = (queryOptions: TypeUseQueryOptions = {}) => {
  const { data, isError, isLoading, ...rest } = useQuery({
    ...defaultUseQueryOptions,
    ...queryOptions,
    queryKey: getMatchPlacesQueryKey(),
    queryFn: fetchMatchPlaces,
    select: (data: TypePlace[]) => data,
  });

  return {
    ...rest,
    loadingPlaces: isLoading,
    places: data,
    placesErrored: isError,
  };
};
