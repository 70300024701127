import { createIntl, createIntlCache } from 'react-intl';
import { localesByKey, messagesByLocaleKey } from './constants';

const locale = localesByKey.EN_US;
const cache = createIntlCache();
export const intl = createIntl(
  {
    defaultLocale: localesByKey.EN_US,
    messages: messagesByLocaleKey[locale],
    locale,
  },
  cache
);

export const getBedroomsCountText = (bedroomsCount: number): string => {
  return intl.formatMessage(
    {
      defaultMessage:
        '{bedroomsCount, plural, =0 {Studio} one {# bedroom} other {# bedrooms}}',
    },
    { bedroomsCount }
  );
};
