import { EnumSidebarFormat } from 'util/layout/constants';
import { useMemo, useState } from 'react';
import { useSemanticBreakpoints } from 'util/theme/hooks';

export type TypeUseSidebarsManagerReturnValue = {
  closePrimarySidebar: () => void;
  closeSecondarySidebar: () => void;
  openPrimarySidebar: () => void;
  openSecondarySidebar: () => void;
  primarySidebarIsOpen: boolean;
  secondarySidebarIsOpen: boolean;
  sidebarFormat: EnumSidebarFormat;
};

export default function useSidebarsManager(): TypeUseSidebarsManagerReturnValue {
  const { mobile, tablet } = useSemanticBreakpoints();

  const [mobilePrimarySidebarIsOpen, setMobilePrimarySidebarIsOpen] =
    useState(false);
  const [mobileSecondarySidebarIsOpen, setMobileSecondarySidebarIsOpen] =
    useState(false);
  const [tabletPrimarySidebarIsOpen, setTabletPrimarySidebarIsOpen] =
    useState(true);
  const [tabletSecondarySidebarIsOpen, setTabletSecondarySidebarIsOpen] =
    useState(false);
  const [desktopPrimarySidebarIsOpen, setDesktopPrimarySidebarIsOpen] =
    useState(true);
  const [desktopSecondarySidebarIsOpen, setDesktopSecondarySidebarIsOpen] =
    useState(false);

  const closePrimarySidebar = () => {
    if (mobile) {
      setMobilePrimarySidebarIsOpen(false);
    } else {
      setTabletPrimarySidebarIsOpen(false);
      setDesktopPrimarySidebarIsOpen(false);
    }
  };

  const closeSecondarySidebar = () => {
    if (mobile) {
      setMobileSecondarySidebarIsOpen(false);
    } else {
      setTabletSecondarySidebarIsOpen(false);
      setDesktopSecondarySidebarIsOpen(false);
    }
  };

  const openPrimarySidebar = () => {
    if (mobile) {
      setMobilePrimarySidebarIsOpen(true);
    } else {
      setTabletPrimarySidebarIsOpen(true);
      setDesktopPrimarySidebarIsOpen(true);
    }
  };

  const openSecondarySidebar = () => {
    if (mobile) {
      setMobileSecondarySidebarIsOpen(true);
    } else {
      setTabletSecondarySidebarIsOpen(true);
      setDesktopSecondarySidebarIsOpen(true);
    }
  };

  const primarySidebarIsOpen = useMemo(() => {
    if (mobile) {
      return mobilePrimarySidebarIsOpen;
    } else if (tablet) {
      return tabletPrimarySidebarIsOpen;
    } else {
      return desktopPrimarySidebarIsOpen;
    }
  }, [
    mobile,
    tablet,
    mobilePrimarySidebarIsOpen,
    tabletPrimarySidebarIsOpen,
    desktopPrimarySidebarIsOpen,
  ]);

  const secondarySidebarIsOpen = useMemo(() => {
    if (mobile) {
      return mobileSecondarySidebarIsOpen;
    } else if (tablet) {
      return tabletSecondarySidebarIsOpen;
    } else {
      return desktopSecondarySidebarIsOpen;
    }
  }, [
    mobile,
    tablet,
    mobileSecondarySidebarIsOpen,
    tabletSecondarySidebarIsOpen,
    desktopSecondarySidebarIsOpen,
  ]);

  const sidebarFormat = useMemo(() => {
    if (mobile) {
      return EnumSidebarFormat.MODAL;
    } else if (tablet) {
      return EnumSidebarFormat.STACKED;
    } else {
      return EnumSidebarFormat.ADJACENT;
    }
  }, [mobile, tablet]);

  return {
    closePrimarySidebar,
    closeSecondarySidebar,
    openPrimarySidebar,
    openSecondarySidebar,
    primarySidebarIsOpen,
    secondarySidebarIsOpen,
    sidebarFormat,
  };
}
