import {
  Breakpoint,
  Container as MuiContainer,
  ContainerProps as TypeMuiContainerProps,
} from '@mui/material';
import {
  EnumPageContainerMaxSize,
  TypePageContainerAs,
} from 'util/layout/types';

export type TypePageContainerProps = TypeMuiContainerProps & {
  as?: TypePageContainerAs;
  maxSize?: EnumPageContainerMaxSize;
};

export default function PageContainer(props: TypePageContainerProps) {
  const {
    as = 'div',
    maxSize = EnumPageContainerMaxSize.DEFAULT,
    ...containerProps
  } = props;

  return (
    <MuiContainer
      {...containerProps}
      component={as}
      maxWidth={convertMaxSizeToWidth(maxSize)}
    />
  );
}

const convertMaxSizeToWidth = (
  maxSize: EnumPageContainerMaxSize
): false | Breakpoint => {
  switch (maxSize) {
    case EnumPageContainerMaxSize.FULL:
      return false;
    case EnumPageContainerMaxSize.LARGE:
      return 'xl';
    case EnumPageContainerMaxSize.NARROW:
      return 'md';
    case EnumPageContainerMaxSize.SMALL:
      return 'sm';
    default:
      return 'lg';
  }
};
