export enum EnumRouteKey {
  ABOUT = 'ABOUT',
  CONTACT = 'CONTACT',
  LOGIN = 'LOGIN',
  MATCH = 'MATCH',
  PLACE = 'PLACE',
  PRIVACY = 'PRIVACY',
  SIGNUP = 'SIGNUP',
  TERMS = 'TERMS',
  HOME = 'HOME',
}
