import { TypeBreakpointKey } from 'util/theme/types';

export enum EnumSidebarFormat {
  ADJACENT = 'ADJACENT',
  MODAL = 'MODAL',
  STACKED = 'STACKED',
}

export enum EnumSizeKey {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export const DEFAULT_TOAST_MS_TIMEOUT_SHORT = 2000;
export const PAGE_MAX_COLUMN_WIDTH = 12;
export const SIDEBAR_CONTENT_EDGE_SPACE = 2;

export const placeCardHeightsBySizeKey: Record<EnumSizeKey, number> = {
  [EnumSizeKey.XS]: 100,
  [EnumSizeKey.SM]: 150,
  [EnumSizeKey.MD]: 200,
  [EnumSizeKey.LG]: 300,
  [EnumSizeKey.XL]: 400,
};

export const primarySidebarColumnWidthsByBreakpointKey: Partial<
  Record<TypeBreakpointKey, number>
> = {
  xs: 0,
  md: 4,
  lg: 3,
  xl: 2,
};

export const secondarySidebarColumnWidthsByBreakpointKey: Partial<
  Record<TypeBreakpointKey, number>
> = {
  xs: 0,
  md: 0,
  lg: 3,
  xl: 2,
};
