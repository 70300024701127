import { createContext, ReactNode } from 'react';
import { TypeAuthContextValue } from 'util/auth/types';
import useAuthManager from 'util/auth/hooks/useAuthManager';

export const AuthContext = createContext<TypeAuthContextValue>({
  isAuthenticated: () => false,
  logIn: () => new Promise(() => {}),
  logInWithOAuth: () => new Promise(() => {}),
  logOut: () => new Promise(() => {}),
  resetPassword: () => new Promise(() => {}),
  signUp: () => new Promise(() => {}),
});

export type TypeAuthProviderProps = {
  children: ReactNode;
};

export default function AuthProvider(props: TypeAuthProviderProps) {
  const { children } = props;
  const value = useAuthManager();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
