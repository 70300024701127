import {
  aboutSectionsByKey,
  orderedAboutSectionKeys,
} from 'util/about/constants';
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { EnumPageContainerMaxSize } from 'util/layout/types';
import { Fragment } from 'react';
import { isDarkThemeMode } from 'util/theme/helpers';
import { useIntl } from 'react-intl';
import { useThemeMode } from 'util/theme/hooks';
import Footer from 'components/footer/Footer';
import Grid from '@mui/material/Unstable_Grid2';
import MainImage from 'assets/img/lombard_street.jpg';
import Page from 'components/ui/layout/page/Page';
import SimpleHeader from 'components/ui/layout/header/SimpleHeader';

const MAIN_IMAGE_MIN_HEIGHT = 300;

export default function AboutPage() {
  const { themeMode } = useThemeMode();
  const { formatMessage } = useIntl();

  return (
    <Page
      footer={<Footer />}
      header={
        <SimpleHeader
          subtitleText={formatMessage({
            defaultMessage:
              "When you love where you live, you'll live your best life",
          })}
          titleText={formatMessage({
            defaultMessage: 'Make home where your heart is',
          })}
        />
      }
      htmlTitle={formatMessage({ defaultMessage: 'About' })}
      maxSize={EnumPageContainerMaxSize.NARROW}
    >
      <Stack mb={6} spacing={6}>
        <Card>
          <CardMedia
            image={MainImage}
            sx={{ minHeight: MAIN_IMAGE_MIN_HEIGHT }}
          />
        </Card>
        <Typography component='h2' variant='h5'>
          {formatMessage({ defaultMessage: 'Why MapTitan?' })}
        </Typography>
        <Grid container>
          {orderedAboutSectionKeys.map((key) => {
            const section = aboutSectionsByKey[key];
            if (!section) {
              return null;
            }

            return (
              <Fragment key={key}>
                <Grid
                  display='flex'
                  justifyContent='center'
                  md={4}
                  sm={5}
                  xs={12}
                >
                  {isDarkThemeMode(themeMode)
                    ? section.darkImage
                    : section.lightImage}
                </Grid>
                <Grid
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  md={8}
                  sm={7}
                  xs={12}
                >
                  <Typography component='h3' mb={3} variant='h6'>
                    {section.titleText}
                  </Typography>
                  <Typography>{section.description}</Typography>
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
        <Divider />
        <Card variant='outlined'>
          <CardHeader title={formatMessage({ defaultMessage: 'About' })} />
          <CardContent>
            <Typography>
              {formatMessage({
                defaultMessage:
                  "Founded in 2018, MapTitan is a community that offers insights on cities, towns, and suburbs all across the United States. MapTitan leverages unique technology to empower people to live their best lives and to spread a sense of belonging. MapTitan's city database includes millions of data points to help people find the best places to live and travel based on their own individual preferences. All of this technology is brought together in a beautiful, easy-to-use website.",
              })}
            </Typography>
          </CardContent>
        </Card>
      </Stack>
    </Page>
  );
}
