export enum EnumMatchTopicKey {
  AGE = 'AGE',
  AGE_GROUPS = 'AGE_GROUPS',
  COMMUTE_METHOD = 'COMMUTE_METHOD',
  COMMUTE_TIME = 'COMMUTE_TIME',
  EDUCATIONAL_ATTAINMENT = 'EDUCATIONAL_ATTAINMENT',
  ENTREPRENEURSHIP = 'ENTREPRENEURSHIP',
  FAMILY = 'FAMILY',
  GENDER = 'GENDER',
  GENERATION = 'GENERATION',
  HOUSE_PRICE = 'HOUSE_PRICE',
  HOUSING_AGE = 'HOUSING_AGE',
  HOUSING_MARKET = 'HOUSING_MARKET',
  HOUSING_VACANCY = 'HOUSING_VACANCY',
  HOUSING_YEAR_BUILT = 'HOUSING_YEAR_BUILT',
  INCOME = 'INCOME',
  INCOME_EQUALITY = 'INCOME_EQUALITY',
  INCOME_SOURCE = 'INCOME_SOURCE',
  MARITAL_STATUS = 'MARITAL_STATUS',
  MILITARY = 'MILITARY',
  NATIONALITY = 'NATIONALITY',
  PARENTS = 'PARENTS',
  POPULATION = 'POPULATION',
  POPULATION_CHANGE = 'POPULATION_CHANGE',
  POVERTY = 'POVERTY',
  PROSPERITY = 'PROSPERITY',
  REMOTE_WORK = 'REMOTE_WORK',
  RENT = 'RENT',
  RENT_TO_INCOME = 'RENT_TO_INCOME',
  STUDENTS = 'STUDENTS',
  TENANTS = 'TENANTS',
  UNDERGRADUATE_FOCUS = 'UNDERGRADUATE_FOCUS',
  VACATION_HOUSING = 'VACATION_HOUSING',
  WORK_CULTURE = 'WORK_CULTURE',
}

export type TypeMatchTopic = {
  key: EnumMatchTopicKey;
  name: string;
};
