import {
  auth,
  facebookAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from 'util/common/constants/firebaseConstants';
import {
  AuthProvider,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { deletePrivateQueries } from 'util/common/helpers/queryHelpers';
import { EnumAuthProviderKey } from 'util/auth/constants';
import {
  TypeAuthContextValue,
  TypeLogInWithAuthProviderReturnValue,
  TypeUser,
} from 'util/auth/types';
import { useCallback, useEffect, useState } from 'react';

export default function useAuthManager(): TypeAuthContextValue {
  const [user, setUser] = useState<TypeUser>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(undefined);
      }
    });

    return () => unsubscribe();
  }, []);

  const isAuthenticated = useCallback(() => {
    return !!user;
  }, [user]);

  const logIn = async (email: string, password: string): Promise<TypeUser> => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  };

  const logInWithOAuth = (
    authProviderKey: EnumAuthProviderKey
  ): Promise<TypeLogInWithAuthProviderReturnValue> => {
    switch (authProviderKey) {
      case EnumAuthProviderKey.FACEBOOK:
        return logInWithAuthProvider(facebookAuthProvider);
      case EnumAuthProviderKey.GOOGLE:
        return logInWithAuthProvider(googleAuthProvider);
      case EnumAuthProviderKey.TWITTER:
        return logInWithAuthProvider(twitterAuthProvider);
    }
  };

  const logInWithAuthProvider = async (
    authProvider: AuthProvider
  ): Promise<TypeLogInWithAuthProviderReturnValue> => {
    try {
      const userCredential = await signInWithPopup(auth, authProvider);
      const user: TypeUser = userCredential.user;
      setUser(user);
      const { isNewUser } = getAdditionalUserInfo(userCredential) ?? {};

      return {
        fullName: user.displayName,
        userIsNew: !!isNewUser,
        user,
      };
    } catch (error) {
      throw error;
    }
  };

  const logOut = async (): Promise<void> => {
    try {
      await signOut(auth);
      deletePrivateQueries();
      setUser(undefined);
      return;
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw error;
    }
  };

  const signUp = async (email: string, password: string): Promise<TypeUser> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(userCredential.user);
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  };

  return {
    isAuthenticated,
    logIn,
    logInWithOAuth,
    logOut,
    resetPassword,
    signUp,
    user,
  };
}
