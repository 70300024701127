import {
  queryClient,
  rootQueryKeysByKey,
} from 'util/common/constants/queryConstants';

export const deletePrivateQueries = (): void => {
  queryClient.removeQueries({
    queryKey: rootQueryKeysByKey.PREFERENCES,
  });
  queryClient.removeQueries({
    queryKey: rootQueryKeysByKey.USER_DETAILS,
  });
};
