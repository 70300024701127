import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { isEndIndex } from 'util/common/helpers/arrayHelpers';
import { TypeLegalDocument } from 'util/legal/types';

export type TypeLegalDocumentProps = StackProps & {
  document: TypeLegalDocument;
};

export default function LegalDocument(props: TypeLegalDocumentProps) {
  const { document, ...stackProps } = props;

  return (
    <Stack spacing={6} {...stackProps}>
      {document.sections.map((section, index) => {
        return (
          <Card key={section.key} sx={{ width: '100%' }} variant='outlined'>
            <CardHeader title={`${index + 1}. ${section.titleText}`} />
            <CardContent>
              <Stack spacing={6}>
                {section.subsections.map((subsection) => {
                  return (
                    <Stack key={subsection.key}>
                      {subsection.titleText && (
                        <Typography component='h3' variant='h6'>
                          {subsection.titleText}
                        </Typography>
                      )}
                      {subsection.paragraphs.map((paragraph, index) => {
                        return (
                          <Typography
                            key={paragraph}
                            mb={
                              isEndIndex(subsection.paragraphs, index) ? 0 : 2
                            }
                            sx={{ wordWrap: 'break-word' }}
                          >
                            {paragraph}
                          </Typography>
                        );
                      })}
                    </Stack>
                  );
                })}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Stack>
  );
}
