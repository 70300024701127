import { Navigate } from 'react-router-dom';
import { ReactElement } from 'react';
import useAuth from 'util/auth/hooks/useAuth';

export type TypeUnauthenticatedRouteWrapperProps = {
  children: ReactElement;
};

export default function UnauthenticatedRouteWrapper(
  props: TypeUnauthenticatedRouteWrapperProps
) {
  const { children } = props;
  const { isAuthenticated } = useAuth();

  if (isAuthenticated()) {
    return <Navigate to='/match' replace />;
  }

  return children;
}
